import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../components/CustomTextField";
import IOSSwitch from "../../components/IOSSwitch";
import { Campaign } from "../../types/campaign.types";
import useQuestionsBlock from "../../hooks/useQuestionsBlock";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { useHistory } from "react-router-dom";
import { Controller } from "react-hook-form";
import { ButtonSaveCampaignSettings } from ".";
import { useTranslation } from "react-i18next";
import ViewRenderingButtonModal from "../../components/ViewRenderingButtonModal";
import QuestionsResponsesBuilder from "../../components/QuestionsResponsesBuilder";
import ControlledEmojiPicker from "../../components/form-components/ControlledEmojiPicker";

interface GeneralSettingsProps {
  campaign: Campaign;
  formMethods: any;
}

export default function GeneralSettings({
  campaign,
  formMethods,
}: GeneralSettingsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  let history = useHistory();

  const [setOpenModalPreviewQuestion, setSetOpenModalPreviewQuestion] =
    useState<(b: boolean) => void>(() => {});

  const { displayQuestionBlocks } = useQuestionsBlock({
    disabledDradNDrop: true,
    questions: campaign?.questions,
    onClick: (id: number) => {},
  });

  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function goToQuestionBuilder() {
    history.push(`/campaign/${campaign.id}/questions`);
  }

  if (campaign?.isFolder) {
    return (
      <Stack spacing={3} mt={1}>
        <Stack
          direction={matchesSmScreen ? "column" : "row"}
          spacing={matchesSmScreen ? 1 : 2}
        >
          <Stack spacing={1}>
            <Typography variant="body2">
              {t("pages.campaignSettings.campaignName")}
            </Typography>
            <ControlledCustomTextField
              control={formMethods.control}
              defaultValue={campaign.name}
              name="name"
            />
          </Stack>
          <ControlledEmojiPicker
            control={formMethods.control}
            defaultValue={campaign.emoji}
            label="emoji"
            name="emoji"
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <ButtonSaveCampaignSettings />
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack spacing={3} mt={1}>
      <Stack
        direction={matchesSmScreen ? "column" : "row"}
        spacing={matchesSmScreen ? 1 : 2}
      >
        <Stack spacing={1}>
          <Typography variant="body2">
            {t("pages.campaignSettings.campaignName")}
          </Typography>
          <ControlledCustomTextField
            control={formMethods.control}
            defaultValue={campaign.name}
            name="name"
          />
        </Stack>
        <ControlledEmojiPicker
          control={formMethods.control}
          defaultValue={campaign.emoji}
          label="emoji"
          name="emoji"
        />

        <Stack spacing={1}>
          <Typography variant="body2">
            {t("pages.campaignSettings.giftLink")}
          </Typography>
          <ControlledCustomTextField
            control={formMethods.control}
            defaultValue={campaign.giftLink}
            name="giftLink"
          />
        </Stack>
        {/* <Stack spacing={1}>
                    <Typography variant="h4">
                        {t('pages.campaignSettings.campaignStatus')}
                    </Typography>
                    <ControllerAutocomplete
                        control={formMethods.control}
                        defaultValue={campaign.status}
                        name="status"
                        getOptionLabel={opt => opt}
                        options={['draft', 'inProgress', 'paused', 'ended']}
                        label=""

                    />
                </Stack> */}
      </Stack>
      <Stack
        direction={matchesSmScreen ? "column" : "row"}
        spacing={matchesSmScreen ? 1 : 2}
      >
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">
            {t("pages.campaignSettings.customerNotation")}
          </Typography>
          <ControlledSwitch
            control={formMethods.control}
            defaultValue={campaign.notations}
            name="notations"
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">
            {t("pages.campaignSettings.sendAutoEmail")}
          </Typography>
          <ControlledSwitch
            control={formMethods.control}
            defaultValue={campaign.sendAutoEmail}
            name="sendAutoEmail"
            onSwitch={(val) => {}}
          />
        </Stack>
      </Stack>

      <Stack spacing={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h5">
            {t("pages.campaignSettings.yourQuestion")}
          </Typography>
          <ViewRenderingButtonModal
            fullSize
            init={({ open, setOpen }: { open: boolean; setOpen: any }) => {
              setSetOpenModalPreviewQuestion(() => setOpen);
            }}
          >
            <QuestionsResponsesBuilder
              initialValue={campaign?.questions?.map((q) => ({ question: q }))}
              onEnd={() => {
                setOpenModalPreviewQuestion(false);
              }}
            />
          </ViewRenderingButtonModal>
        </Box>

        {displayQuestionBlocks()}

        <Button
          onClick={goToQuestionBuilder}
          variant="contained"
          sx={{
            borderRadius: "100px",
            height: "50px",
            maxWidth: "200px",
          }}
        >
          <ModeEditOutlineOutlinedIcon sx={{ marginRight: 1 }} />{" "}
          {t("pages.campaignSettings.editMode")}
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <ButtonSaveCampaignSettings />
      </Stack>
    </Stack>
  );
}

function ControlledSwitch({
  name,
  control,
  defaultValue,
  onSwitch,
}: {
  onSwitch?: (val: boolean) => void;
  name: string;
  control: any;
  defaultValue?: boolean;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || false}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          {/* @ts-ignore */}
          <IOSSwitch
            checked={!!value}
            onChange={(event, checked) => {
              onChange(checked);
              if (onSwitch) {
                onSwitch(checked);
              }
            }}
          />
        </>
      )}
    />
  );
}

function ControlledCustomTextField({
  sx,
  multiline,
  name,
  control,
  defaultValue,
  type,
}: {
  sx?: any;
  name: string;
  control: any;
  defaultValue?: string;
  multiline?: boolean;
  type?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <CustomTextField
          multiline={multiline || false}
          fullWidth={false}
          value={value}
          onChange={onChange}
          sx={sx}
          type={type}
        />
      )}
    />
  );
}
