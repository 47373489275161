import { Stack, Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";

interface ColorBlockProps {
  onClick?: () => void;
  color: "primary" | "warning" | "success";
  centerElements: boolean;
  clickable: boolean;
  minSize?: boolean;

  icon1: any;
  icon2: any;
  text1: string;
  text2: string;
  text3: string;

  revertIcon2?: boolean;
  width?: string;
  maxWidth?: string;
}

export default function ColorBlock({
  maxWidth,
  width,
  revertIcon2,
  onClick,
  clickable,
  color,
  centerElements,
  minSize,
  text1,
  text2,
  text3,
  icon1,
  icon2,
}: ColorBlockProps) {
  const theme = useTheme();

  const lightTextColor =
    color === "primary"
      ? "#ABBDF5"
      : color === "success"
      ? "#B6ECB9"
      : "#EAC7A7";
  const lightIconColor =
    color === "primary"
      ? "#EDF1FE"
      : color === "success"
      ? "#EEFEEF"
      : "#FFEDDC";
  const boxIconColor =
    color === "primary"
      ? "#C8D4F8"
      : color === "success"
      ? "#B6ECB9"
      : "#EAC7A7";

  return (
    <button
      onClick={onClick}
      style={{
        flexGrow: minSize ? 1 : 0,
        width: width || "auto",
      }}
    >
      <Stack
        minHeight={"150px"}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        direction="column"
        spacing={minSize ? 2 : 10}
        alignItems={centerElements ? "center" : "flex-start"}
        pr={3}
        pl={3}
        pt={5}
        pb={5}
        maxWidth={maxWidth || "initial"}
        sx={{
          borderRadius: "24px",
          backgroundColor: theme.palette[color].light,
        }}
      >
        <Stack
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ color: lightTextColor }}
        >
          <Box
            sx={{ borderRadius: "8px", backgroundColor: boxIconColor }}
            pr={1}
            pl={1}
            pt={0.5}
            pb={0.5}
          >
            {React.createElement(icon1, { sx: { color: lightIconColor } })}
          </Box>
          <Typography
            variant="body1"
            color="inherit"
            sx={{ textAlign: centerElements ? "center" : "start" }}
          >
            {text1}
          </Typography>
        </Stack>

        <Typography
          variant="h4"
          fontWeight={700}
          color={theme.palette[color].dark}
          sx={{ width: "80%", textAlign: centerElements ? "center" : "start" }}
        >
          {text2}
        </Typography>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          sx={{ color: lightTextColor }}
        >
          {revertIcon2 ? (
            <Box sx={{ transform: "rotate(180deg)" }}>
              {React.createElement(icon2, { sx: { color: "inherit" } })}
            </Box>
          ) : (
            React.createElement(icon2, { sx: { color: "inherit" } })
          )}

          <Typography
            variant="body1"
            color="inherit"
            sx={{ textAlign: centerElements ? "center" : "start" }}
          >
            {text3}
          </Typography>
        </Stack>
      </Stack>
    </button>
  );
}
