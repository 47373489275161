import { Button, Modal, Box, IconButton, Stack, SxProps } from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

interface ViewRenderingButtonModalProps {
  sx?: any;
  children?: any;
  onOpen?: () => void;
  fullSize?: boolean;
  init?: ({ open, setOpen }) => void;
}

export default function ViewRenderingButtonModal({
  sx,
  children,
  onOpen,
  fullSize,
  init,
}: ViewRenderingButtonModalProps) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (init) {
      init({ open, setOpen });
    }
  }, []);

  const handleOpen = () => {
    if (onOpen) {
      onOpen();
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const boxSX: SxProps = fullSize
    ? {
        position: "absolute",
        top: "0",
        left: "0",
        bgcolor: "background.paper",
        border: "none",
        width: "100vw",
        height: "100vh",
      }
    : {
        position: "absolute",
        top: "40%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: 400,
        bgcolor: "background.paper",
        border: "none",
        boxShadow: 24,
        p: 4,
      };

  return (
    <>
      <Button onClick={handleOpen} variant="text" type="button" sx={sx}>
        <RemoveRedEyeOutlinedIcon fontSize="small" sx={{ marginRight: 1 }} />
        {t("common.viewRender")}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={boxSX}>
          <IconButton
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
            }}
            onClick={handleClose}
          >
            <CloseIcon sx={{ fontSize: "2.3rem" }} fontSize="medium" />
          </IconButton>

          <Stack direction="column" spacing={2}>
            {open && children}

            {/* <Stack display="flex" justifyContent="end" flexDirection="row">
                            <Button onClick={handleClose} type="button" variant="contained" sx={{ borderRadius: '100px', height: '50px' }}>
                                {t('common.ok')}
                            </Button>
                        </Stack> */}
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
