import {
  Autocomplete,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { Controller } from "react-hook-form";
import CustomTextField from "../../components/CustomTextField";
import { BroadcastCondition, Campaign } from "../../types/campaign.types";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ButtonSaveCampaignSettings } from ".";
import DisplayScriptInfoButtonModal from "../../components/DisplayScriptInfoButtonModal";
import { useTranslation } from "react-i18next";
import IOSSwitch from "../../components/IOSSwitch";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import AddIcon from "@mui/icons-material/Add";
import { confirm } from "../../plugins/confirm.plugin";

interface DiffusionProps {
  campaign: Campaign;
  formMethods: any;
}

const CONDITION_TYPES = ["if"];

enum ConditionType {
  URL_EQUAL = 1,
  URL_NOT_EQUAL = 2,
  URL_CONTAIN = 3,
  URL_DO_NOT_CONTAIN = 4,
  URL_BEGIN_WITH = 5,
}

function getConditionLabelKey(conditionType: ConditionType) {
  switch (conditionType) {
    case ConditionType.URL_EQUAL:
      return "pages.campaignSettings.url_equal";
    case ConditionType.URL_NOT_EQUAL:
      return "pages.campaignSettings.url_not_equal";
    case ConditionType.URL_CONTAIN:
      return "pages.campaignSettings.url_contain";
    case ConditionType.URL_DO_NOT_CONTAIN:
      return "pages.campaignSettings.url_do_not_contain";
    case ConditionType.URL_BEGIN_WITH:
      return "pages.campaignSettings.url_begin_with";
    default:
      return "";
  }
}

interface BroadcastConditionComponentProps {
  condition: BroadcastCondition;
  index: number;
  conditions: BroadcastCondition[];
  t: any;
  formMethods: any;
  theme: any;
  onDelete: (index) => void;
}
function BroadcastConditionComponent({
  condition,
  index,
  formMethods,
  t,
  conditions,
  theme,
  onDelete,
}: BroadcastConditionComponentProps) {
  const [, setDisplayState] = useState<boolean>(condition.display);
  const [withChildrenState, setWithChildrenState] = useState<boolean>(
    condition.withChildren
  );

  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack direction="column" spacing={1} alignItems="center">
      <Stack width="100%">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2">
            {t("pages.campaignSettings.condition")} #{index}
          </Typography>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => onDelete(index)}
          >
            <DeleteOutlineOutlinedIcon
              sx={{ color: theme.palette.error.main }}
            />
          </IconButton>
        </Box>
        <Stack direction="row" spacing={1} width="100%">
          <Autocomplete
            disabled
            value={CONDITION_TYPES[0]}
            fullWidth
            disablePortal
            getOptionLabel={(opt) => t(`pages.campaignSettings.${opt}`)}
            renderInput={(params) => <CustomTextField fullWidth {...params} />}
            options={CONDITION_TYPES}
          />
          {/*
                            <ControlledCustomAutocomplete
                                control={formMethods.control}
                                name={`broadcast_conditions[${index}].conditionType`}
                                defaultValue={CONDITION_TYPES[0]}
                                options={CONDITION_TYPES}
                                getOptionLabel={opt => t(`pages.campaignSettings.${opt}`)}
                                disabled
                            />
                            */}

          <ControlledCustomAutocomplete
            control={formMethods.control}
            name={`broadcast_conditions[${index}].condition_type`}
            defaultValue={conditions[index].condition_type}
            getOptionLabel={(opt) => t(getConditionLabelKey(opt))}
            options={
              [
                1, 2, 3, 4, 5,
              ] /* Object.keys(ConditionType).map(k => ConditionType[k]) */
            }
          />
          <ControlledCustomTextField
            control={formMethods.control}
            name={`broadcast_conditions[${index}].condition_value`}
            defaultValue={conditions[index].condition_value}
          />
        </Stack>
      </Stack>
      <Stack alignItems="center" justifyContent="center">
        <Typography variant="body1">
          {t("pages.campaignSettings.then")}
        </Typography>
        <ArrowDownwardIcon sx={{ color: theme.palette.primary.main }} />
      </Stack>
      <Stack width="100%">
        <Typography variant="body2">
          {t("pages.campaignSettings.rule")} #{index}
        </Typography>
        <Stack
          direction={matchesSmScreen ? "column" : "row"}
          spacing={matchesSmScreen ? 0 : 3}
          width="100%"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1">
              {t("pages.campaignSettings.do_not_display")}
            </Typography>
            <ControlledSwitch
              control={formMethods.control}
              defaultValue={condition.display}
              name={`broadcast_conditions[${index}].display`}
              onSwitch={setDisplayState}
            />
            <Typography variant="body1">
              {t("pages.campaignSettings.display")}
            </Typography>
          </Stack>

          {!matchesSmScreen && <Divider orientation="vertical" flexItem />}

          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="body1">
              {t("pages.campaignSettings.notWithChildren")}
            </Typography>
            <ControlledSwitch
              control={formMethods.control}
              defaultValue={condition.withChildren}
              name={`broadcast_conditions[${index}].withChildren`}
              onSwitch={setWithChildrenState}
            />
            <Typography variant="body1">
              {t("pages.campaignSettings.withChildren")}
            </Typography>
          </Stack>

          {!withChildrenState ? (
            <>
              {!matchesSmScreen && <Divider orientation="vertical" flexItem />}
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="body1">
                  {t("pages.campaignSettings.noRandomOrder")}
                </Typography>
                <ControlledSwitch
                  control={formMethods.control}
                  defaultValue={condition.randomOrder}
                  name={`broadcast_conditions[${index}].randomOrder`}
                />
                <Typography variant="body1">
                  {t("pages.campaignSettings.randomOrder")}
                </Typography>
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default function Diffusion({ campaign, formMethods }: DiffusionProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [conditions, setConditions] = useState<BroadcastCondition[]>(
    campaign.broadcast_conditions || []
  );

  function addEmptyCondition() {
    setConditions([...conditions, {}]);
  }

  return (
    <Stack spacing={2} direction="column" mt={1}>
      <Box>
        <DisplayScriptInfoButtonModal />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">
          {t("pages.campaignSettings.conditions")}
        </Typography>
        <Button
          color="primary"
          variant="text"
          type="button"
          onClick={addEmptyCondition}
          startIcon={<AddIcon />}
          sx={{
            "& .MuiButton-startIcon": {
              mr: 0,
            },
          }}
        >
          {t("pages.campaignSettings.addCondition")}
        </Button>
      </Box>
      <Stack spacing={6}>
        {conditions.map((condition, index) => (
          <Stack key={Math.random()} spacing={6}>
            <BroadcastConditionComponent
              theme={theme}
              t={t}
              formMethods={formMethods}
              index={index}
              condition={condition}
              conditions={conditions}
              onDelete={async (index) => {
                if (await confirm(t("common.sureDeleteConditionDiffusion"))) {
                  const newConditions = conditions.filter(
                    (c, i) => index !== i
                  );
                  formMethods.setValue("broadcast_conditions", newConditions);
                  setConditions(newConditions);
                }
              }}
            />
            {index !== conditions.length - 1 && <Divider />}
          </Stack>
        ))}
      </Stack>
      <Box display="flex" justifyContent="flex-end">
        <ButtonSaveCampaignSettings />
      </Box>
    </Stack>
  );
}

function ControlledCustomAutocomplete({
  getOptionLabel,
  disabled,
  multiline,
  name,
  control,
  defaultValue,
  options,
}: {
  getOptionLabel?: (opt: any) => string;
  disabled?: boolean;
  options: Array<any>;
  name: string;
  control: any;
  defaultValue?: string | number;
  multiline?: boolean;
}) {
  const cgetOptionLabel = getOptionLabel
    ? getOptionLabel
    : (option) => option["label"] || option;

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Autocomplete
          disabled={disabled}
          value={value}
          onChange={(event, newValue) => onChange(newValue)}
          fullWidth
          disablePortal
          getOptionLabel={cgetOptionLabel}
          renderInput={(params) => <CustomTextField fullWidth {...params} />}
          options={options || []}
        />
      )}
    />
  );
}

function ControlledCustomTextField({
  multiline,
  name,
  control,
  defaultValue,
}: {
  name: string;
  control: any;
  defaultValue?: string;
  multiline?: boolean;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({ field: { onChange, value } }) => (
        <CustomTextField
          multiline={multiline || false}
          fullWidth
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
}

function ControlledSwitch({
  name,
  control,
  defaultValue,
  onSwitch,
}: {
  name: string;
  control: any;
  defaultValue?: boolean;
  onSwitch?: (value: boolean) => void;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || false}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          {/* @ts-ignore */}
          <IOSSwitch
            checked={!!value}
            onChange={(event, checked) => {
              onChange(checked);
              if (onSwitch) {
                onSwitch(checked);
              }
            }}
          />
        </>
      )}
    />
  );
}
