import {
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useHistory, useParams } from "react-router-dom";
import AloneBadge from "../../components/AloneBadge";
import useSWR from "swr";
import useServices from "../../hooks/useServices.hook";
import CircularProgress from "@mui/material/CircularProgress";
import SettingsIcon from "@mui/icons-material/Settings";
import Responses from "./Responses";
import KeySettings from "./KeySettings";
import ColorBlock from "../../components/ColorBlock";
import { useTranslation } from "react-i18next";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import MouseOutlinedIcon from "@mui/icons-material/MouseOutlined";
import { useSnackbar } from "notistack";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import usePage from "../../hooks/usePage";
import { confirm } from "../../plugins/confirm.plugin";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FRONTEND_URL } from "../../config";
import { IDEncoder } from "../../utils";

export default function CampaignPage(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const theme = useTheme();
  const { campaignsService } = useServices();
  const { id } = useParams();

  const { data: campaign, mutate: mutateCampaign } = useSWR(
    ["campaigns", id],
    (key, id) => campaignsService.getById(id)
  );
  const { data: campaignStats } = useSWR(["campaignsStats", id], (key, id) =>
    campaignsService.getStats(id)
  );

  const { enqueueSnackbar } = useSnackbar();

  function goToHomePage() {
    history.push("/");
  }

  function goToCampaignSettings() {
    history.push(`/campaign/${campaign.id}/settings`);
  }

  usePage(
    {
      isMenuActive: true,
      headerProps: {
        title: (
          <Stack
            sx={{
              flexDirection: ["column", "row", "row"],
              "& > *": {
                mr: 1,
              },
            }}
          >
            <span>
              {campaign?.name} {campaign?.emoji || ""}
            </span>

            {!campaign?.isFolder &&
              (campaign?.status !== "inProgress" ? (
                <Button
                  onClick={handlePlayCampaign}
                  startIcon={
                    <PlayCircleOutlineIcon
                      sx={{
                        fontSize: "2rem!important",
                        color: theme.palette.primary.main,
                      }}
                    />
                  }
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <Typography variant="h4" color={theme.palette.primary.main}>
                    {t("common.toStartUp")}
                  </Typography>
                </Button>
              ) : (
                <Button
                  sx={{
                    width: "fit-content",
                  }}
                  onClick={handlePauseCampaign}
                  color="error"
                  startIcon={
                    <PauseCircleOutlineIcon
                      sx={{
                        fontSize: "2rem!important",
                        color: theme.palette.error.main,
                      }}
                    />
                  }
                >
                  <Typography variant="h4" color={theme.palette.error.main}>
                    {t("common.toStop")}
                  </Typography>
                </Button>
              ))}
          </Stack>
        ),
        subtitle: campaign?.status
          ? campaign?.status === "draft"
            ? t("pages.campaign.draftCampaign")
            : `${campaign?.responses?.length} ${t(
                "pages.campaign.testimonials"
              )}`
          : "",
        subtitleColor: "#9296A1",
        subtitle2: (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <span>{t("common.respondToCampaing")}</span>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${FRONTEND_URL}#/campaign/${
                  campaign?.id ? IDEncoder.encodeID(campaign?.id) : ""
                }/response`}
              >
                {FRONTEND_URL}#/campaign/
                {campaign?.id ? IDEncoder.encodeID(campaign?.id) : ""}/response
              </a>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.permissions
                    .query({ name: "clipboard-write" as PermissionName })
                    .then((result) => {
                      if (
                        result.state === "granted" ||
                        result.state === "prompt"
                      ) {
                        navigator.clipboard
                          .writeText(
                            `${FRONTEND_URL}#/campaign/${
                              campaign?.id
                                ? IDEncoder.encodeID(campaign?.id)
                                : ""
                            }/response`
                          )
                          .then(
                            function () {
                              enqueueSnackbar(
                                t(
                                  "pages.displayScriptInfoButtonModal.textCopied"
                                ),
                                { variant: "success" }
                              );
                            },
                            function () {
                              /* clipboard write failed */
                            }
                          );
                      }
                    });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Stack>
          </>
        ),
      },
    },
    campaign
  );

  async function handlePlayCampaign() {
    if (await confirm(t("common.surePlayCampaign"))) {
      try {
        await campaignsService.update(id, {
          status: "inProgress",
        });
        mutateCampaign({
          ...campaign,
          status: "inProgress",
        });
        enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
      }
    }
  }

  async function handlePauseCampaign() {
    if (await confirm(t("common.surePauseCampaign"))) {
      try {
        await campaignsService.update(id, {
          status: "ended",
        });
        mutateCampaign({
          ...campaign,
          status: "ended",
        });
        enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
      }
    }
  }

  const numberResponsesNotManaged = campaign?.responses?.filter(
    (r) => !r.status && r.videoUrl
  ).length;

  return (
    <>
      <Box mt={3} mb={3}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="end"
          sx={{ cursor: "pointer" }}
          onClick={goToHomePage}
        >
          <HomeOutlinedIcon />
          <Typography variant="body1" display="flex" alignItems="end">
            {t("pages.campaign.returnToCampaign")}
          </Typography>
        </Stack>
      </Box>
      {!campaign ? (
        <CircularProgress
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      ) : (
        <>
          <Box
            mt={5}
            mb={3}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {campaign?.status === "draft" ? (
              <>
                <Chip
                  color="success"
                  label={t("pages.campaign.superYourCampaignIsCreatedAsADraft")}
                  icon={<AloneBadge size="sm" color={"#00BA88"} />}
                  sx={{
                    pl: 1,
                  }}
                />
              </>
            ) : (
              <>
                <Chip
                  label={
                    numberResponsesNotManaged
                      ? t("pages.campaign.youHaveXTestimonials", {
                          numberResponsesNotManaged,
                        })
                      : t("pages.campaign.yourCampaignIsUpToDate")
                  }
                  icon={
                    <AloneBadge
                      size="sm"
                      color={
                        numberResponsesNotManaged
                          ? theme.palette.secondary.main
                          : "#00BA88"
                      }
                    />
                  }
                  sx={{
                    pl: 1,
                    backgroundColor: numberResponsesNotManaged
                      ? theme.palette.secondary.light
                      : theme.palette.success.main,
                  }}
                />
              </>
            )}
          </Box>
          {campaign?.status === "draft" && (
            <Box display="flex" justifyContent="center" mt={5}>
              <ColorBlock
                text1={t("pages.campaign.yourCampaignCannotBePublishedYet")}
                text2={t("pages.campaign.manageCampaignSettings")}
                text3={t("pages.campaign.itWillOnlyTakeFewMinutes")}
                icon1={MouseOutlinedIcon}
                icon2={WarningAmberOutlinedIcon}
                clickable={true}
                centerElements
                onClick={goToCampaignSettings}
                color="primary"
                maxWidth="586px"
              />
            </Box>
          )}
          {campaign?.status !== "draft" && (
            <>
              <Box mt={2}>
                {/* todo:add a view of the non complete videos ? */}
                <Responses
                  mutateCampaigns={mutateCampaign}
                  responses={campaign?.responses?.filter(
                    (r) => !r.status && r.videoUrl
                  )}
                />
              </Box>
              <Stack mt={2} spacing={2}>
                <Typography variant="h5">
                  {t("pages.campaign.campaignKeyFigures")}
                </Typography>
                <KeySettings stats={campaignStats} />
              </Stack>

              <Stack mt={2} spacing={2}>
                <Typography variant="h5">
                  {t("pages.campaign.testimonialsTreated")}
                </Typography>
                <Responses
                  mutateCampaigns={mutateCampaign}
                  responses={campaign?.responses?.filter((r) => r.status)}
                />
              </Stack>
              <Button
                onClick={goToCampaignSettings}
                variant="contained"
                sx={{
                  position: "fixed",
                  bottom: 16,
                  right: 16,
                  borderRadius: "100px",
                  height: "50px",
                }}
              >
                <SettingsIcon sx={{ marginRight: 1 }} />{" "}
                {t("pages.campaign.campaignSettings")}
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}
