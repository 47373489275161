import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import PaperButton from "../PaperButton";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CameraOutlinedIcon from "@mui/icons-material/CameraOutlined";
import ClosePageButton from "../ClosePageButton";
import ReturnPageButton from "../ReturnPageButton";
import { useTranslation } from "react-i18next";
import TextFieldsIcon from "@mui/icons-material/TextFields";
interface VideoCreateChoiceProps {
  onClose: () => void;
  onGoPrevious: () => void;
  onGoRecord: () => void;
  onVideoBlobComplete: (blob: Blob) => void;
  onGoTextQuestion: () => void;
}

export default function VideoCreateChoice({
  onGoTextQuestion,
  onClose,
  onGoPrevious,
  onGoRecord,
  onVideoBlobComplete,
}: VideoCreateChoiceProps) {
  const { t } = useTranslation();

  const inputFileUploadRef = React.useRef<HTMLInputElement>();

  function handleUploadFileVideo(file: File) {
    if (!file) return;
    onVideoBlobComplete(file);
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100vw", height: "100vh" }}
    >
      <ClosePageButton onClick={onClose} />
      <ReturnPageButton onClick={onGoPrevious} />

      <Box pr={2} pl={2}>
        <Typography variant="h4" textAlign="center">
          {t("pages.questionBuilder.howToCreateQuestion")}
        </Typography>
        <Box
          mt={5}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
        >
          <PaperButton
            sx={{
              marginRight: 2,
              marginTop: 2,
              width: "256px",
              height: "214px",
            }}
            onClick={onGoTextQuestion}
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <TextFieldsIcon fontSize="large" />
              <Typography variant="h6" color="black" mt={2} width="80%">
                {t("pages.questionBuilder.textQuestion")}
              </Typography>
            </Box>
          </PaperButton>
          <PaperButton
            sx={{
              marginRight: 2,
              marginTop: 2,
              width: "256px",
              height: "214px",
            }}
            onClick={onGoRecord}
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <CameraOutlinedIcon fontSize="large" />
              <Typography variant="h6" color="black" mt={2} width="80%">
                {t("pages.questionBuilder.filmMyselfWithMyWebcam")}
              </Typography>
            </Box>
          </PaperButton>
          <PaperButton
            sx={{
              marginRight: 2,
              marginTop: 2,
              width: "256px",
              height: "214px",
            }}
            onClick={() => {
              if (inputFileUploadRef?.current) {
                inputFileUploadRef?.current.click();
              }
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <CloudUploadOutlinedIcon fontSize="large" />
              <Typography variant="h6" color="black" mt={2} width="80%">
                {t("pages.questionBuilder.integrateAReadyVideo")}
              </Typography>
            </Box>
          </PaperButton>
          <input
            style={{ display: "none" }}
            type="file"
            ref={inputFileUploadRef}
            onChange={(e) => handleUploadFileVideo(e.target.files![0])}
          />
        </Box>
      </Box>
    </Box>
  );
}
