import { Typography, useMediaQuery, Stack } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import React from "react";
import NotificationsDrawer from "./NotificationsDrawer";

export interface HeaderProps {
  title: string | JSX.Element;
  subtitle: any;
  subtitleColor?: string;
  subtitle2?: string | JSX.Element;
}

export default function Header({
  title,
  subtitle,
  subtitleColor,
  subtitle2,
}: HeaderProps) {
  const theme = useTheme();
  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [date, setDate] = React.useState<Date>(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Stack mb={3} mt={4}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h3">{title}</Typography>
          <Typography
            color={subtitleColor || "black"}
            variant="subtitle1"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {subtitle}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {!matchesSmScreen && (
            <>
              <Typography variant="body1" color="gray" mr={3}>
                {date.toLocaleDateString()}
              </Typography>

              <Typography variant="body1" color="gray" mr={3} width="66px">
                {date.toLocaleTimeString()}
              </Typography>
            </>
          )}

          <NotificationsDrawer />
        </Box>
      </Box>
      {subtitle2}
    </Stack>
  );
}
