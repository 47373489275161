import { Stack } from "@mui/material";
import React from "react";
import { Response } from "../../types/campaign.types";
import ResponseBlock from "./ResponseBlock";

interface ResponsesProps {
  responses: Array<Response>;
  mutateCampaigns?: () => void;
}

export default function Responses({
  responses,
  mutateCampaigns,
}: ResponsesProps) {
  const list = [
    ...responses.filter((r) => r.status === "broadcast"),
    ...responses.filter((r) => r.status === "archived"),
    ...responses.filter(
      (r) => r.status !== "broadcast" && r.status !== "archived"
    ),
  ];

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      sx={{
        "& > *": {
          mr: 1,
        },
      }}
    >
      {list?.map((response) => (
        <ResponseBlock
          key={response.id}
          response={response}
          mutateCampaigns={mutateCampaigns}
        />
      ))}
    </Stack>
  );
}
