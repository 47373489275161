import {
  Avatar,
  Badge,
  Drawer,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import PaperButton from "../PaperButton";
import BellIcon from "@mui/icons-material/NotificationsNone";
import useSWR from "swr";
import useServices from "../../hooks/useServices.hook";
import { Box, useTheme } from "@mui/system";
import AloneBadge from "../AloneBadge";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authState } from "../../stores/auth.store";
import { useRecoilState } from "recoil";
import { User } from "../../types/user.types";

export default function NotificationsDrawer() {
  let history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const { notificationService } = useServices();
  const { data: notifications } = useSWR("notifications", () =>
    notificationService.find()
  );

  const [open, setOpen] = React.useState<boolean>(false);

  const [auth] = useRecoilState<User>(authState);

  function handleCreateCampaign() {
    history.push("/create-campaign");
  }

  function goToAccount(e) {
    e.preventDefault();
    history.push("/account");
  }

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const goToCampaign = (id: number) => {
    history.push(`/campaign/${id}`);
  };

  const avatarUrl = auth?.avatar;

  return (
    <>
      <PaperButton
        sx={{ height: "40px", width: "40px" }}
        onClick={toggleDrawer}
      >
        {notifications?.length ? (
          <Badge variant="dot" color="secondary">
            <BellIcon fontSize="medium" style={{ color: "#18181A" }} />
          </Badge>
        ) : (
          <BellIcon fontSize="medium" style={{ color: "#18181A" }} />
        )}
      </PaperButton>
      <Drawer anchor="right" open={open} onClose={toggleDrawer}>
        <Stack
          spacing={6}
          p={4}
          sx={{ backgroundColor: "#F6FCFF" }}
          height="100%"
          minWidth="380px"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <IconButton onClick={goToAccount}>
              {avatarUrl ? (
                <Avatar
                  alt={`${auth?.firstname || ""} ${auth?.lastname || ""}`}
                  src={avatarUrl}
                  sx={{ width: "58px", height: "58px" }}
                />
              ) : (
                <Avatar sx={{ width: "58px", height: "58px" }}>
                  {(auth?.firstname || "A")[0].toUpperCase()}
                  {(auth?.lastname || "A")[0].toUpperCase()}
                </Avatar>
              )}
            </IconButton>

            <Stack spacing={0.5}>
              <Box>
                <Typography variant="h4" fontSize="20px" fontWeight={600}>
                  {`${auth?.firstname || ""} ${auth?.lastname || ""}`}
                </Typography>
              </Box>
              <Box>
                {notifications && notifications?.length ? (
                  <Stack direction="row" alignItems="center">
                    <AloneBadge
                      sx={{ marginRight: "5px" }}
                      size="md"
                      color="#ED2E7E"
                    />
                    <Typography variant="body1">
                      {notifications?.length}{" "}
                      {t("pages.notificationDrawer.notifications")}
                    </Typography>
                  </Stack>
                ) : (
                  <Stack direction="row" alignItems="center">
                    <AloneBadge
                      sx={{ marginRight: "5px" }}
                      size="md"
                      color="#00BA88"
                    />
                    <Typography variant="body1">
                      {t("pages.notificationDrawer.upToDate")}
                    </Typography>
                  </Stack>
                )}
              </Box>
            </Stack>
          </Stack>
          {notifications && notifications?.length ? (
            <Stack spacing={2}>
              <Typography variant="h5" color="#9296A1">
                {t("pages.notificationDrawer.newTestimonials")}
              </Typography>
              <Stack spacing={1}>
                {notifications?.map((notification) => (
                  <Stack
                    key={Math.random()}
                    p={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "8px",
                      cursor: "pointer",
                    }}
                    onClick={() => goToCampaign(notification.campaign_id)}
                  >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <PlayCircleFilledIcon
                        sx={{ color: "#FFEDDC" }}
                        fontSize="large"
                      />
                      <Typography variant="body2">
                        {notification.campaign_name}
                      </Typography>
                    </Stack>

                    <ArrowForwardIcon
                      fontSize="large"
                      sx={{ justifySelf: "flex-end" }}
                    />
                  </Stack>
                ))}
              </Stack>
            </Stack>
          ) : (
            <></>
          )}
          <button className="scale-up-button" onClick={handleCreateCampaign}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              width="100%"
              pt={6}
              pb={6}
              sx={{
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.primary.dark,
                borderRadius: "24px",
              }}
            >
              <Typography variant="button" textAlign="center" width="80%">
                {t("pages.notificationDrawer.createNewCampaign")}
              </Typography>
              <ArrowForwardIcon fontSize="large" sx={{ marginTop: 1 }} />
            </Box>
          </button>
        </Stack>
      </Drawer>
    </>
  );
}
