import axios from "axios";

import { API_URL } from "../config";
import { User } from "../types/user.types";

export default class AuthService {

    static token?: string;
    auth?: User;

    static getToken (): string | undefined {
        if ( !AuthService.token?.length )
        {
            AuthService.token = localStorage.getItem( 'token' ) || '';
        }
        return AuthService.token;
    }

    static isAuth (): boolean {
        return !!AuthService.getToken()?.length;
    }

    static disconnect () {
        AuthService.token = undefined;
        localStorage.removeItem( 'token' );
        localStorage.removeItem( 'auth' );
    }

    async updateAccount ( data: any ): Promise<User> {
        delete data[ 'base64Id' ];
        const result = await axios.put( `${ API_URL }auth/update`, data, { headers: { Authorization: AuthService.getToken() } } );
        localStorage.setItem( 'auth', JSON.stringify( result.data ) );
        return result.data;
    }

    async getAuth (): Promise<User> {
        const result = await axios.get( `${ API_URL }auth/update`, { headers: { Authorization: AuthService.getToken() } } );
        localStorage.setItem( 'auth', JSON.stringify( result.data ) );
        return result.data;

    }

    async login ( email: string, password: string ) {
        try
        {
            const result = await axios.post<any>( `${ API_URL }auth/login`, {
                email,
                password
            } );
            AuthService.token = result.data.token;
            localStorage.setItem( 'token', result.data.token );
            localStorage.setItem( 'auth', JSON.stringify( result.data.user ) );
            return result.data.user;
        } catch ( err )
        {
            return Promise.reject( err );
        }

    }



}
