import { Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import useServices from "../hooks/useServices.hook";
import { authState } from "../stores/auth.store";
import CustomTextField from "./CustomTextField";

export default function CompanyDataForm() {
  const { authService } = useServices();
  const { enqueueSnackbar } = useSnackbar();

  const [auth, setAuth] = useRecoilState(authState);

  const { t } = useTranslation();
  const { handleSubmit, control, reset } = useForm();

  async function handleSave(data: any) {
    try {
      const user = await authService.updateAccount(data);
      enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
      setAuth(user);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
  }
  React.useEffect(() => {
    reset(auth);
  }, [auth, reset]);

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Stack spacing={1}>
        <Stack direction="row" spacing={1}>
          <ControlledCustomTextField
            control={control}
            name="companyEntity"
            label="pages.account.entity"
          />

          <ControlledCustomTextField
            control={control}
            name="companySiret"
            label="pages.account.siret"
          />
        </Stack>

        <Stack direction="row" spacing={1}>
          <ControlledCustomTextField
            control={control}
            name="companyPhone"
            label="pages.account.phone"
          />
          <ControlledCustomTextField
            control={control}
            name="companyEmail"
            label="pages.account.email"
          />
          <ControlledCustomTextField
            control={control}
            name="companyAddress"
            label="pages.account.address"
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            type="submit"
            variant="contained"
            sx={{ borderRadius: "100px", height: "50px", marginTop: 1 }}
          >
            {t("common.toSave")}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

function ControlledCustomTextField({
  name,
  control,
  defaultValue,
  label,
}: {
  name: string;
  control: any;
  defaultValue?: string;
  label: string;
}) {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Stack spacing={1} sx={{ flex: 1 }}>
          <Typography variant="body2">{t(label)}</Typography>
          <CustomTextField value={value} onChange={onChange} />
        </Stack>
      )}
    />
  );
}
