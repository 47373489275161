import { Button, InputAdornment, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useParams, useHistory } from "react-router-dom";
import useGreyBackground from "../hooks/useGreyBackground";
import { Campaign } from "../types/campaign.types";
import Divider from "@mui/material/Divider";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { useTheme } from "@mui/material/styles";
import IOSSwitch from "../components/IOSSwitch";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClosePageButton from "../components/ClosePageButton";
import CustomTextField from "../components/CustomTextField";
import useSWR from "swr";
import useServices from "../hooks/useServices.hook";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ResponseEndPage from "../components/Response/ResponseEndPage";
import ViewRenderingButtonModal from "../components/ViewRenderingButtonModal";
import { CompactPicker } from "react-color";
import usePage from "../hooks/usePage";

// todo : add media to campaign end page
export default function CustomThankYouPage(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [htmlForPage, setHtmlForPage] = React.useState<boolean>(false);

  usePage({ isMenuActive: false });
  useGreyBackground();
  const { idCampaign } = useParams();
  let history = useHistory();
  const [, setMedia] = React.useState<File>(null);

  const { campaignsService } = useServices();
  const { data: campaign, mutate: mutateCampaign } = useSWR(
    ["campaigns", idCampaign],
    (key, id) => campaignsService.getById(id)
  );

  const [previewData, setPreviewData] = React.useState<Campaign>(campaign);

  const { handleSubmit, control, getValues, reset } = useForm();

  React.useEffect(() => {
    reset(campaign);
    if (campaign?.endPageIsHTML) {
      setHtmlForPage(true);
    }
  }, [campaign, reset]);

  function handleViewRender() {
    setPreviewData(getValues());
  }

  async function handleSave(data) {
    try {
      await campaignsService.update(idCampaign, data);
      enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
      mutateCampaign({
        ...campaign,
        ...data,
      });
      handleClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
  }

  function handleClose() {
    history.goBack();
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb={2}
        mt={matchesSmScreen ? 0 : 6}
      >
        <ClosePageButton onClick={handleClose} />

        <Typography variant="h4" color="primary" mt={5} textAlign="center">
          {t("pages.customQuestionThankYouPage.thankYouScreen")}
        </Typography>

        <Stack
          direction="row"
          spacing={1}
          alignSelf="self-start"
          width="80%"
          ml="10%"
          mt={5}
        >
          <Typography variant="body2">
            {t("pages.customQuestionThankYouPage.endPageIsHTML")}
          </Typography>
          <ControlledSwitch
            control={control}
            name="endPageIsHTML"
            defaultValue={campaign?.endPageIsHTML}
            onSwitch={(checked) => {
              setHtmlForPage(checked);
            }}
          />
        </Stack>
        <Box
          display="flex"
          flexDirection={matchesSmScreen ? "column" : "row"}
          justifyContent="space-between"
          flexWrap="wrap"
          width="80%"
          mt={5}
        >
          {!htmlForPage ? (
            <>
              <Stack spacing={2} minWidth="45%">
                <Typography
                  variant="h5"
                  fontWeight={500}
                  fontSize="24px"
                  mb={4}
                >
                  {t("pages.customQuestionThankYouPage.yourMessage")}
                </Typography>

                <Stack spacing={1}>
                  <Typography variant="body2">
                    {t("pages.customQuestionThankYouPage.title")}
                  </Typography>
                  <ControlledCustomTextField
                    control={control}
                    defaultValue={campaign?.endPageTitle}
                    name="endPageTitle"
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    {t("pages.customQuestionThankYouPage.subtitle")}
                  </Typography>
                  <ControlledCustomTextField
                    control={control}
                    defaultValue={campaign?.endPageSubTitle}
                    name="endPageSubTitle"
                  />
                </Stack>

                <Stack spacing={1}>
                  <Typography variant="body2">
                    {t("pages.customQuestionThankYouPage.buttonText")}
                  </Typography>
                  <ControlledCustomTextField
                    control={control}
                    defaultValue={campaign?.endPageButtonText}
                    name="endPageButtonText"
                  />
                </Stack>
              </Stack>
              {matchesSmScreen ? (
                <Box mt={4}></Box>
              ) : (
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderColor: "white" }}
                />
              )}

              <Stack minWidth="45%" spacing={2} justifyContent="space-between">
                <Typography
                  variant="h5"
                  fontWeight={500}
                  fontSize="24px"
                  mb={4}
                >
                  {t("pages.customQuestionThankYouPage.layout")}
                </Typography>

                <Stack spacing={1}>
                  <input
                    style={{ display: "none" }}
                    id="button-file"
                    type="file"
                    onChange={(event) => {
                      setMedia(event.target.files[0]);
                    }}
                  />
                  <label htmlFor="button-file">
                    <CustomTextField
                      sx={{ cursor: "pointer" }}
                      InputProps={{
                        style: { cursor: "pointer" },
                        startAdornment: (
                          <InputAdornment position="start">
                            <ArrowDownwardOutlinedIcon
                              sx={{ color: theme.palette.primary.main }}
                            />
                            <Typography variant="body2" color="black" ml={1.5}>
                              {t("pages.customQuestionThankYouPage.addMedia")}
                            </Typography>
                          </InputAdornment>
                        ),
                        disabled: true,
                      }}
                    />
                  </label>
                  {/*
                            <CustomTextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <ArrowDownwardOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                                            <Typography variant="body2" color="black" ml={1.5}>
                                                Ajouter un média
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            */}
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    {t("pages.customQuestionThankYouPage.mainColor")}
                  </Typography>
                  <ControlledColorSelector
                    control={control}
                    defaultValue={campaign?.endPagePrimaryColor}
                    name="endPagePrimaryColor"
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    {t("pages.customQuestionThankYouPage.buttonLink")}
                  </Typography>
                  <ControlledCustomTextField
                    type="url"
                    control={control}
                    defaultValue={campaign?.endPageButtonLink}
                    name="endPageButtonLink"
                  />
                </Stack>
              </Stack>
            </>
          ) : (
            <>
              <Stack spacing={1} width="100%">
                <Typography variant="body2">
                  {t("pages.customQuestionThankYouPage.html")}
                </Typography>
                {/*
                                <ControlledCustomTextField
                                    control={formMethods.control}
                                    multiline
                                    defaultValue={campaign.textEmail}
                                    name="textEmail"
                                    sx={{
                                        width: matchesSmScreen ? '100%' : '50%',
                                        maxWidth: '100%'
                                    }}
                                />
                                */}
                <ControlledCustomTextField
                  control={control}
                  name="endPageHTML"
                  defaultValue={campaign.endPageHTML || ""}
                  multiline
                />
              </Stack>
            </>
          )}
        </Box>

        <ViewRenderingButtonModal
          sx={{ marginTop: 8 }}
          onOpen={handleViewRender}
        >
          <ResponseEndPage
            defaultProps={previewData}
            sx={{ width: "100%", height: "100%" }}
          />
        </ViewRenderingButtonModal>
        <Button
          type="submit"
          variant="contained"
          sx={{
            borderRadius: "100px",
            height: "50px",
            marginTop: 1,
            minWidth: "336px",
          }}
        >
          {t("common.toSave")}
        </Button>
      </Box>
    </form>
  );
}

function ControlledCustomTextField({
  multiline,
  type,
  name,
  control,
  defaultValue,
}: {
  multiline?: boolean;
  type?: string;
  name: string;
  control: any;
  defaultValue?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <CustomTextField
          multiline={multiline}
          minRows={multiline ? 5 : 0}
          type={type || "text"}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
}

function ControlledColorSelector({
  name,
  control,
  defaultValue,
}: {
  name: string;
  control: any;
  defaultValue?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Box
          sx={{
            maxWidth: "500px",
            ">*": {
              width: "100%",
            },
            ">*>*": {
              boxShadow: "none!important",
            },
            ">*>*>*": {
              width: "auto!important",
              padding: "8px!important",
            },
          }}
        >
          <CompactPicker
            color={value || ""}
            onChangeComplete={(color) => onChange(color?.hex)}
          />
        </Box>
      )}
    />
  );
}

function ControlledSwitch({
  name,
  control,
  defaultValue,
  onSwitch,
}: {
  onSwitch?: (val: boolean) => void;
  name: string;
  control: any;
  defaultValue?: boolean;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || false}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <>
          {/* @ts-ignore */}
          <IOSSwitch
            checked={!!value}
            onChange={(event, checked) => {
              onChange(checked);
              if (onSwitch) {
                onSwitch(checked);
              }
            }}
          />
        </>
      )}
    />
  );
}
