export function utf8_to_b64(str) {
    return window.btoa(unescape(encodeURIComponent(str)));
}

export function b64_to_utf8(str) {
    return decodeURIComponent(escape(window.atob(str)));
}

export function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace("#", "");
    var r = parseInt(hexcolor.substr(0, 2), 16);
    var g = parseInt(hexcolor.substr(2, 2), 16);
    var b = parseInt(hexcolor.substr(4, 2), 16);
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? 'black' : 'white';
}

export const IDEncoder = {
    CODE_KEY: 12.12,

    encodeID(id: number | string) {
        return btoa((id as unknown as number * this.CODE_KEY) as unknown as string)
    },

    decodeID(code: string) {
        const data = atob(code);
        return data as unknown as number / this.CODE_KEY;
    }
}



