import React from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import QuestionStepper from "../components/QuestionStepper";
import useServices from "../hooks/useServices.hook";
import { Question } from "../types/campaign.types";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import usePage from "../hooks/usePage";

export default function CreateVideoQuestion() {
  const { t } = useTranslation();

  usePage({ isMenuActive: false });
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const { idCampaign } = useParams();
  const { campaignsService } = useServices();
  const { data: campaign } = useSWR(["campaigns", idCampaign], (key, id) =>
    campaignsService.getById(id)
  );

  async function handleValidate({ videoBlob, text }: Question) {
    try {
      let formData = new FormData();
      if (videoBlob) {
        formData.append(
          "video",
          new File(
            [videoBlob],
            videoBlob["name"] || `video.${videoBlob.type.split("/")[1]}`,
            {
              type: videoBlob.type,
            }
          )
        );
      }

      formData.append("text", text);

      await campaignsService.createQuestions(idCampaign, formData);
      enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
    history.goBack();
  }

  return (
    <QuestionStepper handleValidate={handleValidate} campaign={campaign} />
  );
}
