import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import AuthService from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import { authState } from "../../stores/auth.store";
import { useRecoilState } from "recoil";
import logoImg from "../../assets/logo_white.png";
import { useTranslation } from "react-i18next";
import { confirm } from "../../plugins/confirm.plugin";

export default function Menu(props) {
  const theme = useTheme();
  const history = useHistory();

  const [auth] = useRecoilState(authState);

  const { t } = useTranslation();

  const avatarUrl = auth?.avatar;

  function goToAccount(e) {
    e.preventDefault();
    history.push("/account");
  }

  function goToHome(e) {
    e.preventDefault();
    history.push("/");
  }

  async function handleDiconnect() {
    if (await confirm(t("common.sureDisconnect"))) {
      AuthService.disconnect();
      history.push("/login");
    }
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: theme.palette.primary.main,

          width: "60px",
          borderRadius: "0px 16px 16px 0px",
          position: "fixed",
          margin: 0,
          top: 0,
          bottom: 0,
          left: 0,
          paddingBottom: "50px",
          paddingTop: "50px",
          color: "white",
        }}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          pr={0.5}
        >
          <IconButton onClick={goToHome}>
            <img
              alt="logo"
              src={logoImg}
              style={{
                width: "100%",
              }}
            />
          </IconButton>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          pr={0.5}
        >
          <IconButton onClick={goToAccount}>
            {avatarUrl ? (
              <Avatar
                alt={`${auth?.firstname || ""} ${auth?.lastname || ""}`}
                src={avatarUrl}
                sx={{ width: 35, height: 35 }}
              />
            ) : (
              <Avatar sx={{ width: 35, height: 35 }}>
                {(auth?.firstname || "A")[0].toUpperCase()}
                {(auth?.lastname || "A")[0].toUpperCase()}
              </Avatar>
            )}
          </IconButton>
          <IconButton onClick={handleDiconnect}>
            <ExitToAppIcon
              fontSize="large"
              sx={{ color: "white", transform: "rotate(180deg)" }}
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
