import { Button } from "@mui/material";
import React from "react";
import { Box, } from "@mui/system";
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export default function AddButton(props) {
    const { t } = useTranslation();

    const theme = useTheme();
    const [open, setOpen] = React.useState<boolean>(false);
    let history = useHistory();

    function toggleOpen(): void {
        setOpen(!open);
    }

    function handleCreateCampaign() {
        history.push("/create-campaign");
    }

    function handleCreateFolder() {
        history.push("/create-folder");
    }

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            sx={{
                position: 'absolute',
                bottom: 16,
                right: 16,
            }}>
            {open && (
                <ClickAwayListener onClickAway={toggleOpen}>
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-end"
                    >
                        <Button onClick={handleCreateFolder} color="inherit" variant="contained" sx={{ background: theme.palette.primary.light, color: theme.palette.primary.main, borderRadius: '100px', height: '50px', marginBottom: '4px' }}>
                            <AddIcon /> {t('pages.home.createFolder')}
                        </Button>
                        <Button onClick={handleCreateCampaign} variant="contained" sx={{ borderRadius: '100px', height: '50px', marginBottom: '4px' }}>
                            <AddIcon />  {t('pages.home.createCampaign')}
                        </Button>
                    </Box>
                </ClickAwayListener>

            )}
            <Fab color="primary" aria-label="add" onClick={toggleOpen}>
                <AddIcon />
            </Fab>
        </Box>
    );
}