import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getContrastYIQ } from "../../utils";

interface NotationProps {
  onDone: (data) => void;
  defaultProps?: {
    homePagePrimaryColor: string;
  };
}

export default function Notation({ onDone, defaultProps }: NotationProps) {
  const { t } = useTranslation();
  const [stars, setStars] = React.useState<number>(0);

  const color = defaultProps?.homePagePrimaryColor || "#504AB7";

  async function handleDone(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    onDone({ stars });
  }

  return (
    <Stack
      width="100vw"
      height="90vh"
      spacing={3}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="h4">{t("pages.response.starsProduct")}</Typography>
      <Stack
        direction="row"
        spacing={0.5}
        alignItems="center"
        justifyContent="center"
        sx={{ color: "#F6D62E" }}
      >
        {[1, 2, 3, 4, 5].map((key) => {
          if (key <= stars) {
            return (
              <StarIcon
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStars(key);
                }}
                fontSize="large"
              />
            );
          }
          return (
            <StarBorderIcon
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                setStars(key);
              }}
              fontSize="large"
            />
          );
        })}
      </Stack>
      <Button
        component="button" //todo check difference
        onClick={handleDone}
        type="button"
        variant="contained"
        sx={{
          borderRadius: "100px",
          height: "50px",
          background: color,
          color: getContrastYIQ(color),
          "&:hover": {
            background: color,
            opacity: 0.7,
            transition: "opacity .2s",
          },
        }}
      >
        {t("common.validate")}
      </Button>
    </Stack>
  );
}
