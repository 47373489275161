import React from "react";
import useGreyBackground from "../../hooks/useGreyBackground";
import { Campaign, Question } from "../../types/campaign.types";
import VideoCreateChoice from "./VideoCreateChoice";
import { useHistory } from "react-router-dom";
import Preview from "./Preview";
import VideoRecord from "./VideoRecord";

type Steps = "video-create-choice" | "video-record" | "preview";

interface QuestionStepperProps {
  campaign: Campaign;
  question?: Question;
  handleValidate: (question: Question) => void | Promise<void>;
  onDelete?: () => void;
}

export default function QuestionStepper({
  campaign,
  question,
  handleValidate,
  onDelete,
}: QuestionStepperProps) {
  useGreyBackground();
  let history = useHistory();

  const isUpdateMode = question?.id;

  const [editedQuestion] = React.useState(question ? question : {});
  const [newVideoBlob, setNewVideoBlob] = React.useState<Blob>(null);
  const [newPoster, setNewPoster] = React.useState<string>(null);
  const [previousStep, setPreviousStep] = React.useState<Steps>(null);
  const [step, setStep] = React.useState<Steps>(
    isUpdateMode ? "preview" : "video-create-choice"
  );

  function handleQuit() {
    history.goBack();
  }

  function handleGoStep(newStep: Steps) {
    setPreviousStep(step);
    setStep(newStep);
  }

  function handleVideoBlobComplete(blob: Blob, poster?: string) {
    setNewVideoBlob(blob);
    setNewPoster(poster);
    handleGoStep("preview");
  }

  function handleGoPreviousStep() {
    handleGoStep(previousStep);
  }

  function handleDelete() {
    if (onDelete) {
      onDelete();
    }
  }

  return (
    <>
      {step === "video-create-choice" && (
        <VideoCreateChoice
          onGoRecord={() => {
            handleGoStep("video-record");
          }}
          onVideoBlobComplete={handleVideoBlobComplete}
          onClose={handleQuit}
          onGoPrevious={handleQuit}
          onGoTextQuestion={() => {
            handleGoStep("preview");
          }}
        />
      )}
      {step === "video-record" && (
        <VideoRecord
          onVideoBlobComplete={handleVideoBlobComplete}
          onClose={handleQuit}
          onGoPrevious={handleGoPreviousStep}
        />
      )}
      {step === "preview" && (
        <Preview
          onDelete={handleDelete}
          onValidate={handleValidate}
          videoBlob={newVideoBlob}
          question={editedQuestion}
          poster={newPoster}
          onClose={handleQuit}
          onRestartRecord={handleGoPreviousStep}
        />
      )}
    </>
  );
}
