import React from "react";
import PauseCircleOutlinedIcon from "@mui/icons-material/PauseCircleOutlined";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { Box } from "@mui/system";
import { API_VIDEO_URL } from "../config";

interface VideoPlayerProps {
  size: number | string;
  type?: string;
  src?: string | Array<string>;
  blob?: Blob;
  poster?: string | null;
  borderRadius?: number;
  iconColor?: string;
  iconFontSize?: number;
}

export default function VideoPlayer({
  size,
  type,
  src,
  blob,
  borderRadius,
  iconColor,
  iconFontSize,
  poster,
}: VideoPlayerProps) {
  const videoRef = React.useRef(null);

  const [paused, setPaused] = React.useState<boolean>(true);
  const [ended, setEnded] = React.useState<boolean>(false);

  function changeSrc(autoplay: boolean, videoSrc) {
    videoRef.current.setAttribute("src", videoSrc);
    videoRef.current.setAttribute("type", "video/mp4");
    videoRef.current.load();
    if (autoplay) {
      videoRef.current.play();
    }
  }

  React.useEffect(() => {
    if (videoRef?.current) {
      videoRef.current.onended = () => {
        if (Array.isArray(src)) {
          let endCurrentSRC =
            videoRef.current.src.split("\\")[
              videoRef.current.src.split("\\").length - 1
            ];
          endCurrentSRC =
            endCurrentSRC.split("/")[endCurrentSRC.split("/").length - 1];
          const index = src.findIndex((s) => {
            let end = s.split("\\")[s.split("\\").length - 1];
            end = end.split("/")[end.split("/").length - 1];
            return end === endCurrentSRC;
          });
          if (index === src.length - 1) {
            changeSrc(false, API_VIDEO_URL + src[0]);
            setEnded(true);
          } else {
            changeSrc(true, API_VIDEO_URL + src[index + 1]);
          }
        } else {
          setEnded(true);
        }
      };
      videoRef.current.onpause = () => {
        setPaused(true);
      };
      videoRef.current.onplay = () => {
        setEnded(false);
        setPaused(false);
      };

      if (Array.isArray(src)) {
        changeSrc(false, API_VIDEO_URL + src[0]);
      }
      if (blob) {
        //videoRef.current.src =
        src = URL.createObjectURL(blob);
        videoRef.current.src = URL.createObjectURL(blob);
        videoRef.current.poster = poster;
      }
    }
  }, [videoRef]);

  React.useEffect(() => {
    // usefull when blob change to src (problem with lifecycle)
    if (!blob && src && videoRef?.current) {
      videoRef.current.src = API_VIDEO_URL + src;
    }
  }, [blob]);

  function handleVideoPlayToggle(e) {
    e.stopPropagation();

    if (videoRef?.current?.paused || videoRef?.current?.ended) {
      videoRef?.current?.play();
    } else {
      videoRef?.current?.pause();
    }
  }
  interface VideoProps {
    width: string | number;
    height: string | number;
    poster?: string;
  }
  let videoProps: VideoProps = {
    width: size,
    height: size,
  };
  if (poster) {
    videoProps.poster = poster;
  }

  return (
    <Box
      onClick={handleVideoPlayToggle}
      width={size}
      height={size}
      sx={{
        position: "relative",
        borderRadius: `${borderRadius}px`,
        cursor: "pointer",
      }}
    >
      <video
        {...videoProps}
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
          borderRadius: `${borderRadius}px`,
        }}
        ref={videoRef}
      >
        {src &&
          type &&
          (!Array.isArray(src) && src?.length ? (
            <source src={API_VIDEO_URL + src} type={type}></source>
          ) : (
            <></>
          ))}
      </video>

      {(src || blob) && (ended || paused) && (
        <PlayCircleFilledIcon
          sx={{
            color: iconColor || "#E3E9FC",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: `${iconFontSize || 100}px`,
          }}
        />
      )}
      {(src || blob) && !ended && !paused && (
        <PauseCircleOutlinedIcon
          className="displayOnParentHover"
          sx={{
            color: iconColor || "#E3E9FC",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontSize: `${iconFontSize || 100}px`,
          }}
        />
      )}
    </Box>
  );
}
