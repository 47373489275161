import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import frLocale from "date-fns/locale/fr";
import ruLocale from "date-fns/locale/ru";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { useTranslation } from "react-i18next";

const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
  de: deLocale,
};

const maskMap = {
  fr: "__/__/____",
  en: "__/__/____",
  ru: "__.__.____",
  de: "__.__.____",
};

interface ControlledDatePickerProps {
  control: any;
  name: string;
  defaultValue?: Date | number;
  datePickerProps?: any;
  label?: string;
}

export default function ControlledDatePicker(props: ControlledDatePickerProps) {
  const { i18n } = useTranslation();

  const locale = i18n.language || "fr";

  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={props.defaultValue}
      render={({ field: { onChange, value } }) => (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={localeMap[locale]}
        >
          <Stack direction="column" spacing={0.5}>
            <Typography variant="body2" color="#18181A">
              {props?.label}
            </Typography>
            <DatePicker
              {...props.datePickerProps}
              mask={maskMap[locale]}
              value={value}
              onChange={(newValue) => {
                onChange(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Stack>
        </LocalizationProvider>
      )}
    />
  );
}
