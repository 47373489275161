
import { Site } from "../types/user.types";
import EntityService from "./entity.service";

export default class SitesService extends EntityService<Site> {
    
    baseUrl = 'sites';

    mapToEntity(data: any): Site {
        return data as Site;
    }
}
