import React from "react";
import { Button, ButtonProps } from "@mui/material";

interface PaperButtonProps extends ButtonProps {

}

export default function PaperButton(props: PaperButtonProps) {


    return (
        <Button style={{
            background: '#FFFFFF',
            boxShadow: '1px 2px 23px rgba(76, 70, 166, 0.13)',
            borderRadius: '8px',
        }} {...props}>
            {props.children}
        </Button>
    );
}