import React from "react";
import { Box, InputAdornment, Typography } from "@mui/material";
import CustomTextField, { CustomTextFieldProps } from "./CustomTextField";
import IOSSwitch from "./IOSSwitch";

type CustomSwitchFieldProps = CustomTextFieldProps & {
    label: string;
    switchNextToLabel?: boolean;
    checked?: boolean;
    onSwitchChange?: (boolean) => void;
}

export default function CustomSwitchField({ label, switchNextToLabel, checked, ...props }: CustomSwitchFieldProps) {

    function onSwitchChange(event, checked: boolean) {
        if (props.onSwitchChange) {
            props.onSwitchChange(checked);
        }
    }

    const inputProps = {
        startAdornment: (
            <InputAdornment position="start">

                {switchNextToLabel ? (
                    <>
                        <Box minWidth="160px">
                            <Typography variant="body2" color={props.disabled ? "gray" :"black"}>
                                {label}
                            </Typography>
                        </Box>
                        {/* @ts-ignore */}
                        <IOSSwitch checked={checked} onChange={onSwitchChange} />
                    </>
                ) : (
                    <Typography variant="body2"  color={props.disabled ? "gray" :"black"}>
                        {label}
                    </Typography>
                )}

            </InputAdornment>
        ),
        endAdornment: switchNextToLabel ? <></> : (
            <InputAdornment position="end">
                {/* @ts-ignore */}
                <IOSSwitch checked={checked} onChange={onSwitchChange} disabled={props.disabled} />
            </InputAdornment>
        )
    };

    return (
        <CustomTextField
            disabled
            InputProps={inputProps}
            {...props}
        />
    )
}