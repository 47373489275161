import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import ResponseEndPage from "../../components/Response/ResponseEndPage";
import ResponseHomePage from "../../components/Response/ResponseHomePage";
import usePage from "../../hooks/usePage";
import useServices from "../../hooks/useServices.hook";
import QuestionsResponsesBuilder, {
  QuestionResponse,
} from "../../components/QuestionsResponsesBuilder";
import Notation from "./Notation";
import { IDEncoder } from "../../utils";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Step = "not-started" | "notation" | "started" | "ended";

export default function ResponsePage() {
  const { idCampaign } = useParams();

  const { t } = useTranslation();

  const [data, setData] = useState({});
  usePage({ isMenuActive: false });

  const [currentStep, setCurrentStep] = useState<Step>("not-started");
  const [questionResponse, setQuestionResponse] = useState<QuestionResponse[]>(
    []
  );

  const { campaignsService } = useServices();
  const { data: campaign } = useSWR(
    ["campaigns", Math.round(IDEncoder.decodeID(idCampaign))],
    (key, id) => campaignsService.getById(id)
  );

  useEffect(() => {
    if (campaign?.questions && !questionResponse?.length) {
      setQuestionResponse(
        campaign?.questions
          .map((q) => ({
            question: q,
          }))
          .sort((a, b) => a.question.order - b.question.order)
      );
    }
  }, [campaign, questionResponse]);

  async function onQuestionsResponsesEnd(qrs) {
    setCurrentStep("ended");
    campaignsService.endResponse(campaign?.id, {
      responseId: data["responseId"],
    });
  }

  if (!campaign) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <CircularProgress color="primary" />
      </Stack>
    );
  }

  if (campaign && campaign?.status !== "inProgress") {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        <Typography variant="body1">
          {t("common.sorryYouCantTestify")}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      {currentStep === "not-started" && (
        <ResponseHomePage
          campaign={campaign}
          onStart={(newData) => {
            setData(newData);
            setCurrentStep(campaign?.notations ? "notation" : "started");
          }}
          defaultProps={{
            homePagePrimaryColor: campaign?.homePagePrimaryColor,
            homePageTitle: campaign?.homePageTitle,
            homePageSubTitle: campaign?.homePageSubTitle,
            emailNeeded: campaign?.emailNeeded,
            firstnameNeeded: campaign?.firstnameNeeded,
            nameNeeded: campaign?.nameNeeded,
            purchaseDateNeeded: campaign?.purchaseDateNeeded,
          }}
        />
      )}
      {currentStep === "notation" && (
        <Notation
          onDone={(newData) => {
            setData({ ...data, ...newData });
            setCurrentStep("started");
          }}
          defaultProps={{
            homePagePrimaryColor: campaign?.homePagePrimaryColor,
          }}
        />
      )}
      {currentStep === "started" && (
        <QuestionsResponsesBuilder
          setData={setData}
          userData={data}
          initialValue={questionResponse}
          onEnd={onQuestionsResponsesEnd}
        />
      )}
      {currentStep === "ended" && (
        <ResponseEndPage
          defaultProps={{
            endPageButtonLink: campaign?.endPageButtonLink,
            endPageButtonText: campaign?.endPageButtonText,
            endPagePrimaryColor: campaign?.endPagePrimaryColor,
            endPageSubTitle: campaign?.endPageSubTitle,
            endPageTitle: campaign?.endPageTitle,

            endPageIsHTML: campaign?.endPageIsHTML,
            endPageHTML: campaign?.endPageHTML,
          }}
        />
      )}
    </>
  );
}
