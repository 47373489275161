// export const API_URL = 'http://192.168.1.159:3001/';
// export const API_VIDEO_URL = 'http://192.168.1.159:3001/';
// export const FRONTEND_URL = 'http://localhost:3000/';

//ABOVE : localhost is needed to make mediaDevices available (https or Localhost)

// export const API_URL = 'http://82.66.43.207:3001/';
// export const API_VIDEO_URL = 'http://82.66.43.207:3001/';
// export const FRONTEND_URL = 'http://82.66.43.207:3000/';
/*
export const API_URL = 'https://apigrowproof.demoapp.fr/';
export const API_VIDEO_URL = 'https://apigrowproof.demoapp.fr/';
export const FRONTEND_URL = 'https://growproof.demoapp.fr/';
*/

export const API_URL = 'https://app.growproof.io/';
export const API_VIDEO_URL = 'https://app.growproof.io/';
export const FRONTEND_URL = 'https://app.growproof.io/';


export const isMockMode: boolean = false;