import { Box } from "@mui/system";
import React from "react";

interface AloneBadgeProps {
    color?: string;
    size?: 'sm' | 'md' |'lg'; 
    sx?: any;
}

export default function AloneBadge({color, size, sx}: AloneBadgeProps) {
    const widthAndHeight = size === 'sm' ? '10px' : (size === 'md' ? '15px' : '25px');
    const applyColor = color || 'black';

    return (
        <Box component="div" sx={{display: 'inline-block', width: widthAndHeight, height: widthAndHeight, background: applyColor, borderRadius: '50%', ...sx}}>

        </Box>
    );
}