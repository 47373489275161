import { Box, useTheme } from "@mui/material";
import React from "react";
import { Question } from "../../types/campaign.types";
import QuestionBlock from "./QuestionBlock";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

interface useQuestionsBlockProps {
  onClick: (id: number) => void;
  questions: Array<Question>;
  disabledDradNDrop?: boolean;
  onReorder?: (questions) => void;
}

export default function useQuestionsBlock({
  questions,
  onClick,
  onReorder,
  disabledDradNDrop,
}: useQuestionsBlockProps) {
  const theme = useTheme();

  const [questionsItems, setQuestionsItems] = React.useState(
    questions?.length ? questions.sort(compareQuestionsOrder) : []
  );

  React.useEffect(() => {
    setQuestionsItems(
      questions?.length ? questions.sort(compareQuestionsOrder) : []
    );
  }, [questions]);

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      questionsItems,
      result.source.index,
      result.destination.index
    );

    if (onReorder) {
      onReorder(items);
    }

    setQuestionsItems(items);
  }

  function handleQuestionClick(id: number) {
    onClick && onClick(id);
  }

  function displayQuestionBlocks(
    startBlock?: JSX.Element,
    endBlock?: JSX.Element
  ) {
    let questionColor = theme.palette.warning;
    if (disabledDradNDrop) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            "&>*": {
              mr: 1,
              mb: 1,
            },
          }}
        >
          {!!startBlock && startBlock}
          {questions?.map((question, index) => {
            const color = questionColor;
            questionColor =
              questionColor.main === theme.palette.warning.main
                ? theme.palette.primary
                : questionColor.main === theme.palette.primary.main
                ? theme.palette.success
                : theme.palette.warning;
            return (
              <QuestionBlock
                color={color}
                key={question.id}
                imageUrl={question.imageUrl}
                videoUrl={question.videoUrl}
                videoType={question.videoType}
                defaultText={question.text}
                number={index + 1}
                onClick={() => {
                  handleQuestionClick(question.id);
                }}
              />
            );
          })}
          {!!endBlock && endBlock}
        </Box>
      );
    } else {
      return (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="horizontal">
            {(provided, snapshot) => (
              <Box
                ref={provided.innerRef}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  "&>*": {
                    mr: 1,
                    mb: 1,
                  },
                }}
                {...provided.droppableProps}
              >
                {!!startBlock && startBlock}
                {questionsItems?.map((question, index) => {
                  const color = questionColor;
                  questionColor =
                    questionColor.main === theme.palette.warning.main
                      ? theme.palette.primary
                      : questionColor.main === theme.palette.primary.main
                      ? theme.palette.success
                      : theme.palette.warning;
                  return (
                    <Draggable
                      key={question.id}
                      draggableId={"" + question.id}
                      index={index}
                      disableInteractiveElementBlocking
                    >
                      {(provided, snapshot) => (
                        <QuestionBlock
                          color={color}
                          key={question.id}
                          imageUrl={question.imageUrl}
                          videoUrl={question.videoUrl}
                          videoType={question.videoType}
                          defaultText={question.text}
                          number={index + 1}
                          onClick={() => {
                            handleQuestionClick(question.id);
                          }}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          innerRef={provided.innerRef}
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                        />
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
                {!!endBlock && endBlock}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  }

  return { QuestionBlock, displayQuestionBlocks };
}

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  opacity: isDragging ? 0.9 : 1,
  "& *": {
    opacity: isDragging ? 0.9 : 1,
  },
  // styles we need to apply on draggables
  ...draggableStyle,
});

function compareQuestionsOrder(a: Question, b: Question) {
  if (a.order < b.order) return -1;
  if (a.order > b.order) return 1;
  return 0;
}
