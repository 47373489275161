import React from "react";
import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getContrastYIQ } from "../../utils";

interface ResponseEndPageProps {
  defaultProps?: {
    endPageTitle?: string;
    endPageSubTitle?: string;
    endPageButtonText?: string;
    endPagePrimaryColor?: string;
    endPageButtonLink?: string;

    endPageHTML?: string;
    endPageIsHTML?: boolean;
  };
  sx?: any;
}

export default function ResponseEndPage({
  defaultProps,
  sx,
}: ResponseEndPageProps) {
  const theme = useTheme();
  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const title = defaultProps.endPageTitle || "";
  const subtitle = defaultProps.endPageSubTitle || "";
  const color = defaultProps.endPagePrimaryColor || "#504AB7";
  const buttonText = defaultProps.endPageButtonText || "";
  const buttonLink = defaultProps.endPageButtonLink || "";

  if (defaultProps?.endPageIsHTML) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100vw"
        sx={sx}
      >
        <div dangerouslySetInnerHTML={{ __html: defaultProps?.endPageHTML }} />
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
      sx={sx}
    >
      <Stack
        spacing={4}
        alignItems="center"
        justifyContent="center"
        width={matchesSmScreen ? "95%" : "65%"}
      >
        {title && (
          <Typography variant="h4" color={color} textAlign="center">
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography
            variant="h5"
            fontWeight={400}
            fontSize="24px"
            color="#18181A"
            textAlign="center"
          >
            {subtitle}
          </Typography>
        )}
        {buttonLink?.length && buttonText?.length ? (
          <a href={buttonLink}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "100px",
                height: "50px",
                background: color,
                color: getContrastYIQ(color),
                "&:hover": {
                  background: color,
                  opacity: 0.7,
                  transition: "opacity .2s",
                },
              }}
            >
              {buttonText}
            </Button>
          </a>
        ) : (
          <></>
        )}
      </Stack>
    </Stack>
  );
}
