import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { Response } from "../../types/campaign.types";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import VideoPlayer from "../../components/VideoPlayer";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { API_VIDEO_URL } from "../../config";
import useSWR, { mutate } from "swr";
import useServices from "../../hooks/useServices.hook";
import { useSnackbar } from "notistack";
import axios from "../../plugins/axios.plugin";
import { useTranslation } from "react-i18next";
import ReactDOM from "react-dom";

interface ResponseBlockProps {
  response: Response;
  mutateCampaigns?: () => void;
}

export default function ResponseBlock({
  response,
  mutateCampaigns,
}: ResponseBlockProps) {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = React.useState<boolean>(false);

  return (
    <>
      {ReactDOM.createPortal(
        <ModalResponseTreatment
          mutateCampaigns={mutateCampaigns}
          response={response}
          open={isModalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        />,
        document.getElementsByTagName("body")[0]
      )}

      <Stack
        className="scale-up-button"
        onClick={() => setModalOpen(true)}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        spacing={0.5}
        sx={{
          cursor: "pointer",
        }}
      >
        <Stack display="flex" direction="column" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              position: "relative",
              borderRadius: "24px",
              boxShadow: 1,
            }}
          >
            {/*
                        <img src={API_VIDEO_URL + response.imageUrl} style={{ borderRadius: '24px', width: '160px', height: '160px' }} />
                        */}
            <video
              style={{
                borderRadius: "24px",
                width: "160px",
                height: "160px",
                objectFit: "cover",
              }}
            >
              <source
                src={API_VIDEO_URL + response.videoUrl + "#t=0.1"}
                type={response.videoType}
              ></source>
            </video>
            {response.status && (
              <Stack
                direction="row"
                spacing={0.5}
                sx={{
                  position: "absolute",
                  bottom: 0,
                  height: "30px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    response.status === "archived"
                      ? "#FFEDDC"
                      : response.status === "inappropriate"
                      ? "#FFF0F6"
                      : "#EEFEEF",
                  color:
                    response.status === "archived"
                      ? "#BB8653"
                      : response.status === "inappropriate"
                      ? "#ED2E7E"
                      : "#2B683C",
                  borderRadius: "0 0 24px 24px",
                }}
              >
                <LoyaltyIcon fontSize="small" />
                <Typography variant="body1">
                  {response.status === "archived" &&
                    t("pages.campaign.archived")}
                  {response.status === "inappropriate" &&
                    t("pages.campaign.inappropriate")}
                  {response.status === "broadcast" &&
                    t("pages.campaign.broadcast")}
                </Typography>
              </Stack>
            )}
          </Box>

          <Typography variant="body1">
            {response.firstname || ""} {response.lastname || ""}
          </Typography>

          {response?.stars && (
            <Box sx={{ color: "#F6D62E" }}>
              {response?.stars >= 1 ? (
                <StarIcon fontSize="small" />
              ) : (
                <StarBorderIcon fontSize="small" />
              )}
              {response?.stars >= 2 ? (
                <StarIcon fontSize="small" />
              ) : (
                <StarBorderIcon fontSize="small" />
              )}
              {response?.stars >= 3 ? (
                <StarIcon fontSize="small" />
              ) : (
                <StarBorderIcon fontSize="small" />
              )}
              {response?.stars >= 4 ? (
                <StarIcon fontSize="small" />
              ) : (
                <StarBorderIcon fontSize="small" />
              )}
              {response?.stars >= 5 ? (
                <StarIcon fontSize="small" />
              ) : (
                <StarBorderIcon fontSize="small" />
              )}
            </Box>
          )}
        </Stack>
      </Stack>
    </>
  );
}

interface ModalResponseTreatmentProps {
  response: Response;
  open: boolean;
  onClose: () => void;
  mutateCampaigns?: () => void;
}

function ModalResponseTreatment({
  response,
  open,
  onClose,
  mutateCampaigns,
}: ModalResponseTreatmentProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { responseService } = useServices();
  const { enqueueSnackbar } = useSnackbar();
  const { data: responseData } = useSWR(["response", response.id], (key, id) =>
    responseService.getById(id)
  );

  const [status, setStatus] = React.useState<
    "archived" | "inappropriate" | "broadcast"
  >(response?.status);

  React.useEffect(() => {
    if (response?.status && !open) {
      setStatus(response?.status);
    }
  }, [open]);

  async function handleBroadcast() {
    try {
      await responseService.update(response.id, { status: "broadcast" });
      setStatus("broadcast");
      if (mutateCampaigns) {
        mutateCampaigns();
      }
      mutate("notifications");
      enqueueSnackbar(t("pages.campaign.responseBroadcasted"), {
        variant: "success",
      });
      onClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("pages.campaign.anErrorHasOccurred"), {
        variant: "error",
      });
    }
  }

  async function handleArchive() {
    try {
      await responseService.update(response.id, { status: "archived" });
      setStatus("archived");
      if (mutateCampaigns) {
        mutateCampaigns();
      }
      mutate("notifications");
      enqueueSnackbar(t("pages.campaign.responseArchived"), {
        variant: "success",
      });
      onClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("pages.campaign.anErrorHasOccurred"), {
        variant: "error",
      });
    }
  }

  async function handleRefuse() {
    try {
      await responseService.update(response.id, { status: "inappropriate" });
      setStatus("inappropriate");
      if (mutateCampaigns) {
        mutateCampaigns();
      }
      mutate("notifications");
      enqueueSnackbar(t("pages.campaign.responseInappropriated"), {
        variant: "success",
      });
      onClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("pages.campaign.anErrorHasOccurred"), {
        variant: "error",
      });
    }
  }

  async function handleDownload() {
    const path = API_VIDEO_URL + response.videoUrl;

    const fileName = `${response?.firstname ? response?.firstname + "_" : ""}${
      response?.lastname ? response?.lastname + "_" : ""
    }testimony.mp4`;

    const apiResponse = await axios.get(path, { responseType: "arraybuffer" });
    let element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;base64," +
        encodeURIComponent(
          Buffer.from(apiResponse.data, "binary").toString("base64")
        )
    );
    element.setAttribute("download", fileName);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return open ? (
    <>
      <Box
        onClick={onClose}
        sx={{
          backgroundColor: "#18181A",
          opacity: ".6",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          margin: "0!important",
          zIndex: 998,
        }}
        ml={0}
      ></Box>
      <Stack
        spacing={2}
        sx={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: matchesSmScreen ? "100%" : "fit-content",

          zIndex: 999,
        }}
      >
        <Stack
          direction={matchesSmScreen ? "row" : "row"}
          spacing={matchesSmScreen ? 1 : 2}
          onClick={(e) => {
            e.stopPropagation();
          }}
          alignItems="center"
          p={matchesSmScreen ? 0 : 2}
          sx={{
            background: "white",
            borderRadius: "32px",
            width: matchesSmScreen ? "100%" : "fit-content",
          }}
        >
          <VideoPlayer
            borderRadius={32}
            size={409}
            src={`${responseData?.videoUrl + "#t=0.1"}`}
            type={responseData?.videoType}
          />
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Typography variant="h4">
                {response.firstname} {response.lastname}
              </Typography>
              {response?.stars && (
                <Box sx={{ color: "#F6D62E" }}>
                  {response?.stars >= 1 ? <StarIcon /> : <StarBorderIcon />}
                  {response?.stars >= 2 ? <StarIcon /> : <StarBorderIcon />}
                  {response?.stars >= 3 ? <StarIcon /> : <StarBorderIcon />}
                  {response?.stars >= 4 ? <StarIcon /> : <StarBorderIcon />}
                  {response?.stars >= 5 ? <StarIcon /> : <StarBorderIcon />}
                </Box>
              )}
            </Stack>
            <Stack spacing={1} sx={{ color: "#9296A1" }}>
              {/*
                            <Typography variant="body1">
                                Produit : Nike Requin
                            </Typography>
                            */}
              {!!response?.purchase && (
                <Typography variant="body1">
                  {t("pages.campaign.testimonyDate")} :{" "}
                  {new Date(response?.purchase).toLocaleDateString()}
                </Typography>
              )}
              {!!response?.email && (
                <Typography variant="body1">
                  {t("pages.campaign.email")} : {response?.email}
                </Typography>
              )}
            </Stack>
            <Stack spacing={1}>
              {/*
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                sx={{ color: '#9296A1', cursor: 'pointer' }}
                            >
                                <RemoveRedEyeOutlinedIcon fontSize="small" fontWeight={700} />
                                <Typography variant="body2">
                                    {t('pages.campaign.showEmbed')}
                                </Typography>
                            </Stack>
                            */}
              {status && (
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  sx={{ color: "#504AB7", cursor: "pointer" }}
                  onClick={() => {
                    setStatus(null);
                  }}
                >
                  <EditOutlinedIcon fontSize="small" fontWeight={700} />
                  <Typography variant="body2">
                    {t("pages.campaign.editStatus")}
                  </Typography>
                </Stack>
              )}
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ color: "#2B2B68", cursor: "pointer" }}
                onClick={handleDownload}
              >
                <DownloadOutlinedIcon fontSize="small" fontWeight={700} />
                <Typography variant="body2">
                  {t("pages.campaign.download")}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {!status ? (
          <>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={handleArchive}
                color="primary"
                variant="contained"
                sx={{
                  flex: 1,
                  borderRadius: "100px",
                  backgroundColor: theme.palette.primary.light,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                  },
                  color: theme.palette.primary.main,
                  pt: 2,
                  pb: 2,
                }}
              >
                <CheckOutlinedIcon sx={{ marginRight: 1 }} />
                {t("pages.campaign.toArchive")}
              </Button>
              <Button
                onClick={handleBroadcast}
                color="success"
                variant="contained"
                sx={{
                  flex: 1,
                  borderRadius: "100px",
                  "&:hover": {
                    color: "white",
                  },
                  pt: 2,
                  pb: 2,
                }}
              >
                <CheckOutlinedIcon sx={{ marginRight: 1 }} />
                {t("pages.campaign.toBroadcast")}
              </Button>
            </Stack>
            <Button
              onClick={handleRefuse}
              variant="text"
              sx={{
                color: "white",
              }}
            >
              <Box
                width="fit-content"
                height="fit-content"
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={0.4}
                sx={{
                  backgroundColor: "white",
                  marginRight: 1,
                  borderRadius: "50%",
                }}
              >
                <ClearIcon sx={{ color: "#ED2E7E" }} />
              </Box>
              {t("pages.campaign.inappropriateTestimony")}
            </Button>
          </>
        ) : status === "broadcast" ? (
          <Button
            onClick={onClose}
            color="success"
            variant="contained"
            sx={{
              flex: 1,
              borderRadius: "100px",
              "&:hover": {
                color: "white",
              },
              pt: 2,
              pb: 2,
            }}
          >
            <CheckOutlinedIcon sx={{ marginRight: 1 }} />
            {t("pages.campaign.testimonyBroadcasted")}
          </Button>
        ) : status === "archived" ? (
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            sx={{
              flex: 1,
              borderRadius: "100px",
              backgroundColor: theme.palette.primary.light,
              "&:hover": {
                backgroundColor: theme.palette.primary.main,
                color: "white",
              },
              color: theme.palette.primary.main,
              pt: 2,
              pb: 2,
            }}
          >
            <CheckOutlinedIcon sx={{ marginRight: 1 }} />
            {t("pages.campaign.testimonyArchived")}
          </Button>
        ) : (
          <Button
            onClick={onClose}
            variant="text"
            sx={{
              color: "white",
            }}
          >
            <Box
              width="fit-content"
              height="fit-content"
              display="flex"
              alignItems="center"
              justifyContent="center"
              p={0.4}
              sx={{
                backgroundColor: "white",
                marginRight: 1,
                borderRadius: "50%",
              }}
            >
              <ClearIcon sx={{ color: "#ED2E7E" }} />
            </Box>
            {t("pages.campaign.inappropriateTestimony")}
          </Button>
        )}
      </Stack>
    </>
  ) : (
    <></>
  );
}
