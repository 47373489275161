import { Campaign, CampaignStats } from "../types/campaign.types";
import EntityService from "./entity.service";

export default class CampaignsService extends EntityService<Campaign> {
  baseUrl = "campaigns";

  async getFromSiteId(siteId: number) {
    return await this.find({ path: `sites/${siteId}/campaigns` });
  }

  async getStats(id: number): Promise<CampaignStats> {
    return this.apiService.get<CampaignStats>({
      path: `${this.baseUrl}/${id}/stats`,
    }) as unknown as CampaignStats;
  }

  async createQuestions(id: number, body: any) {
    return this.apiService.post({
      path: `${this.baseUrl}/${id}/questions`,
      body,
    });
  }

  async createResponse(id: number, body: any) {
    return this.apiService.post({
      path: `${this.baseUrl}/${id}/response`,
      body,
    });
  }
  async createPart(id: number, body: any) {
    // return { id: 1, email: 'abcd' };
    return this.apiService.post({
      path: `${this.baseUrl}/${id}/responsepart`,
      body,
    });
  }
  async endResponse(id: number, body: any) {
    // return { id: 1, email: 'abcd' };
    return this.apiService.post({
      path: `${this.baseUrl}/${id}/responseend`,
      body,
    });
  }

  mapToEntity(data: any): Campaign {
    return data as Campaign;
  }
}
