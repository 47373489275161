import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Stack,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { API_URL } from "../config";
import { useTranslation } from "react-i18next";
import { authState } from "../stores/auth.store";
import { useRecoilState } from "recoil";

import { useSnackbar } from "notistack";

export default function DisplayScriptInfoButtonModal() {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { enqueueSnackbar } = useSnackbar();

  const [auth] = useRecoilState(authState);

  /* const buttonCopyScriptRef = React.useRef<HTMLButtonElement>(null);
    const buttonCopyScriptSellDoneRef = React.useRef<HTMLButtonElement>(null); */

  // const textScript = `<script type="text/javascript" src="${API_URL}public/growproofscript.js?id=${auth}"></script>`;

  // const textScriptPageSellDone = `<script type="text/javascript" src="${API_URL}public/growproofscript_sell_page.js?id=${auth}"></script>`;

  const textScript = `<script type="text/javascript" src="${API_URL}public/growproofscript.js?id=${auth?.base64Id}"></script>`;

  const textScriptPageSellDone = `<script type="text/javascript" src="${API_URL}public/growproofscript_sell_page.js?id=${auth?.base64Id}"></script>`;

  async function copyScript(e) {
    e.stopPropagation();
    navigator.permissions
      .query({ name: "clipboard-write" as PermissionName })
      .then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard.writeText(textScript).then(
            function () {
              enqueueSnackbar(
                t("pages.displayScriptInfoButtonModal.textCopied"),
                { variant: "success" }
              );
            },
            function () {
              /* clipboard write failed */
            }
          );
        }
      });
  }

  async function copyScriptPageSellDone(e) {
    e.stopPropagation();
    navigator.permissions
      .query({ name: "clipboard-write" as PermissionName })
      .then((result) => {
        if (result.state === "granted" || result.state === "prompt") {
          navigator.clipboard.writeText(textScriptPageSellDone).then(
            function () {
              enqueueSnackbar(
                t("pages.displayScriptInfoButtonModal.textCopied"),
                { variant: "success" }
              );
            },
            function () {
              /* clipboard write failed */
            }
          );
        }
      });
  }

  /* 
        async function copyScript(e) {
            e.stopPropagation();
            if (buttonCopyScriptRef && buttonCopyScriptRef.current) {
                buttonCopyScriptRef.current.click();
            }
        }
    
        async function copyScriptPageSellDone(e) {
            e.stopPropagation();
            if (buttonCopyScriptSellDoneRef && buttonCopyScriptSellDoneRef.current) {
                buttonCopyScriptSellDoneRef.current.click();
            }
        } */

  /* React.useEffect(() => {
 
        let clipboardScript = new ClipboardJS(`.copy_script`, {
            text: function (trigger) {
                return textScript;
            }
        });
        clipboardScript.on('success', function (e) {
            console.info('Action:', e.action);
            console.info('Text:', e.text);
            console.info('Trigger:', e.trigger);
            enqueueSnackbar(t('pages.displayScriptInfoButtonModal.textCopied'), { variant: 'info' })
        });
        clipboardScript.on('error', function (e) { console.error(e) });
 
        let clipboardScriptSellDone = new ClipboardJS(`.copy_script_sell_done`, {
            text: function (trigger) {
                return textScriptPageSellDone;
            }
        });
        clipboardScriptSellDone.on('success', function (e) {
            enqueueSnackbar(t('pages.displayScriptInfoButtonModal.textCopied'), { variant: 'info' })
        });
        clipboardScriptSellDone.on('error', function (e) { console.error(e) });
 
        return () => {
            clipboardScript.destroy();
            clipboardScriptSellDone.destroy();
        };
    }, []); */

  return (
    <>
      {/* <button
                type="button"
                className="copy_script_sell_done"
                style={{ display: 'none' }}
                ref={buttonCopyScriptSellDoneRef}
            />
            <button
                type="button"
                className="copy_script"
                style={{ display: 'none' }}
                ref={buttonCopyScriptRef}
            /> */}

      <Button
        onClick={handleOpen}
        variant="contained"
        sx={{
          borderRadius: "100px",
          height: "50px",
        }}
        type="button"
      >
        {t(
          "pages.displayScriptInfoButtonModal.integrateTheScriptOnYourMerchantSite"
        )}
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
            }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>

          <Stack direction="column" spacing={2}>
            <Typography variant="h5">
              {t("pages.displayScriptInfoButtonModal.toUseOurService")}
            </Typography>

            <Stack
              display="flex"
              direction="column"
              spacing={1}
              alignItems="flex-start"
            >
              <Typography variant="body1">
                {t("pages.displayScriptInfoButtonModal.addScriptOnProductPage")}
              </Typography>
              <code>{textScript}</code>
              <Button
                onClick={copyScript}
                variant="contained"
                type="button"
                sx={{
                  borderRadius: "100px",
                  height: "50px",
                }}
              >
                {t("pages.displayScriptInfoButtonModal.toCopy")}
              </Button>
            </Stack>

            <Stack
              display="flex"
              direction="column"
              spacing={1}
              alignItems="flex-start"
            >
              <Typography variant="body1">
                {t(
                  "pages.displayScriptInfoButtonModal.addScriptOnSellDonePage"
                )}
              </Typography>
              <code>{textScriptPageSellDone}</code>
              <Button
                onClick={copyScriptPageSellDone}
                variant="contained"
                type="button"
                sx={{
                  borderRadius: "100px",
                  height: "50px",
                }}
              >
                {t("pages.displayScriptInfoButtonModal.toCopy")}
              </Button>
            </Stack>

            <Stack display="flex" justifyContent="end" flexDirection="row">
              <Button
                onClick={handleClose}
                type="button"
                variant="contained"
                sx={{ borderRadius: "100px", height: "50px" }}
              >
                {t("common.ok")}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
}
