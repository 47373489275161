import { Response } from "../types/campaign.types";
import EntityService from "./entity.service";

export default class ResponseService extends EntityService<Response> {
  baseUrl = "responses";

  mapToEntity(data: any): Response {
    return {
      ...data,
    } as Response;
  }
}
