import React from "react";
import { Box } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { API_VIDEO_URL } from "../../config";
import TextFieldsIcon from "@mui/icons-material/TextFields";

interface QuestionBlockProps {
  imageUrl: string;
  videoUrl: string;
  videoType: string;
  defaultText: string;
  number: number;
  onClick?: () => void;
  color?: any;

  draggableProps?: any;
  dragHandleProps?: any;
  innerRef?: any;
  style?: any;
}

export default function QuestionBlock({
  innerRef,
  dragHandleProps,
  draggableProps,
  style,
  number,
  imageUrl,
  defaultText,
  onClick,
  color,
  videoUrl,
  videoType,
}: QuestionBlockProps) {
  const theme = useTheme();

  const txtQuestion = !videoUrl?.length;

  return (
    <Box
      ref={innerRef}
      {...draggableProps}
      {...dragHandleProps}
      sx={style}
      className={innerRef ? "scale-up-button" : ""}
      onClick={onClick}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Avatar
          sx={{
            backgroundColor: color?.light || theme.palette.warning.light,
            color: color?.dark || theme.palette.warning.dark,
            width: 33,
            height: 33,
          }}
        >
          <Typography variant="subtitle2">{number}</Typography>
        </Avatar>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          width="160px"
          height="235px"
          mt={2}
        >
          <Box sx={{ position: "relative", borderRadius: "24px" }}>
            {txtQuestion ? (
              <Box
                sx={{
                  width: "160px",
                  height: "160px",
                  boxShadow: 1,
                  borderRadius: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "background.paper",
                }}
              >
                <TextFieldsIcon
                  sx={{ color: "primary.main" }}
                  fontSize="large"
                />
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    width: "160px",
                    height: "160px",
                    boxShadow: 1,
                    borderRadius: "24px",
                  }}
                >
                  <video
                    preload="auto"
                    style={{
                      objectFit: "cover",
                      borderRadius: "24px",
                      width: "160px",
                      height: "160px",
                    }}
                  >
                    {!txtQuestion && (
                      <source
                        src={API_VIDEO_URL + videoUrl + "#t=0.1"}
                        type={videoType}
                      ></source>
                    )}
                  </video>
                </Box>

                <PlayCircleFilledIcon
                  fontSize="large"
                  sx={{
                    color: theme.palette.warning.light,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </>
            )}
          </Box>

          {/*
                    <CustomTextField defaultValue={defaultText} value={defaultText} />
                    */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              boxShadow: "1px 2px 23px 0px #4C46A621",
              borderRadius: "13px",
              height: "64px",
              width: "100%",
            }}
          >
            <Typography variant="body1" textAlign="center" m={1}>
              {defaultText}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
