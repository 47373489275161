import { Question } from "../types/campaign.types";
import EntityService from "./entity.service";

export default class QuestionService extends EntityService<Question> {
  baseUrl = "questions";

  async getForCampaign(idCampaign: number) {
    return await this.find({
      path: `campaigns/${idCampaign}/questions`,
    });
  }

  async reorderQuestionForCampaign(idCampaign: number, items: Array<any>) {
    return await this.apiService.put({
      path: `campaigns/${idCampaign}/order_questions`,
      body: items,
    });
  }

  mapToEntity(data: any): Question {
    return data as Question;
  }
}
