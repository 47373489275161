import { Box, useTheme } from "@mui/system";
import React from "react";
import VideoPlayer from "../VideoPlayer";
import ClosePageButton from "../ClosePageButton";
import { Button, Card, Stack, Typography, useMediaQuery } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Question } from "../../types/campaign.types";
import CustomTextField from "../CustomTextField";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TextFieldsIcon from "@mui/icons-material/TextFields";

interface PreviewProps {
  onClose: () => void;
  onRestartRecord: () => void;
  question: Question;
  videoBlob?: Blob;
  poster?: string;
  onValidate: (newQuestionData: Question) => void;
  onDelete?: () => void;
  noVideo?: boolean;
}

export default function Preview({
  noVideo,
  onClose,
  onRestartRecord,
  question,
  videoBlob,
  onValidate,
  onDelete,
  poster,
}: PreviewProps) {
  const { t } = useTranslation();

  const { handleSubmit, control } = useForm();

  const theme = useTheme();
  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));

  function handleValidate(data) {
    onValidate({
      videoBlob: videoBlob,
      text: data.text || "",
    });
  }

  function handleDelete() {
    if (onDelete) {
      onDelete();
    }
  }

  function handleRestart() {
    onRestartRecord();
  }

  return (
    <>
      <ClosePageButton onClick={onClose} />
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          width: "fit-content",
          height: "100vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <form onSubmit={handleSubmit(handleValidate)}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            sx={{ borderRadius: "32px", backgroundColor: "#FFFFFF" }}
            p={5}
          >
            {!noVideo && (videoBlob || question?.videoUrl?.length) ? (
              <VideoPlayer
                blob={videoBlob}
                iconFontSize={100}
                borderRadius={32}
                poster={poster}
                size={matchesSmScreen ? "auto" : 409}
                type={question?.videoType}
                src={question?.videoUrl}
              />
            ) : (
              <Card
                sx={{
                  width: matchesSmScreen ? "auto" : 300,
                  height: matchesSmScreen ? "auto" : 300,
                  borderRadius: "32px",
                  boxShadow: 1,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TextFieldsIcon fontSize="large" color="primary" />
              </Card>
            )}
            <Stack direction="column" spacing={2}>
              <Typography variant="h5">
                {t("pages.questionBuilder.question")}
              </Typography>
              <Controller
                control={control}
                defaultValue={question?.text}
                name="text"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <CustomTextField
                    value={value}
                    onChange={onChange}
                    multiline
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="center"
            mt={2}
            width="100%"
          >
            {videoBlob && (
              <Button
                onClick={handleRestart}
                color="primary"
                variant="contained"
                sx={{
                  borderRadius: "100px",
                  height: "50px",
                  marginBottom: "4px",
                  backgroundColor: theme.palette.primary.light,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                  },
                  color: theme.palette.primary.main,
                  flexGrow: 1,
                }}
              >
                <AutorenewIcon sx={{ marginRight: 1 }} />{" "}
                {t("common.toRestart")}
              </Button>
            )}
            {question?.id && (
              <Button
                onClick={handleDelete}
                color="primary"
                variant="contained"
                sx={{
                  borderRadius: "100px",
                  height: "50px",
                  marginBottom: "4px",
                  backgroundColor: theme.palette.error.light,
                  "&:hover": {
                    backgroundColor: theme.palette.error.main,
                    color: "white",
                  },
                  color: "black",
                  flexGrow: 1,
                }}
              >
                <DeleteOutlineOutlinedIcon
                  sx={{ color: "black", marginRight: 1 }}
                />
                {t("common.toDelete")}
              </Button>
            )}
            <Button
              type="submit"
              color="success"
              variant="contained"
              sx={{
                borderRadius: "100px",
                height: "50px",
                marginBottom: "4px",
                backgroundColor: theme.palette.success.light,
                "&:hover": {
                  backgroundColor: theme.palette.success.main,
                },
                color: theme.palette.success.dark,
                flexGrow: 1,
              }}
            >
              <CheckIcon sx={{ marginRight: 1 }} />{" "}
              {t("pages.questionBuilder.validate")}
            </Button>
          </Stack>
        </form>
      </Box>
    </>
  );
}
