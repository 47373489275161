import { Chip, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

export interface Tab {
    label: string;
    component: React.FC | any;
}

interface CustomTabsProps {
    tabs: Array<Tab>;
}


export default function CustomTabs({ tabs }: CustomTabsProps) {
    const [currentTabActive, setCurrentTabActive] = React.useState<Tab>(tabs[0]);

    React.useEffect(() => {
        setCurrentTabActive(tabs[0]);
    }, [tabs]);

    return (
        <Stack spacing={3} direction="column">
            <Stack direction="row" spacing={2}>
                {tabs.map(tab => (
                    <Chip
                        key={tab.label}
                        label={<Typography variant="body1">
                            {tab.label}
                        </Typography>}
                        onClick={() => {
                            setCurrentTabActive(tab);
                        }}
                        sx={{
                            fontSize: '16px',
                            fontWeight: 500,
                            color: currentTabActive?.label === tab.label ? '#BB8653' : '#9296A1',
                            backgroundColor: currentTabActive?.label === tab.label ? '#FFEDDC' : 'transparent',
                            '&:hover': {
                                backgroundColor: '#F5F5F5'
                            }
                        }}
                    />
                ))}
            </Stack>
            <Box>
                {currentTabActive?.component || <></>}
            </Box>
        </Stack>
    );
}