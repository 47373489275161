import ApiService from "./api.service";
import { GetOptions } from "./types";

export default abstract class EntityService<T> {
    apiService: ApiService;
    protected baseUrl: string;

    constructor(apiService: ApiService) {
        this.apiService = apiService;

        this.getById = this.getById.bind(this);
        this.find = this.find.bind(this);
    }

    abstract mapToEntity(data: any): T;

    async find(options?: GetOptions): Promise<T[]> {
        return (await this.apiService.get<T>({
            path: options?.path || this.baseUrl,
        }) as Array<T>).map(data => this.mapToEntity(data));
    }

    async getById(id: number | string): Promise<T> {
        return this.mapToEntity(await this.apiService.get<T>({
            path: `${this.baseUrl}/${id}`,
        }) as T);
    }

    async create(body: any): Promise<T> {
        return this.apiService.post({
            path: this.baseUrl,
            body
        });
    }

    async update(id: number, body: any): Promise<T> {
        return this.apiService.put({
            path: `${this.baseUrl}/${id}`,
            body
        });
    }

    async delete(id: number): Promise<any> {
        return this.apiService.delete({
            path: `${this.baseUrl}/${id}`,
        });
    }
}