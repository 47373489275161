import React from "react";
import Router from "./router";
import "./plugins/i18next.plugin";
import Page from "./components/Page";

function App() {
  return (
    <Page>
      <Router />
    </Page>
  );
}

export default App;
