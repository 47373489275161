import axios from "axios";
import { API_URL } from "../config";
import AuthService from "../services/auth.service";

const instance = axios.create({
  baseURL: API_URL,
});

instance.interceptors.request.use(
  (config) => {
    const token = AuthService.getToken();
    if (token?.length) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    if (response.status === 403) {
      AuthService.disconnect();
      return Promise.reject("403 forbidden");
    }
    return response;
  },
  (error) => {
    if (error.response.status === 403) {
      AuthService.disconnect();
    }
    return Promise.reject(error);
  }
);

export const fetcher = (url) => instance.get(url).then((res) => res.data);

export default instance;
