import React from "react";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import CustomTextField from "../CustomTextField";
import { useTranslation } from "react-i18next";
import { getContrastYIQ } from "../../utils";
import ControlledDatePicker from "../form-components/ControlledDatePicker";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ResponseContract from "./RepsonseContract";
import { Campaign } from "../../types/campaign.types";

interface ResponseHomePageProps {
  defaultProps?: {
    homePageTitle?: string;
    homePageSubTitle?: string;
    homePagePrimaryColor?: string;
    firstnameNeeded?: boolean;
    nameNeeded?: boolean;
    emailNeeded?: boolean;
    purchaseDateNeeded?: boolean;
  };
  onStart?: (data) => void;
  campaign?: Campaign;
}

export default function ResponseHomePage({
  defaultProps,
  onStart,
  campaign,
}: ResponseHomePageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { control, handleSubmit, getValues } = useForm();

  const title = defaultProps?.homePageTitle || "";
  const subtitle = defaultProps?.homePageSubTitle || "";
  const color = defaultProps?.homePagePrimaryColor || "#504AB7";

  const [responseContractOpen, setResponseContractOpen] =
    React.useState<boolean>(false);
  const [compatibilityOpen, setCompatibilityOpen] =
    React.useState<boolean>(false);

  function handleStart(data) {
    if (onStart) {
      onStart(data);
    }
  }
  const testForCompatibility = async () => {
    try {
      const r = new MediaRecorder(new MediaStream());
      if (!r) {
        handleOpen();
      }
    } catch (err) {
      handleOpen();
      console.error(err);
    }
  };
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 200,
    maxWidth: 600,
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    textAlign: "center" as "center",
    alignItems: "center",
    borderRadius: "25px",
    bgcolor: "background.paper",
    outline: "none",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const handleOpen = () => setCompatibilityOpen(true);
  const handleClose = () => setCompatibilityOpen(false);
  React.useEffect(() => {
    testForCompatibility();
  }, []);
  return (
    <form onSubmit={handleSubmit(handleStart)}>
      <Modal
        open={compatibilityOpen}
        onClose={() => null}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h6"
            sx={{ padding: 2, border: "3px solid red", borderRadius: "15px" }}
          >
            {t("pages.response.warning")}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 3 }}>
            {t("pages.response.incompatibility")}
          </Typography>
          <Typography sx={{ mt: 3 }}>
            {t("pages.response.incompatibilityTip")}
          </Typography>
          <Typography sx={{ mt: 3 }}>
            {t("pages.response.possibleErrors")}
          </Typography>
          <Button
            onClick={handleClose}
            variant={"contained"}
            style={{ marginTop: 20 }}
          >
            Je comprends
          </Button>
        </Box>
      </Modal>

      <ResponseContract
        getValues={getValues}
        color={color}
        onClose={() => {
          setResponseContractOpen(false);
        }}
        isOpen={responseContractOpen}
        campaign={campaign}
      />

      <Stack
        direction="column"
        spacing={matchesSmScreen ? 1.5 : 5}
        justifyContent="center"
        alignItems="center"
        pl={2}
        pr={2}
        sx={{ marginTop: matchesSmScreen ? 0 : 8 }}
      >
        <Stack direction="column" spacing={1} alignItems="center">
          {title && (
            <Typography variant="h4" color={color} textAlign="center">
              {title}
            </Typography>
          )}
          {subtitle && (
            <Typography
              variant="h5"
              fontWeight={500}
              fontSize="16px"
              color="#18181A"
              textAlign="center"
            >
              {subtitle}
            </Typography>
          )}
        </Stack>
        <Stack direction="column" spacing={1}>
          {/*
                    {!!defaultProps?.firstnameNeeded && (
                        <ControlledTextField control={control} name="firstname" label={t('pages.response.yourFirstname')} />
                    )}
                    {!!defaultProps?.nameNeeded && (
                        <ControlledTextField control={control} name="lastname" label={t('pages.response.yourLastname')} />
                    )}
                    {!!defaultProps?.emailNeeded && (
                        <ControlledTextField control={control} type="email" name="email" label={t('pages.response.yourEmail')} />
                    )}
                    
                    */}

          <ControlledTextField
            control={control}
            name="firstname"
            label={t("pages.response.yourFirstname")}
          />
          <ControlledTextField
            control={control}
            name="lastname"
            label={t("pages.response.yourLastname")}
          />
          <ControlledTextField
            control={control}
            type="email"
            name="email"
            label={t("pages.response.yourEmail")}
          />
          {!!defaultProps?.purchaseDateNeeded && (
            <ControlledDatePicker
              control={control}
              name="purchase"
              label={t("pages.response.purchaseDate")}
              defaultValue={new Date()}
            />
          )}

          <ControlledCheckboxField
            control={control}
            name="acceptConditions"
            label={t("pages.response.acceptTermsOfUse")}
            required
          />

          <ControlledCheckboxField
            control={control}
            name="acceptContract"
            label={
              <>
                {t("pages.response.acceptContract")}
                <IconButton
                  onClick={() => {
                    setResponseContractOpen(true);
                  }}
                >
                  <RemoveRedEyeOutlinedIcon
                    sx={{
                      fill: color,
                    }}
                  />
                </IconButton>
              </>
            }
            required
          />

          {/* todo : bad placement... */}
          <Stack spacing={5}>
            <div />
            <Button
              type="submit"
              variant="contained"
              sx={{
                borderRadius: "100px",
                height: "50px",
                background: color,
                color: getContrastYIQ(color),
                "&:hover": {
                  background: color,
                  opacity: 0.7,
                  transition: "opacity .2s",
                },
              }}
            >
              {t("common.toStart")}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}

function ControlledCheckboxField({
  required,
  name,
  control,
  label,
  disabled,
}: {
  disabled?: boolean;
  required?: boolean;
  name: string;
  control: any;
  label: string | JSX.Element;
}) {
  return (
    <Controller
      control={control}
      defaultValue={""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Stack direction="row" spacing={0.5} alignItems="center">
          <Checkbox
            disabled={disabled}
            id={name}
            checked={!!value}
            onChange={(event, checked) => {
              onChange(checked);
            }}
            sx={{ padding: 0 }}
            required={required}
          />
          <label htmlFor={name}>
            <Typography
              variant="body2"
              fontWeight={400}
              color="#18181A"
              sx={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              {label}
            </Typography>
          </label>
        </Stack>
      )}
    />
  );
}

function ControlledTextField({
  type,
  name,
  control,
  label,
}: {
  type?: string;
  name: string;
  control: any;
  label: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Stack direction="column" spacing={0.5}>
          <Typography variant="body2" color="#18181A">
            {label}
          </Typography>
          <CustomTextField
            type={type || "text"}
            required
            value={value}
            onChange={onChange}
          />
        </Stack>
      )}
    />
  );
}
