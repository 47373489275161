import { Button, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Campaign } from "../../types/campaign.types";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import CustomTabs from "../../components/CustomTabs";
import GeneralSettings from "./GeneralSettings";
import PopUp from "./PopUp";
import Diffusion from "./Diffusion";
import useServices from "../../hooks/useServices.hook";
import useSWR from "swr";
import { useForm } from "react-hook-form";
import SendIcon from "@mui/icons-material/Send";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { confirm } from "../../plugins/confirm.plugin";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import usePage from "../../hooks/usePage";
import EmailSettings from "./EmailSettings";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FRONTEND_URL } from "../../config";
import { IDEncoder } from "../../utils";

export function ButtonSaveCampaignSettings() {
  const { t } = useTranslation();

  return (
    <Button
      type="submit"
      variant="contained"
      sx={{ borderRadius: "100px", height: "50px", marginBottom: "4px" }}
    >
      <SendIcon
        fontSize="small"
        sx={{ transform: "rotate(-20deg)", marginRight: 1 }}
      />{" "}
      {t("pages.campaignSettings.publishUpdates")}
    </Button>
  );
}

export default function CampaignSettings() {
  const { t } = useTranslation();
  let history = useHistory();
  const { id } = useParams();
  const { campaignsService } = useServices();
  const formMethods = useForm();

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const { data: campaign, mutate: mutateCampaign } = useSWR(
    ["campaigns", id],
    (key, id) => campaignsService.getById(id)
  );

  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (campaign) {
      formMethods.reset(campaign);
      if (campaign?.isFolder) {
        setTabs([
          {
            label: t("pages.campaignSettings.generalSettings"),
            component: (
              <GeneralSettings
                formMethods={formMethods}
                campaign={campaign ?? {}}
              />
            ),
          },
          {
            label: t("pages.campaignSettings.broadcasting"),
            component: (
              <Diffusion campaign={campaign} formMethods={formMethods} />
            ),
          },
        ]);
      } else {
        setTabs([
          {
            label: t("pages.campaignSettings.generalSettings"),
            component: (
              <GeneralSettings
                formMethods={formMethods}
                campaign={campaign ?? {}}
              />
            ),
          },
          {
            label: t("pages.campaignSettings.popup"),
            component: <PopUp formMethods={formMethods} campaign={campaign} />,
          },
          /*
                    {
                        label: t('pages.campaignSettings.tastyWall'),
                        component: <TastyWall campaign={campaign} />
                    },
                    */
          {
            label: t("pages.campaignSettings.broadcasting"),
            component: (
              <Diffusion campaign={campaign} formMethods={formMethods} />
            ),
          },
          {
            label: t("pages.campaignSettings.emailSettings"),
            component: (
              <EmailSettings campaign={campaign} formMethods={formMethods} />
            ),
          },
        ]);
      }
    }
  }, [campaign]);

  async function handleSave(data: Campaign) {
    try {
      const body = { ...data };
      if (!body.broadcast_conditions) {
        body.broadcast_conditions = campaign.broadcast_conditions;
      }
      await campaignsService.update(id, body);
      mutateCampaign({
        ...campaign,
        ...body,
      });
      enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
  }

  function goToCampaignPage() {
    history.push(`/campaign/${campaign.id}`);
  }

  function goToHomePage() {
    history.push("/");
  }

  async function handlePlayCampaign() {
    if (await confirm(t("common.surePlayCampaign"))) {
      try {
        await campaignsService.update(id, {
          status: "inProgress",
        });
        mutateCampaign({
          ...campaign,
          status: "inProgress",
        });
        enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
      }
    }
  }

  async function handlePauseCampaign() {
    if (await confirm(t("common.surePauseCampaign"))) {
      try {
        await campaignsService.update(id, {
          status: "ended",
        });
        mutateCampaign({
          ...campaign,
          status: "ended",
        });
        enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
      } catch (err) {
        console.error(err);
        enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
      }
    }
  }

  usePage(
    {
      isMenuActive: true,
      headerProps: {
        title: (
          <Stack
            sx={{
              flexDirection: ["column", "row", "row"],
              "& > *": {
                mr: 1,
              },
            }}
          >
            <span>
              {campaign?.name} {campaign?.emoji || ""}
            </span>

            {!campaign?.isFolder &&
              (campaign?.status !== "inProgress" ? (
                <Button
                  onClick={handlePlayCampaign}
                  startIcon={
                    <PlayCircleOutlineIcon
                      sx={{
                        fontSize: "2rem!important",
                        color: theme.palette.primary.main,
                      }}
                    />
                  }
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <Typography variant="h4" color={theme.palette.primary.main}>
                    {t("common.toStartUp")}
                  </Typography>
                </Button>
              ) : (
                <Button
                  onClick={handlePauseCampaign}
                  color="error"
                  startIcon={
                    <PauseCircleOutlineIcon
                      sx={{
                        fontSize: "2rem!important",
                        color: theme.palette.error.main,
                      }}
                    />
                  }
                  sx={{
                    width: "fit-content",
                  }}
                >
                  <Typography variant="h4" color={theme.palette.error.main}>
                    {t("common.toStop")}
                  </Typography>
                </Button>
              ))}
          </Stack>
        ),
        subtitle:
          !campaign?.isFolder && campaign?.status
            ? campaign?.status === "draft"
              ? t("pages.campaign.draftCampaign")
              : `${campaign?.responses?.length} ${t(
                  "pages.campaign.testimonials"
                )}`
            : "",
        subtitleColor: "#9296A1",
        subtitle2: !campaign?.isFolder ? (
          <>
            <Stack direction="row" alignItems="center" spacing={1}>
              <span>{t("common.respondToCampaing")}</span>
              <a
                target="_blank"
                rel="noreferrer"
                href={`${FRONTEND_URL}#/campaign/${
                  campaign?.id ? IDEncoder.encodeID(campaign?.id) : ""
                }/response`}
              >
                {FRONTEND_URL}#/campaign/
                {campaign?.id ? IDEncoder.encodeID(campaign?.id) : ""}/response
              </a>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.permissions
                    .query({ name: "clipboard-write" as PermissionName })
                    .then((result) => {
                      if (
                        result.state === "granted" ||
                        result.state === "prompt"
                      ) {
                        navigator.clipboard
                          .writeText(
                            `${FRONTEND_URL}#/campaign/${
                              campaign?.id
                                ? IDEncoder.encodeID(campaign?.id)
                                : ""
                            }/response`
                          )
                          .then(
                            function () {
                              enqueueSnackbar(
                                t(
                                  "pages.displayScriptInfoButtonModal.textCopied"
                                ),
                                { variant: "success" }
                              );
                            },
                            function () {
                              /* clipboard write failed */
                            }
                          );
                      }
                    });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Stack>
          </>
        ) : (
          <></>
        ),
      },
    },
    campaign
  );

  return (
    <>
      <Box mt={2} mb={2}>
        {campaign?.isFolder ? (
          <Stack
            direction="row"
            spacing={1}
            alignItems="end"
            sx={{ cursor: "pointer" }}
            onClick={goToHomePage}
          >
            <HomeOutlinedIcon />
            <Typography variant="body1" display="flex" alignItems="end">
              {t("pages.campaign.returnToCampaign")}
            </Typography>
          </Stack>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ cursor: "pointer" }}
            onClick={goToCampaignPage}
          >
            <ArrowBackOutlinedIcon />
            <Typography variant="body1" display="flex" alignItems="end">
              {t("pages.campaignSettings.returnToCampaign")}
            </Typography>
          </Stack>
        )}
      </Box>
      <Box mt={5}>
        <form onSubmit={formMethods.handleSubmit(handleSave)}>
          <CustomTabs tabs={tabs} />
        </form>
      </Box>
    </>
  );
}
