import { atom } from "recoil";
import { PageProps } from "../components/Page";



export const pageState = atom({
  key: 'pageState',
  default: {
      isMenuActive: true,
  } as PageProps,
});


