import {
  Dialog,
  Button,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import { confirmable } from "react-confirm";
import { useTranslation } from "react-i18next";
import { theme } from "../plugins/miu.plugin";

function ConfirmDialog({ show, proceed, confirmation, options }) {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <Dialog onClose={() => proceed(false)} open={show}>
        <DialogTitle>{t("common.confirmation")}</DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: confirmation }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={() => proceed(false)}>
            {t("common.toCancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => proceed(true)}
            sx={{
              borderRadius: "100px",
              height: "50px",
            }}
          >
            {t("common.ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(ConfirmDialog);
