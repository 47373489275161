import { Stack, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { useTranslation } from 'react-i18next';


interface ReturnPageButtonProps {
    onClick?: () => void;
}

export default function ReturnPageButton({ onClick }: ReturnPageButtonProps) {
    const { t } = useTranslation();

    return (
        <IconButton sx={{position: 'absolute', top: '8px', left: '8px'}} onClick={onClick}>
            <Stack direction="row" spacing={.5} alignItems="center">
                <ArrowBackOutlinedIcon  sx={{fontSize: '2.1rem'}}  /> <Typography variant="body1" >{t('common.return')}</Typography>
            </Stack>
        </IconButton>
    );
}