import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { authState } from "../stores/auth.store";
import { useRecoilState } from "recoil";
import { Controller, useForm } from "react-hook-form";
import useServices from "../hooks/useServices.hook";
import CustomTextField from "../components/CustomTextField";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useHistory } from "react-router-dom";
import usePage from "../hooks/usePage";
import CompanyDataForm from "../components/CompanyDataForm";
import { useSnackbar } from "notistack";
import CustomTabs from "../components/CustomTabs";
import axios from "../plugins/axios.plugin";

export default function Account() {
  const { authService } = useServices();
  const { handleSubmit, control } = useForm();
  const [auth, setAuth] = useRecoilState(authState);
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const lifecycle = async () => {
    const authData = await authService.getAuth();
    setAuth(authData);
  };
  useEffect(() => {
    lifecycle();
  }, []);
  const Offers = [
    { name: "Standard", lookup_key: "price_1LCjAeHYIjj91O3ZyBybpXQU" },
    { name: "Premium", lookup_key: "price_1LCjBgHYIjj91O3Z5Q8U7Uxl" },
  ];
  usePage({
    isMenuActive: true,
    headerProps: {
      title: `${auth.firstname || ""} ${auth.lastname || ""}`,
      subtitle: t(`pages.account.accountInformations`),
    },
  });
  async function goToCustomerPortal() {
    const response: { data: { url: string } } = await axios.get(
      "/create-portal-session"
    );
    window.location.href = response?.data?.url;
  }
  async function goToCheckout(price) {
    const response: { data: { url: string } } = await axios.post(
      "/create-checkout-session",
      { price }
    );
    window.location.href = response?.data?.url;
  }
  async function onSubmit(data: any) {
    try {
      const user = await authService.updateAccount(data);
      enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
      setAuth(user);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
  }

  function goToHomePage() {
    history.push("/");
  }
  function createButtons(
    products: Array<{ name: string; lookup_key: string }>
  ) {
    return products.map((product) => {
      return (
        <Button
          component="button"
          onClick={() => goToCheckout(product.lookup_key)}
          type="button"
          color="inherit"
          variant="contained"
          sx={{
            margin: "5px",
            borderRadius: "100px",
            height: "50px",
          }}
        >
          {t("pages.account.product") + product.name}
        </Button>
      );
    });
  }

  return (
    <>
      <Box mt={3} mb={3}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="end"
          sx={{ cursor: "pointer" }}
          onClick={goToHomePage}
        >
          <HomeOutlinedIcon />
          <Typography variant="body1" display="flex" alignItems="end">
            {t("pages.account.goToHomePage")}
          </Typography>
        </Stack>
      </Box>

      <CustomTabs
        tabs={[
          {
            label: t("pages.account.account"),
            component: (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2} direction="column">
                  <Stack direction="row" spacing={1}>
                    <ControlledCustomTextField
                      control={control}
                      label="firstname"
                      name="firstname"
                      defaultValue={auth?.firstname || ""}
                    />
                    <ControlledCustomTextField
                      control={control}
                      label="lastname"
                      name="lastname"
                      defaultValue={auth?.lastname || ""}
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="flex-end"
                    flex={1}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      variant="contained"
                      sx={{
                        borderRadius: "100px",
                        height: "50px",
                      }}
                    >
                      {t("common.toSave")}
                    </Button>
                  </Stack>
                </Stack>
              </form>
            ),
          },
          {
            label: t("pages.account.company"),
            component: <CompanyDataForm />,
          },
        ]}
      />
      {auth.subscription_status ? (
        <form onSubmit={() => goToCustomerPortal()}>
          <Button
            type="submit"
            color="inherit"
            variant="contained"
            sx={{
              borderRadius: "100px",
              height: "50px",
            }}
          >
            {t("pages.account.manageSubscription")}
          </Button>
        </form>
      ) : (
        <>{createButtons(Offers)}</>
      )}
    </>
  );
}

function ControlledCustomTextField({
  disabled,
  label,
  name,
  control,
  defaultValue,
}: {
  disabled?: boolean;
  label: string;
  name: string;
  control: any;
  defaultValue?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Stack direction="column" spacing={1} flex={1}>
          <Typography variant="body2">{t(`pages.account.${label}`)}</Typography>
          <CustomTextField
            disabled={disabled}
            value={value}
            onChange={onChange}
          />
        </Stack>
      )}
    />
  );
}
