import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useSnackbar } from "notistack";
import VideoPlayer from "../../components/VideoPlayer";
import { useTranslation } from "react-i18next";
import { Question } from "../../types/campaign.types";
import VideoRecord from "./VideoRecord";
import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";
import useServices from "../../hooks/useServices.hook";

interface QuestionResponseBuilderProps {
  question: Question;
  onResponseBlob: () => void;
  onFailSaveBlob: (questionId: number) => void;
  onBlobSaved?: (e: any) => void;
  onBlobSaveStart?: (e: any) => void;
  userData?: any;
  setData?: any;
}

export default function QuestionResponseBuilder({
  question,
  onResponseBlob,
  onFailSaveBlob,
  onBlobSaved,
  onBlobSaveStart,
  userData,
  setData,
}: QuestionResponseBuilderProps) {
  const theme = useTheme();
  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [blob, setBlob] = useState<Blob>(null);
  const [poster, setPoster] = useState<string>(null);

  const [goResponse, setGoResponse] = useState<boolean>(false);
  const { campaignsService } = useServices();
  const [loading, setLoading] = useState<boolean>(false);

  const saveBlob = async (blob: Blob, question: Question, data) => {
    onBlobSaveStart(question);
    const handleCloseTab = function (e: any) {
      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", handleCloseTab);
    let formData = new FormData();
    formData.append(
      "video",
      new File([blob], `${question.id}.${blob.type.split("/")[1]}`, {
        type: blob.type,
      })
    );
    if (data["firstname"]) {
      formData.append("firstname", data["firstname"]);
    }
    if (data["lastname"]) {
      formData.append("lastname", data["lastname"]);
    }
    if (data["email"]) {
      formData.append("email", data["email"]);
    }
    if (data["purchase"]) {
      formData.append("purchase", data["purchase"].toISOString());
    }
    if (data["stars"]) {
      formData.append("stars", data["stars"]);
    }
    if (data["stars"]) {
      formData.append("stars", data["stars"]);
    }
    formData.append("questionOrder", (question.order || 0).toString());

    formData.append("questionId", question.id.toString());
    if (data["responseId"]) {
      formData.append("responseId", data["responseId"]);
    }

    try {
      const success = (await campaignsService.createPart(
        question.campaignId,
        formData
      )) as any;
      if (success.isResponse && success.id) {
        userData["responseId"] = success.id;
        setData({ ...userData, responseId: success.id });
        enqueueSnackbar(t("common.saveSucced"), {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
        onBlobSaved(question);
      } else if (!success.id) {
        onFailSaveBlob(question.id);
        enqueueSnackbar(t("common.saveFailed"), {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
      } else {
        onBlobSaved(question);
        enqueueSnackbar(t("common.saveSucced"), {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "left" },
        });
      }
    } catch (e) {
      onFailSaveBlob(question.id);
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }

    window.removeEventListener("beforeunload", handleCloseTab);
    return;
  };
  const returnToRecording = () => {
    if (!loading) {
      setBlob(null);
      setGoResponse(false);
    }
  };
  const end = async () => {
    if (!loading) {
      setLoading(true);
      saveBlob(blob, question, userData);
      onResponseBlob();
      setBlob(null);
      setGoResponse(false);
      setLoading(false);
    }
  };

  if (goResponse) {
    if (!blob) {
      return (
        <VideoRecord
          onGoPrevious={() => {
            setGoResponse(false);
          }}
          onVideoBlobComplete={(blob, posterSRC) => {
            setBlob(blob);
            setPoster(posterSRC);
          }}
        />
      );
    } else {
      if (matchesSmScreen) {
        return (
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <VideoPlayer
              iconFontSize={100}
              borderRadius={0}
              size={"100%"}
              blob={blob}
              poster={poster}
            />
            <Box
              sx={{
                minWidth: "85%",
                position: "absolute",
                zIndex: 100,
                left: "50%",
                bottom: "16px",
                transform: "translateX(-50%)",
              }}
            >
              <Typography
                fontWeight={700}
                fontSize="24px"
                color="#FFFFFF"
                textAlign="center"
              >
                {t("pages.response.isThisResponseOkForYou")}
              </Typography>
              <Stack direction="row" spacing={1} justifyContent="center" mt={2}>
                <Button
                  onClick={() => {
                    end();
                  }}
                  variant="contained"
                  sx={{
                    borderRadius: "100px",
                    height: "50px",
                    backgroundColor: theme.palette.success.light,
                    "&:hover": {
                      backgroundColor: theme.palette.success.main,
                    },
                    color: theme.palette.success.dark,
                    flex: 1,
                  }}
                >
                  <CheckIcon sx={{ marginRight: 1 }} /> {t("common.yes")}
                </Button>
                <Button
                  onClick={() => {
                    returnToRecording();
                  }}
                  variant="contained"
                  sx={{
                    borderRadius: "100px",
                    height: "50px",
                    backgroundColor: theme.palette.primary.light,
                    "&:hover": {
                      backgroundColor: theme.palette.primary.main,
                      color: "white",
                    },
                    color: theme.palette.primary.main,
                    flex: 1,
                  }}
                >
                  <CachedIcon sx={{ marginRight: 1 }} /> {t("common.no")}
                </Button>
              </Stack>
            </Box>
          </Box>
        );
      } else {
        return (
          <Stack
            spacing={5}
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Typography
              color="#504AB7"
              fontWeight={700}
              fontSize="24px"
              variant="h1"
            >
              {t("pages.response.isThisResponseOkForYou")}
            </Typography>
            <VideoPlayer
              iconFontSize={100}
              borderRadius={32}
              size={"auto"}
              blob={blob}
              poster={poster}
            />
            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              mt={2}
              width="100%"
            >
              <Button
                onClick={() => {
                  returnToRecording();
                }}
                variant="contained"
                sx={{
                  borderRadius: "100px",
                  height: "50px",
                  backgroundColor: theme.palette.primary.light,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                    color: "white",
                  },
                  color: theme.palette.primary.main,
                  flex: 1,
                }}
              >
                <CachedIcon sx={{ marginRight: 1 }} /> {t("common.no")}
              </Button>
              <Button
                onClick={() => {
                  end();
                }}
                variant="contained"
                sx={{
                  borderRadius: "100px",
                  height: "50px",
                  backgroundColor: theme.palette.success.light,
                  "&:hover": {
                    backgroundColor: theme.palette.success.main,
                  },
                  color: theme.palette.success.dark,
                  flex: 1,
                }}
              >
                <CheckIcon sx={{ marginRight: 1 }} /> {t("common.yes")}
              </Button>
            </Stack>
          </Stack>
        );
      }
    }
  }

  if (matchesSmScreen) {
    return (
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography
          color="white"
          variant="h1"
          fontWeight={700}
          fontSize="24px"
          sx={{
            position: "absolute",
            zIndex: 100,
            left: "50%",
            top: "16px",
            transform: "translateX(-50%)",
            width: "95%",
            textAlign: "center",
          }}
        >
          {!!question?.text &&
            question?.text
              .trim()
              .split(" ")
              .map((txt) => (
                <span key={txt} style={{ background: "black" }}>
                  {txt + " "}
                </span>
              ))}
        </Typography>
        {!!question?.videoUrl?.length && (
          <VideoPlayer
            iconFontSize={100}
            borderRadius={0}
            size={"100%"}
            type={question?.videoType}
            src={question?.videoUrl + "#t=0.1"}
          />
        )}
        <Button
          onClick={() => {
            setGoResponse(true);
          }}
          variant="contained"
          sx={{
            borderRadius: "100px",
            height: "50px",
            minWidth: "85%",
            position: "absolute",
            zIndex: 100,
            left: "50%",
            bottom: "16px",
            transform: "translateX(-50%)",
          }}
        >
          {t("common.toAnswer")}
        </Button>
      </Box>
    );
  }

  return (
    <Stack
      spacing={5}
      justifyContent="center"
      alignItems="center"
      sx={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Typography color="#504AB7" variant="h1" fontWeight={700} fontSize="24px">
        {question?.text}
      </Typography>
      {!!question?.videoUrl?.length && (
        <VideoPlayer
          iconFontSize={100}
          borderRadius={32}
          size={409}
          type={question?.videoType}
          src={question?.videoUrl + "#t=0.1"}
        />
      )}
      <Button
        variant="contained"
        sx={{ borderRadius: "100px", height: "50px", width: "100%" }}
        onClick={() => {
          setGoResponse(true);
        }}
      >
        {t("common.toAnswer")}
      </Button>
    </Stack>
  );
}
