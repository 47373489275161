import React from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import AddOrUpdateBlock from "./AddOrUpdateBlock";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import AddIcon from "@mui/icons-material/Add";
import useGreyBackground from "../../hooks/useGreyBackground";
import { useHistory } from "react-router-dom";
import ClosePageButton from "../../components/ClosePageButton";
import useServices from "../../hooks/useServices.hook";
import useSWR from "swr";
import useQuestionsBlock from "../../hooks/useQuestionsBlock";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import usePage from "../../hooks/usePage";

export default function QuestionsBuilder() {
  const { t } = useTranslation();
  useGreyBackground();
  const { id } = useParams();
  let history = useHistory();
  const { questionService } = useServices();
  const { enqueueSnackbar } = useSnackbar();

  const { data: questions, mutate: mutateQuestions } = useSWR(
    ["questions", id],
    (key, idCampaign) => questionService.getForCampaign(idCampaign)
  );

  const { displayQuestionBlocks } = useQuestionsBlock({
    questions,
    onClick: (id) => handleUpdateQuestion(id),
    onReorder: async (items) => {
      try {
        const newQuestions = items.map((item, index) => ({
          ...item,
          order: index,
        }));

        await questionService.reorderQuestionForCampaign(id, newQuestions);

        enqueueSnackbar(t("common.saveSucced"), { variant: "success" });

        mutateQuestions(newQuestions, false);
      } catch (err) {
        console.error(err);
      }
    },
  });

  function handleNewQuestion() {
    history.push(`/campaign/${id}/questions/create`);
  }

  function handleUpdateQuestion(idQuestion: number) {
    history.push(`/campaign/${id}/questions/${idQuestion}`);
  }

  function handleCustomStartScreen() {
    history.push(`/campaign/${id}/custom-start-screen`);
  }

  function handleCustomEndScreen() {
    history.push(`/campaign/${id}/custom-end-screen`);
  }

  function handleClose() {
    history.goBack();
  }

  usePage({ isMenuActive: false });

  return (
    <Box display="flex" flexDirection="column" pt={15} alignItems="center">
      <ClosePageButton onClick={handleClose} />
      <Typography variant="h4" color="primary">
        {t("pages.questionBuilder.yourQuestions")}
      </Typography>
      <Stack
        mt={4}
        justifyContent="center"
        alignItems="center"
        sx={{
          width: ["95%", "80%", "80%"],
        }}
      >
        {displayQuestionBlocks(
          <AddOrUpdateBlock
            color="success"
            icon={ModeEditOutlinedIcon}
            onClick={handleCustomStartScreen}
          >
            {t("pages.questionBuilder.customizeHomeScreen")}
          </AddOrUpdateBlock>,
          <>
            <AddOrUpdateBlock
              color="primary"
              icon={AddIcon}
              onClick={handleNewQuestion}
            >
              {t("pages.questionBuilder.addQuestion")}
            </AddOrUpdateBlock>
            <AddOrUpdateBlock
              color="success"
              icon={ModeEditOutlinedIcon}
              onClick={handleCustomEndScreen}
            >
              {t("pages.questionBuilder.customizeEndScreen")}
            </AddOrUpdateBlock>
          </>
        )}
      </Stack>
    </Box>
  );
}
