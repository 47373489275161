import React from 'react';
import { Box } from "@mui/system";
import { useTheme } from '@mui/material/styles';
import { SvgIconProps, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';

interface AddOrUpdateBlockProps {
    color: 'primary' | 'success';
    icon: React.FC<SvgIconProps>;
    children: any;
    onClick?: () => void;

}

export default function AddOrUpdateBlock({ color, icon, children, onClick }: AddOrUpdateBlockProps) {
    const theme = useTheme();
    const Icon = icon;

    const mainColor = color === "primary" ? theme.palette[color].main : theme.palette[color].dark;
    const ligthColor = theme.palette[color].light;

    return (

        <Box
            className="scale-up-button"
            onClick={onClick}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                cursor: 'pointer'
            }}
        >
            <Avatar sx={{ backgroundColor: mainColor, width: 33, height: 33 }}>
                <Icon sx={{ color: 'white' }} />
            </Avatar>

            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="160px"
                height="235px"

                mt={2}
                sx={{
                    backgroundColor: ligthColor,
                    color: mainColor,
                    borderRadius: '24px'
                }}
            >
                <Box width="128px">
                    <Typography variant="body1" color={color} textAlign="center">
                        {children}
                    </Typography>
                </Box>
            </Box>
        </Box>

    );
}