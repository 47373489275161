import { createTheme } from "@mui/material";

export const theme = createTheme({
    palette: {
        primary: {
            dark: '#2B2B68',
            main: '#504AB7',
            light: '#E3E9FC'
        },
        secondary: {
            dark: '#ED2E7E',
            main: '#ED2E7E',
            light: '#FFF0F6'
        },
        success: {
            dark: '#2B683C',
            main: '#B6ECB9',
            light: '#EEFEEF' 
        },
        warning: {
            dark: '#BB8653',
            main: '#EAC7A7',
            light: '#FFEDDC' 
           
        }
    },
    typography: {
        body2: {
            fontWeight: 700
        },
        h5: {
            fontWeight: 500
        }
        /*
        
        h1: {
            fontWeight: 600,
            fontSize: '40px'
        },
        h2: {
            fontWeight: 500,
            fontSize: '32px'
        },
        h3: {
            fontWeight: 600,
            fontSize: '22px'
        },
        h4: {
            fontWeight: 600,
            fontSize: '16px'
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: '16px'
        },
        subtitle2: {
            fontWeight: 700,
            fontSize: '20px'
        },
        body2 : {
            fontWeight: 400,
            fontSize: '18px'
        },
        body1: {
            fontWeight: 400,
            fontSize: '16px'
        },
        button: {
            fontWeight: 600,
            fontSize: '16px'
        }
        */

    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    
                    
                    '&  .MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    },
                    
                    
                    '& > .MuiOutlinedInput-root' :{
                        border: '1px solid #E0E0E0'
                    },
                    
                    
                }
            }
        }
    }
});