import React from "react";
import { Box } from "@mui/system";
import { Typography, Autocomplete, Button, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import useGreyBackground from "../hooks/useGreyBackground";
import ClosePageButton from "../components/ClosePageButton";
import CustomTextField from "../components/CustomTextField";
import useServices from "../hooks/useServices.hook";
import { useForm, Controller } from "react-hook-form";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import usePage from "../hooks/usePage";
import ControlledEmojiPicker from "../components/form-components/ControlledEmojiPicker";

// todo : voir avec le client si le site ne doit pas être préséléctionné (et le champ eventuellement pas la du coup).
// todo : revoir design champ "folder"
export default function CreateFolder(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { campaignsService, sitesService } = useServices();

  usePage({ isMenuActive: false });
  const [siteIdSelected, setSiteIdSelected] = React.useState<number>(null);

  const { data: sites } = useSWR("sites", () => sitesService.find());

  const { data: folders } = useSWR([siteIdSelected, "folders"], async (id) => {
    if (!id) {
      return undefined;
    }
    let campaigns = await campaignsService.getFromSiteId(id);
    return campaigns.filter((c) => c.isFolder);
  });

  const { handleSubmit, control } = useForm();

  let history = useHistory();
  useGreyBackground();

  React.useEffect(() => {
    if (sites?.length && siteIdSelected == null) {
      setSiteIdSelected(sites[0]?.id);
    }
  }, [sites]);

  function handleClosePage(): void {
    history.goBack();
  }

  async function handleCreateFolder(data) {
    try {
      if (data?.site?.id) {
        await campaignsService.create({
          name: data.name,
          siteId: data.site.id,
          isFolder: true,
          parentId: data.folder?.id || null,
          emoji: data.emoji || "",
        });

        enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
        history.push(`/`);
      }
    } catch (err) {
      console.error(err);

      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100vw", height: "100vh" }}
    >
      <ClosePageButton onClick={handleClosePage} />

      <Typography variant="h5" textAlign="center">
        {t("pages.createFolder.createANewFolder")}
      </Typography>
      <Box
        mt={2}
        sx={{
          width: ["initial", "500px", "500px"],
        }}
      >
        <form onSubmit={handleSubmit(handleCreateFolder)}>
          <Stack spacing={2}>
            <Controller
              control={control}
              name="name"
              defaultValue={""}
              render={({ field: { onChange, value } }) => (
                <CustomTextField
                  value={value}
                  onChange={onChange}
                  placeholder={t("pages.createFolder.folderName")}
                />
              )}
            />

            {/*
                
                        <Autocomplete fullWidth disablePortal options={[]} renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Nom du dossier"
                                sx={{ background: '#FFFFFF', marginBottom: '16px' }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <FolderIcon fontSize="small" style={{ color: theme.palette.primary.main }} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )} />
                        */}

            <Controller
              control={control}
              name="site"
              defaultValue={sites?.length ? sites[0] : undefined}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  fullWidth
                  disablePortal
                  getOptionLabel={(opt) => `${opt.title}`}
                  options={sites ?? []}
                  value={value}
                  onChange={(event, value) => {
                    onChange(value);
                    setSiteIdSelected(value?.id);
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder={t("pages.createFolder.site")}
                    />
                  )}
                />
              )}
            />

            <Controller
              control={control}
              name="folder"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Autocomplete
                  fullWidth
                  disablePortal
                  getOptionLabel={(opt) => `${opt.name}`}
                  options={folders ?? []}
                  value={value}
                  onChange={(event, value) => {
                    onChange(value);
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder={t("pages.createFolder.folder")}
                    />
                  )}
                />
              )}
            />

            <ControlledEmojiPicker
              control={control}
              label="emoji"
              name="emoji"
            />
          </Stack>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            sx={{ borderRadius: "100px", height: "50px", marginTop: 4 }}
          >
            {t("pages.createFolder.createMyFolder")}
          </Button>
        </form>
      </Box>
    </Box>
  );
}
