import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';


interface ClosePageButtonProps {
    onClick?: () => void;
}

export default function ClosePageButton({ onClick }: ClosePageButtonProps) {
    return (
        <IconButton aria-label="close" sx={{ position: 'absolute', top: '8px', right: '8px' }} onClick={onClick}>
            <CloseIcon sx={{fontSize: '2.3rem'}} />
        </IconButton>
    );
}