import { Notification } from "../types/notification.types";
import EntityService from "./entity.service";

export default class NotificationService extends EntityService<Notification> {
    
    baseUrl = 'notifications';

    
    mapToEntity(data: any): Notification {
        return data as Notification;
    }
}
