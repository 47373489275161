import { Modal, Box, Stack, Typography, Button } from "@mui/material";
import { t } from "i18next";
import React from "react";
import { Campaign } from "../../types/campaign.types";
import { getContrastYIQ } from "../../utils";
import ClosePageButton from "../ClosePageButton";

interface ResponseContractProps {
  isOpen: boolean;
  onClose: () => void;
  color: string;
  getValues: () => any;
  campaign?: Campaign;
}

export default function ResponseContract({
  getValues,
  isOpen,
  onClose,
  color,
  campaign,
}: ResponseContractProps) {
  const [contractData, setContractData] = React.useState<any>({});

  React.useEffect(() => {
    const values = getValues();
    setContractData({
      firstname: values?.firstname || "",
      lastname: values?.lastname || "",
      email: values?.email || "",
      //address: '15 rue du village',
      growproof: "GrowProof",
      company: {
        entity: campaign?.site?.admin?.companyEntity || "",
        siret: campaign?.site?.admin?.companySiret || "",
        phone: campaign?.site?.admin?.companyPhone || "",
        email: campaign?.site?.admin?.companyEmail || "",
        address: campaign?.site?.admin?.companyAddress || "",
      },
    });
  }, [isOpen, campaign]);

  function handleDownload() {
    window.resizeBy(1, 1);
    window.print();
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: "background.paper",
          border: "none",
          p: 4,
          overflowY: "auto",
        }}
      >
        <ClosePageButton onClick={onClose} />

        <Stack direction="column" spacing={3} mt={6}>
          <Stack direction="column" spacing={1}>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <Typography variant="body2">Entité :</Typography>
              <Typography variant="body1">
                {contractData?.company?.entity}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <Typography variant="body2">Siret :</Typography>
              <Typography variant="body1">
                {contractData?.company?.siret}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <Typography variant="body2">Adresse :</Typography>
              <Typography variant="body1">
                {contractData?.company?.address}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <Typography variant="body2">Téléphone :</Typography>
              <Typography variant="body1">
                {contractData?.company?.phone}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={0.5}
            >
              <Typography variant="body2">Email :</Typography>
              <Typography variant="body1">
                {contractData?.company?.email}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography variant="body2">
                Ci-après la « Marque Partenaire »
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="h4" textAlign="center">
            AUTORISATION DE DROIT A L’IMAGE
          </Typography>
          <Stack spacing={2}>
            <Typography variant="body1" component="article">
              Je soussigné(e){" "}
              <Typography component="span" variant="body2">
                {contractData?.firstname} {contractData?.lastname}{" "}
              </Typography>
            </Typography>

            <Typography variant="body1" component="article">
              <Typography component="span" variant="body2">
                Donne
              </Typography>{" "}
              à compter de ce jour mon accord à la Marque Partenaire pour être{" "}
              <Typography component="span" variant="body2">
                photographié(e)/filmé(e)/enregistré(e){" "}
              </Typography>
              par son mandataire{" "}
              <Typography component="span" variant="body2">
                {contractData?.growproof}
              </Typography>{" "}
              qui agit, en son nom et sur ses instructions, pour recueillir les
              avis clients suite à mon récent achat
            </Typography>

            <Typography variant="body1" component="article">
              <Typography component="span" variant="body2">
                Confirme
              </Typography>{" "}
              mon accord à la Marque Partenaire pour exploiter mon image fixe ou
              animée, ma voix, [mon prénom/nom : à confirmer] à titre commercial
              et/ou publicitaire tel que notamment sur:
              <ul
                style={{
                  margin: 0,
                }}
              >
                <li>
                  les réseaux sociaux liés à la Marque Partenaire (Facebook,
                  LinkedIn, Twitter, Instagram, Tik Tok etc…)
                </li>
                <li>
                  son site Internet ou celui de ses partenaires, sponsors,
                  mécènes ou autres
                </li>
                <li>tous documents commerciaux et/ou promotionnels</li>
                <li>toute publicité et/ou objet publicitaire.</li>
                <li>
                  tous supports média et hors média connus ou inconnus à ce
                  jour.
                </li>
              </ul>
            </Typography>
            <Typography variant="body1" component="article">
              <Typography component="span" variant="body2">
                Garantis
              </Typography>{" "}
              que je suis majeur(e) et que je ne suis lié(e) à aucune autre
              marque ou entreprise par un contrat exclusif relatif à
              l’utilisation de mon image ou de mon nom.
            </Typography>
            <Typography variant="body1" component="article">
              <Typography variant="body1">
                Le périmètre de cette autorisation convenu avec la Marque
                Partenaire est le suivant:
              </Typography>
              <Typography>Durée : 6 mois renouvelables tacitements</Typography>
            </Typography>
            <Typography variant="body1" component="article">
              Conformément à la réglementation en vigueur, je dispose d'un droit
              d'accès, droit de rectification, droit au retrait du consentement,
              droit de limitation de traitement et de portabilité concernant mes
              données personnelles, qui peuvent être exercés à tout moment en
              adressant un email à la Marque Partenaire à l’adresse{" "}
              {contractData?.company?.email}.
            </Typography>
          </Stack>
          <Typography variant="body1">
            Pour plus d’informations sur notre politique de protection des
            données personnelles de la Marque Partenaire, consultez [indiquer le
            lien vers la politique de protection des données applicable de la
            Marque Partenaire]
          </Typography>
          <Typography variant="body1">
            Fait le
            <Typography component="span" variant="body2">
              {" "}
              {new Date().toLocaleDateString()}
            </Typography>
          </Typography>
        </Stack>

        <Stack justifyContent="flex-end" direction="row" spacing={1}>
          <Button
            onClick={handleDownload}
            type="button"
            variant="contained"
            sx={{
              borderRadius: "100px",
              height: "50px",
              background: color,
              color: getContrastYIQ(color),
              "&:hover": {
                background: color,
                opacity: 0.7,
                transition: "opacity .2s",
              },
            }}
          >
            {t("common.download")}
          </Button>
          <Button
            onClick={onClose}
            type="button"
            variant="contained"
            sx={{
              borderRadius: "100px",
              height: "50px",
              background: color,
              color: getContrastYIQ(color),
              "&:hover": {
                background: color,
                opacity: 0.7,
                transition: "opacity .2s",
              },
            }}
          >
            {t("common.validate")}
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
