import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CreateCampaign from "./pages/CreateCampaign";
import CreateVideoQuestion from "./pages/CreateVideoQuestion";
import CustomHomePage from "./pages/CustomHomePage";
import CustomThankYouPage from "./pages/CustomThankYouPage";
import Home from "./pages/Home";
import QuestionsBuilder from "./pages/QuestionsBuilder";
import UpdateVideoQuestion from "./pages/UpdateVideoQuestion";
import Campaign from "./pages/Campaign";
import CampaignSettings from "./pages/CampaignSettings";
import Login from "./pages/Login";
import ResponsePage from "./pages/Response";
import Checkout from "./pages/Checkout";
import CreateFolder from "./pages/CreateFolder";
import Account from "./pages/Account";
import AuthService from "./services/auth.service";

function AuthRoute({ render, ...rest }) {
    return (
        <Route
            {...rest}
            render={AuthService.isAuth() ? render : ({ location }) =>
                <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            }
        />
    );
}

export default function Router(props) {
    return (
        <Switch>
            <AuthRoute path="/" exact render={props => <Home {...props} />} />
            <Route path="/login" exact render={props => <Login {...props} />} />

            <AuthRoute path="/account" exact render={props => <Account {...props} />} />

            <AuthRoute path="/create-campaign" exact render={props => <CreateCampaign {...props} />} />
            <AuthRoute path="/create-folder" exact render={props => <CreateFolder {...props} />} />

            <AuthRoute path="/campaign/:id" exact render={props => <Campaign {...props} />} />
            <AuthRoute path="/campaign/:id/settings" exact render={props => <CampaignSettings {...props} />} />
            <AuthRoute path="/campaign/:id/questions" exact render={props => <QuestionsBuilder {...props} />} />
            <AuthRoute path="/campaign/:idCampaign/questions/create" exact render={props => <CreateVideoQuestion {...props} />} />
            <AuthRoute path="/campaign/:idCampaign/questions/:idQuestion" exact render={props => <UpdateVideoQuestion {...props} />} />
            <AuthRoute path="/campaign/:idCampaign/custom-start-screen" exact render={props => <CustomHomePage {...props} />} />
            <AuthRoute path="/campaign/:idCampaign/custom-end-screen" exact render={props => <CustomThankYouPage {...props} />} />

            <Route path="/campaign/:idCampaign/response" exact render={props => <ResponsePage {...props} />} />

            <Route path="/checkout" exact render={props => <Checkout {...props} />} />
        </Switch>
    )
};