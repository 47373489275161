import { CircularProgress } from "@mui/material";
import React from "react";
import { useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import ClosePageButton from "../components/ClosePageButton";
import QuestionStepper from "../components/QuestionStepper";
import useServices from "../hooks/useServices.hook";
import { Question } from "../types/campaign.types";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import usePage from "../hooks/usePage";
import { confirm } from "../plugins/confirm.plugin";

export default function UpdateVideoQuestion(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  const { idCampaign, idQuestion } = useParams();

  usePage({ isMenuActive: false });

  const { campaignsService, questionService } = useServices();
  const { data: campaign } = useSWR(["campaigns", idCampaign], (key, id) =>
    campaignsService.getById(id)
  );
  const { data: question, mutate: mutateQuestion } = useSWR(
    ["question", idQuestion],
    (key, id) => questionService.getById(id)
  );

  async function handleValidate({ videoBlob, text }: Question) {
    try {
      await questionService.update(idQuestion, { text });
      mutateQuestion({
        ...question,
        text,
      });
      enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
    history.goBack();
  }

  async function handleDelete() {
    try {
      if (
        await confirm(
          t("pages.updateVideoQuestion.sureWantToDeleteThisQuestion")
        )
      ) {
        await questionService.delete(idQuestion);

        enqueueSnackbar(t("common.deleteSucced"), { variant: "success" });
        mutate(
          ["questions", idCampaign],
          (questions) => questions.filter((q) => q.id !== idQuestion),
          false
        );
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.deleteFailed"), { variant: "error" });
    }
    history.goBack();
  }

  return campaign && question ? (
    <QuestionStepper
      onDelete={handleDelete}
      handleValidate={handleValidate}
      campaign={campaign}
      question={question}
    />
  ) : (
    <>
      <CircularProgress
        color="primary"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />

      <ClosePageButton
        onClick={() => {
          history.goBack();
        }}
      />
    </>
  );
}
