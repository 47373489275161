import {
  Grid,
  List,
  Typography,
  PopperPlacementType,
  Fade,
  Paper,
  Popper,
  ListItem,
  ListItemButton,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useSWR from "swr";
import useServices from "../../hooks/useServices.hook";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import { Box, useTheme } from "@mui/system";
import AloneBadge from "../../components/AloneBadge";
import { Campaign } from "../../types/campaign.types";
import AddButton from "./AddButton";
import { CampaignListItem, FolderListItem } from "./ListItems";
import { Site } from "../../types/user.types";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ButtonModalAddSite from "./ButtonModalAddSite";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { authState } from "../../stores/auth.store";
import { useRecoilState } from "recoil";
import usePage from "../../hooks/usePage";
import { confirm } from "../../plugins/confirm.plugin";

export default function Home() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { campaignsService, sitesService, notificationService } = useServices();

  const { data: sites, mutate: mutateSites } = useSWR("sites", () =>
    sitesService.find()
  );
  const { data: notifications } = useSWR("notifications", () =>
    notificationService.find()
  );

  const [selectedSite, setSelectedSite] = useState<Site>(null);

  const { data: campaigns, mutate: mutateCampaigns } = useSWR(
    [selectedSite?.id, "campaigns"],
    (id) => (!id ? undefined : campaignsService.getFromSiteId(id))
  );
  //const { data: folders, error: errorFolders } = useSWR([selectedSite?.id, 'folders'], (id) => !id ? undefined : folderService.getFromSiteId(id));

  const [auth] = useRecoilState(authState);

  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [open, setOpen] = React.useState(false);
  const [placement, setPlacement] = React.useState<PopperPlacementType>();

  usePage({
    isMenuActive: true,
    headerProps: {
      title: `${t("common.hello")} ${auth?.firstname || ""}!`,
      subtitle:
        notifications && notifications?.length ? (
          <>
            <AloneBadge sx={{ marginRight: "5px" }} size="md" color="#ED2E7E" />

            {t("pages.home.youHaveXNotification", {
              notificationsCount: notifications?.length,
            })}
          </>
        ) : (
          <>
            <AloneBadge sx={{ marginRight: "5px" }} size="md" color="#00BA88" />

            {t("pages.home.youAreUpdated")}
          </>
        ),
    },
  });

  async function deleteSite(siteToDelete: Site) {
    const id = siteToDelete.id;
    if (await confirm(t("pages.home.sureWantToDeleteSite"))) {
      try {
        await sitesService.delete(id);
        mutateSites(sites.filter((site) => site.id !== id));
        enqueueSnackbar(t("common.deleteSucced"), { variant: "info" });
      } catch (err) {
        enqueueSnackbar(t("common.deleteFailed"), { variant: "error" });
      }
    }
  }

  const handleSiteChoiceClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  useEffect(() => {
    if (sites?.length && selectedSite == null) {
      setSelectedSite(sites[0]);
    }
  }, [sites, selectedSite]);

  return (
    <>
      <AddButton />
      <Box display="flex" alignItems="center" mb={4}>
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            textOverflow: "wrap",
          }}
          onClick={handleSiteChoiceClick("bottom-start")}
        >
          <HomeIcon style={{ marginRight: "5px" }} />

          {selectedSite?.title}
        </Typography>
        <ButtonModalAddSite
          setSelectedSite={setSelectedSite}
          mutateSites={mutateSites}
          sites={sites}
        />
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={placement}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <List>
                  {sites && sites.length ? (
                    sites
                      .filter((site) => site.id !== selectedSite.id)
                      .map((site) => (
                        <ListItem
                          key={site.id}
                          disablePadding
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => deleteSite(site)}
                            >
                              <DeleteOutlineOutlinedIcon
                                sx={{ color: theme.palette.error.main }}
                              />
                            </IconButton>
                          }
                        >
                          <ListItemButton
                            onClick={() => {
                              setSelectedSite(site);
                              setOpen(false);
                            }}
                          >
                            <Typography variant="body1">
                              {site?.title}
                            </Typography>
                          </ListItemButton>
                        </ListItem>
                      ))
                  ) : (
                    <></>
                  )}
                </List>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>

      {/*
                    
                    <Box mb={4} display="flex">
                        <Box width="50%">
                            <SearchInput placeholder="Chercher une campagne sur monsite.com" sx={{ borderBottom: 'none' }} startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            } />
                        </Box>
                        <Box>

                        </Box>
                    </Box>
                    
                    */}
      <Box>
        {campaigns && (
          <List>
            <Grid container spacing={2}>
              {/*
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="noParent" direction="horizontal">

                                </Droppable>
                            </DragDropContext>
                            */}
              {campaigns
                .filter((c) => !c.parentId)
                .sort((a, b) => a.id - b.id)
                .map((campaign: Campaign) =>
                  campaign.isFolder ? (
                    <FolderListItem
                      key={campaign.id}
                      folder={campaign}
                      campaigns={campaigns}
                      mutateCampaigns={mutateCampaigns}
                    />
                  ) : (
                    <CampaignListItem
                      key={campaign.id}
                      campaign={campaign}
                      campaigns={campaigns}
                      mutateCampaigns={mutateCampaigns}
                    />
                  )
                )}
            </Grid>
          </List>
        )}
      </Box>
    </>
  );
}
