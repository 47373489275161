import React from "react";
import { useRecoilState } from "recoil";
import { PageProps } from "../components/Page";
import { pageState } from "../stores/page.store";

export default function usePage(props: PageProps, triggerChange?: any) {
  const [, setPageProps] = useRecoilState<PageProps>(pageState);

  React.useEffect(() => {
    setPageProps(props);
  }, [triggerChange]);
}
