import { AxiosResponse } from "axios";
import axios from "../plugins/axios.plugin";
import { GetOptions, PostOptions } from "./types";
import mock from "../mock.json";
import { isMockMode } from "../config";


export default class ApiService {

    isMock: boolean = isMockMode;
    
    
    async get<T>(options: GetOptions): Promise<T | T[]> {
        if (this.isMock) {
            const mockGet = mock.GET;
            if (mockGet) {
                const data = mockGet[options.path];
                if (data) {
                    console.log(`Mock for ${options.path}`, {data})
                    return data;
                } else {
                    throw new Error(`No data found for path "${options.path}"`);
                }
            } else {
                throw new Error('No mock GET'); 
            }
        }
        return axios.get(options.path).then(({data}: AxiosResponse<T>) => data);
    }

    async post<T>(options: PostOptions) {
        return axios.post(options.path, options.body).then(({data}: AxiosResponse<T>) => data);
    }

    async put<T>(options: PostOptions) {
        return axios.put(options.path, options.body).then(({data}: AxiosResponse<T>) => data);
    }

    async delete(options: GetOptions) {
        return axios.delete(options.path);
    }

    
}