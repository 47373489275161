import { ClickAwayListener, Stack, Typography } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

function getRandomEmoji() {
  return emojis[Math.floor(Math.random() * emojis.length)];
}

export interface ControlledEmojiPickerProps {
  control: any;
  name: string;
  defaultValue?: string;

  label?: string;
}

export default function ControlledEmojiPicker(
  props: ControlledEmojiPickerProps
) {
  const defaultValue = props.defaultValue || getRandomEmoji();

  const [emoji, setEmoji] = React.useState<string>(defaultValue);
  const [chooseEmojiOpen, setChooseEmojiOpen] = React.useState<boolean>(false);

  return (
    <Controller
      control={props.control}
      name={props.name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Stack spacing={1}>
          <Typography variant="body2">{props.label}</Typography>

          <Stack
            component="button"
            type="button"
            style={{ position: "relative", cursor: "pointer" }}
            onClick={() => {
              setChooseEmojiOpen(true);
            }}
          >
            <Stack
              sx={{ boxShadow: 1, backgroundColor: "background.paper" }}
              height="56px"
              width="70px"
              alignItems="center"
              justifyContent="center"
            >
              {emoji || ""}
            </Stack>

            {chooseEmojiOpen && (
              <Stack position="absolute" top="calc(100% + 1px)">
                <ClickAwayListener
                  onClickAway={() => {
                    setChooseEmojiOpen(false);
                  }}
                >
                  <Stack
                    p={2}
                    direction="row"
                    sx={{
                      boxShadow: 1,
                      opacity: 1,
                      background: "white",
                      overflowY: "scroll",
                    }}
                    zIndex={1000}
                    flexWrap="wrap"
                    width="350px"
                    height="350px"
                  >
                    <Stack
                      mb={2}
                      width="16.2%"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <button
                        type="button"
                        style={{
                          cursor: "pointer",
                          width: "26px",
                          height: "26px",
                          border: "1px solid #9296A1",
                          padding: "4px",
                          borderRadius: "50%",
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          setEmoji("");
                          onChange("");
                          setChooseEmojiOpen(false);
                        }}
                      >
                        X
                      </button>
                    </Stack>
                    {emojis.map((e) => (
                      <Stack
                        mb={2}
                        key={e}
                        width="16.2%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <button
                          type="button"
                          style={{
                            cursor: "pointer",
                            width: "fit-content",
                            height: "fit-content",
                          }}
                          onClick={(event) => {
                            event.stopPropagation();
                            setEmoji(e);
                            onChange(e);
                            setChooseEmojiOpen(false);
                          }}
                        >
                          {e}
                        </button>
                      </Stack>
                    ))}
                  </Stack>
                </ClickAwayListener>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
    />
  );
}

const emojis = [
  "😀",
  "😁",
  "😂",
  "😃",
  "😄",
  "😅",
  "😆",
  "😇",
  "😈",
  "😉",
  "😊",
  "😋",
  "😌",
  "😍",
  "😎",
  "😏",
  "😐",
  "😑",
  "😒",
  "😓",
  "😔",
  "😕",
  "😖",
  "😗",
  "😘",
  "😙",
  "😚",
  "😛",
  "😜",
  "😝",
  "😞",
  "😟",
  "😠",
  "😡",
  "😢",
  "😣",
  "😤",
  "😥",
  "😦",
  "😧",
  "😨",
  "😩",
  "😪",
  "😫",
  "😬",
  "😭",
  "😮",
  "😯",
  "😰",
  "😱",
  "😲",
  "😳",
  "😴",
  "😵",
  "😶",
  "😷",
  "😸",
  "😹",
  "😺",
  "😻",
  "😼",
  "😽",
  "😾",
  "😿",
  "🙀",
  "🙁",
  "🙂",
  "🙃",
  "🙄",
  "🙅",
  "🙆",
  "🙇",
  "🙈",
  "🙉",
  "🙊",
  "🙋",
  "🙌",
  "🙍",
  "🙎",
  "🙏",
  "🚀",
  "⌚",
  "⌛",
  "⏩",
  "⏪",
  "⏫",
  "⏬",
  "⏭",
  "⏮",
  "⏯",
  "⏰",
  "⏱",
  "⏲",
  "⏳",
  "⏸",
  "⏹",
  "⏺",
  "Ⓜ",
  "☔",
  "☕",
  "☝",
  "♈",
  "♉",
  "♊",
  "♋",
  "♌",
  "♍",
  "♎",
  "♏",
  "♐",
  "♑",
  "♒",
  "♓",
  "♟",
  "♿",
  "⚓",
  "⚡",
  "⚪",
  "⚫",
  "⚽",
  "⚾",
  "⛄",
  "⛅",
  "⛎",
  "⛏",
  "⛑",
  "⛓",
  "⛔",
  "⛩",
  "⛪",
  "⛰",
  "⛱",
  "⛲",
  "⛳",
  "⛴",
  "⛵",
  "⛷",
  "⛸",
  "⛹",
  "⛺",
  "⛽",
  "✂",
  "✅",
  "✈",
  "✉",
  "✊",
  "✋",
  "✌",
  "✍",
  "✏",
  "✒",
  "✔",
  "✖",
  "✝",
  "✡",
  "✨",
  "✳",
  "✴",
  "❄",
  "❇",
  "❌",
  "❎",
  "❓",
  "❔",
  "❕",
  "❗",
  "❣",
  "❤",
  "➕",
  "➖",
  "➗",
  "➡",
  "➰",
  "➿",
  "⤴",
  "⤵",
  "⬅",
  "⬆",
  "⬇",
  "⬛",
  "⬜",
  "⭐",
  "⭕",
  "〰",
  "〽",
  "㊗",
  "㊙",
  "🀄",
  "🃏",
  "🅰",
  "🅱",
  "🅾",
  "🅿",
  "🆎",
  "🆑",
  "🆒",
  "🆓",
  "🆔",
  "🆕",
  "🆖",
  "🆗",
  "🆘",
  "🆙",
  "🆚",
  "🈁",
  "🈂",
  "🈚",
  "🈯",
  "🈲",
  "🈳",
  "🈴",
  "🈵",
  "🈶",
  "🈷",
  "🈸",
  "🈹",
  "🈺",
  "🉐",
  "🉑",
  "🌀",
  "🌁",
  "🌂",
  "🌃",
  "🌄",
  "🌅",
  "🌆",
  "🌇",
  "🌈",
  "🌉",
  "🌊",
  "🌋",
  "🌌",
  "🌍",
  "🌎",
  "🌏",
  "🌐",
  "🌑",
  "🌒",
  "🌓",
  "🌔",
  "🌕",
  "🌖",
  "🌗",
  "🌘",
  "🌙",
  "🌚",
  "🌛",
  "🌜",
  "🌝",
  "🌞",
  "🌟",
  "🌠",
  "🌡",
  "🌤",
  "🌥",
  "🌦",
  "🌧",
  "🌨",
  "🌩",
  "🌪",
  "🌫",
  "🌬",
  "🌭",
  "🌮",
  "🌯",
  "🌰",
  "🌱",
  "🌲",
  "🌳",
  "🌴",
  "🌵",
  "🌶",
  "🌷",
  "🌸",
  "🌹",
  "🌺",
  "🌻",
  "🌼",
  "🌽",
  "🌾",
  "🌿",
  "🍀",
  "🍁",
  "🍂",
  "🍃",
  "🍄",
  "🍅",
  "🍆",
  "🍇",
  "🍈",
  "🍉",
  "🍊",
  "🍋",
  "🍌",
  "🍍",
  "🍎",
  "🍏",
  "🍐",
  "🍑",
  "🍒",
  "🍓",
  "🍔",
  "🍕",
  "🍖",
  "🍗",
  "🍘",
  "🍙",
  "🍚",
  "🍛",
  "🍜",
  "🍝",
  "🍞",
  "🍟",
  "🍠",
  "🍡",
  "🍢",
  "🍣",
  "🍤",
  "🍥",
  "🍦",
  "🍧",
  "🍨",
  "🍩",
  "🍪",
  "🍫",
  "🍬",
  "🍭",
  "🍮",
  "🍯",
  "🍰",
  "🍱",
  "🍲",
  "🍳",
  "🍴",
  "🍵",
  "🍶",
  "🍷",
  "🍸",
  "🍹",
  "🍺",
  "🍻",
  "🍼",
  "🍽",
  "🍾",
  "🍿",
  "🎀",
  "🎁",
  "🎂",
  "🎃",
  "🎄",
  "🎅",
  "🎆",
  "🎇",
  "🎈",
  "🎉",
  "🎊",
  "🎋",
  "🎌",
  "🎍",
  "🎎",
  "🎏",
  "🎐",
  "🎑",
  "🎒",
  "🎓",
  "🎖",
  "🎗",
  "🎙",
  "🎚",
  "🎛",
  "🎞",
  "🎟",
  "🎠",
  "🎡",
  "🎢",
  "🎣",
  "🎤",
  "🎥",
  "🎦",
  "🎧",
  "🎨",
  "🎩",
  "🎪",
  "🎫",
  "🎬",
  "🎭",
  "🎮",
  "🎯",
  "🎰",
  "🎱",
  "🎲",
  "🎳",
  "🎴",
  "🎵",
  "🎶",
  "🎷",
  "🎸",
  "🎹",
  "🎺",
  "🎻",
  "🎼",
  "🎽",
  "🎾",
  "🎿",
  "🏀",
  "🏁",
  "🏂",
  "🏃",
  "🏄",
  "🏅",
  "🏆",
  "🏇",
  "🏈",
  "🏉",
  "🏊",
  "🏋",
  "🏌",
  "🏍",
  "🏎",
  "🏏",
  "🏐",
  "🏑",
  "🏒",
  "🏓",
  "🏔",
  "🏕",
  "🏖",
  "🏗",
  "🏘",
  "🏙",
  "🏚",
  "🏛",
  "🏜",
  "🏝",
  "🏞",
  "🏟",
  "🏠",
  "🏡",
  "🏢",
  "🏣",
  "🏤",
  "🏥",
  "🏦",
  "🏧",
  "🏨",
  "🏩",
  "🏪",
  "🏫",
  "🏬",
  "🏭",
  "🏮",
  "🏯",
  "🏰",
  "🏳",
  "🏴",
  "🏵",
  "🏷",
  "🏸",
  "🏹",
  "🏺",
  "🏻",
  "🏼",
  "🏽",
  "🏾",
  "🏿",
  "🐀",
  "🐁",
  "🐂",
  "🐃",
  "🐄",
  "🐅",
  "🐆",
  "🐇",
  "🐈",
  "🐉",
  "🐊",
  "🐋",
  "🐌",
  "🐍",
  "🐎",
  "🐏",
  "🐐",
  "🐑",
  "🐒",
  "🐓",
  "🐔",
  "🐕",
  "🐖",
  "🐗",
  "🐘",
  "🐙",
  "🐚",
  "🐛",
  "🐜",
  "🐝",
  "🐞",
  "🐟",
  "🐠",
  "🐡",
  "🐢",
  "🐣",
  "🐤",
  "🐥",
  "🐦",
  "🐧",
  "🐨",
  "🐩",
  "🐪",
  "🐫",
  "🐬",
  "🐭",
  "🐮",
  "🐯",
  "🐰",
  "🐱",
  "🐲",
  "🐳",
  "🐴",
  "🐵",
  "🐶",
  "🐷",
  "🐸",
  "🐹",
  "🐺",
  "🐻",
  "🐼",
  "🐽",
  "🐾",
  "🐿",
  "👀",
  "👁",
  "👂",
  "👃",
  "👄",
  "👅",
  "👆",
  "👇",
  "👈",
  "👉",
  "👊",
  "👋",
  "👌",
  "👍",
  "👎",
  "👏",
  "👐",
  "👑",
  "👒",
  "👓",
  "👔",
  "👕",
  "👖",
  "👗",
  "👘",
  "👙",
  "👚",
  "👛",
  "👜",
  "👝",
  "👞",
  "👟",
  "👠",
  "👡",
  "👢",
  "👣",
  "👤",
  "👥",
  "👦",
  "👧",
  "👨",
  "👩",
  "👪",
  "👫",
  "👬",
  "👭",
  "👮",
  "👯",
  "👰",
  "👱",
  "👲",
  "👳",
  "👴",
  "👵",
  "👶",
  "👷",
  "👸",
  "👹",
  "👺",
  "👻",
  "👼",
  "👽",
  "👾",
  "👿",
  "💀",
  "💁",
  "💂",
  "💃",
  "💄",
  "💅",
  "💆",
  "💇",
  "💈",
  "💉",
  "💊",
  "💋",
  "💌",
  "💍",
  "💎",
  "💏",
  "💐",
  "💑",
  "💒",
  "💓",
  "💔",
  "💕",
  "💖",
  "💗",
  "💘",
  "💙",
  "💚",
  "💛",
  "💜",
  "💝",
  "💞",
  "💟",
  "💠",
  "💡",
  "💢",
  "💣",
  "💤",
  "💥",
  "💦",
  "💧",
  "💨",
  "💩",
  "💪",
  "💫",
  "💬",
  "💭",
  "💮",
  "💯",
  "💰",
  "💱",
  "💲",
  "💳",
  "💴",
  "💵",
  "💶",
  "💷",
  "💸",
  "💹",
  "💺",
  "💻",
  "💼",
  "💽",
  "💾",
  "💿",
  "📀",
  "📁",
  "📂",
  "📃",
  "📄",
  "📅",
  "📆",
  "📇",
  "📈",
  "📉",
  "📊",
  "📋",
  "📌",
  "📍",
  "📎",
  "📏",
  "📐",
  "📑",
  "📒",
  "📓",
  "📔",
  "📕",
  "📖",
  "📗",
  "📘",
  "📙",
  "📚",
  "📛",
  "📜",
  "📝",
  "📞",
  "📟",
  "📠",
  "📡",
  "📢",
  "📣",
  "📤",
  "📥",
  "📦",
  "📧",
  "📨",
  "📩",
  "📪",
  "📫",
  "📬",
  "📭",
  "📮",
  "📯",
  "📰",
  "📱",
  "📲",
  "📳",
  "📴",
  "📵",
  "📶",
  "📷",
  "📸",
  "📹",
  "📺",
  "📻",
  "📼",
  "📽",
  "📿",
  "🔀",
  "🔁",
  "🔂",
  "🔃",
  "🔄",
  "🔅",
  "🔆",
  "🔇",
  "🔈",
  "🔉",
  "🔊",
  "🔋",
  "🔌",
  "🔍",
  "🔎",
  "🔏",
  "🔐",
  "🔑",
  "🔒",
  "🔓",
  "🔔",
  "🔕",
  "🔖",
  "🔗",
  "🔘",
  "🔙",
  "🔚",
  "🔛",
  "🔜",
  "🔝",
  "🔞",
  "🔟",
  "🔠",
  "🔡",
  "🔢",
  "🔣",
  "🔤",
  "🔥",
  "🔦",
  "🔧",
  "🔨",
  "🔩",
  "🔪",
  "🔫",
  "🔬",
  "🔭",
  "🔮",
  "🔯",
  "🔰",
  "🔱",
  "🔲",
  "🔳",
  "🔴",
  "🔵",
  "🔶",
  "🔷",
  "🔸",
  "🔹",
  "🔺",
  "🔻",
  "🔼",
  "🔽",
  "🕉",
  "🕊",
  "🕋",
  "🕌",
  "🕍",
  "🕎",
  "🕐",
  "🕑",
  "🕒",
  "🕓",
  "🕔",
  "🕕",
  "🕖",
  "🕗",
  "🕘",
  "🕙",
  "🕚",
  "🕛",
  "🕜",
  "🕝",
  "🕞",
  "🕟",
  "🕠",
  "🕡",
  "🕢",
  "🕣",
  "🕤",
  "🕥",
  "🕦",
  "🕧",
  "🕯",
  "🕰",
  "🕳",
  "🕴",
  "🕵",
  "🕶",
  "🕷",
  "🕸",
  "🕹",
  "🕺",
  "🖇",
  "🖊",
  "🖋",
  "🖌",
  "🖍",
  "🖐",
  "🖕",
  "🖖",
  "🖤",
  "🖥",
  "🖨",
  "🖱",
  "🖲",
  "🖼",
  "🗂",
  "🗃",
  "🗄",
  "🗑",
  "🗒",
  "🗓",
  "🗜",
  "🗝",
  "🗞",
  "🗡",
  "🗣",
  "🗨",
  "🗯",
  "🗳",
  "🗺",
  "🗻",
  "🗼",
  "🗽",
  "🗾",
  "🗿",
  "🚁",
  "🚂",
  "🚃",
  "🚄",
  "🚅",
  "🚆",
  "🚇",
  "🚈",
  "🚉",
  "🚊",
  "🚋",
  "🚌",
  "🚍",
  "🚎",
  "🚏",
  "🚐",
  "🚑",
  "🚒",
  "🚓",
  "🚔",
  "🚕",
  "🚖",
  "🚗",
  "🚘",
  "🚙",
  "🚚",
  "🚛",
  "🚜",
  "🚝",
  "🚞",
  "🚟",
  "🚠",
  "🚡",
  "🚢",
  "🚣",
  "🚤",
  "🚥",
  "🚦",
  "🚧",
  "🚨",
  "🚩",
  "🚪",
  "🚫",
  "🚬",
  "🚭",
  "🚮",
  "🚯",
  "🚰",
  "🚱",
  "🚲",
  "🚳",
  "🚴",
  "🚵",
  "🚶",
  "🚷",
  "🚸",
  "🚹",
  "🚺",
  "🚻",
  "🚼",
  "🚽",
  "🚾",
  "🚿",
  "🛀",
  "🛁",
  "🛂",
  "🛃",
  "🛄",
  "🛅",
  "🛋",
  "🛌",
  "🛍",
  "🛎",
  "🛏",
  "🛐",
  "🛑",
  "🛒",
  "🛠",
  "🛡",
  "🛢",
  "🛣",
  "🛤",
  "🛥",
  "🛩",
  "🛫",
  "🛬",
  "🛰",
  "🛳",
  "🛴",
  "🛵",
  "🛶",
  "🛷",
  "🛸",
  "🛹",
  "🛺",
  "🤐",
  "🤑",
  "🤒",
  "🤓",
  "🤔",
  "🤕",
  "🤖",
  "🤗",
  "🤘",
  "🤙",
  "🤚",
  "🤛",
  "🤜",
  "🤝",
  "🤞",
  "🤟",
  "🤠",
  "🤡",
  "🤢",
  "🤣",
  "🤤",
  "🤥",
  "🤦",
  "🤧",
  "🤨",
  "🤩",
  "🤪",
  "🤫",
  "🤬",
  "🤭",
  "🤮",
  "🤯",
  "🤰",
  "🤱",
  "🤲",
  "🤳",
  "🤴",
  "🤵",
  "🤶",
  "🤷",
  "🤸",
  "🤹",
  "🤺",
  "🤼",
  "🤽",
  "🤾",
  "🥀",
  "🥁",
  "🥂",
  "🥃",
  "🥄",
  "🥅",
  "🥇",
  "🥈",
  "🥉",
  "🥊",
  "🥋",
  "🥌",
  "🥍",
  "🥎",
  "🥏",
  "🥐",
  "🥑",
  "🥒",
  "🥓",
  "🥔",
  "🥕",
  "🥖",
  "🥗",
  "🥘",
  "🥙",
  "🥚",
  "🥛",
  "🥜",
  "🥝",
  "🥞",
  "🥟",
  "🥠",
  "🥡",
  "🥢",
  "🥣",
  "🥤",
  "🥥",
  "🥦",
  "🥧",
  "🥨",
  "🥩",
  "🥪",
  "🥫",
  "🦀",
  "🦁",
  "🦂",
  "🦃",
  "🦄",
  "🦅",
  "🦆",
  "🦇",
  "🦈",
  "🦉",
  "🦊",
  "🦋",
  "🦌",
  "🦍",
  "🦎",
  "🦏",
  "🦐",
  "🦑",
  "🦒",
  "🦓",
  "🦔",
  "🦕",
  "🦖",
  "🦗",
  "🧀",
  "🧐",
  "🧑",
  "🧒",
  "🧓",
  "🧔",
  "🧕",
  "🧖",
  "🧗",
  "🧘",
  "🧙",
  "🧚",
  "🧛",
  "🧜",
  "🧝",
  "🧞",
  "🧟",
  "🧠",
  "🧡",
  "🧢",
  "🧣",
  "🧤",
  "🧥",
  "🧦",
];
