import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CustomTextField from "../components/CustomTextField";
import { useForm, Controller } from "react-hook-form";
import useServices from "../hooks/useServices.hook";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authState } from "../stores/auth.store";
import { useRecoilState } from "recoil";
import usePage from "../hooks/usePage";

export default function Login(props) {
  const { t } = useTranslation();
  let history = useHistory();
  const { authService } = useServices();
  const { handleSubmit, control } = useForm();

  usePage({ isMenuActive: false });

  const [, setAuth] = useRecoilState(authState);

  const onSubmit = async ({ email, password }) => {
    try {
      const newAuth = await authService.login(email, password);
      setAuth(newAuth);
      history.push("/");
    } catch (err) {}
  };

  return (
    <Box display="flex" justifyContent="center" mt={20}>
      <Box display="flex" flexDirection="column" width="fit-content">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h4" sx={{ mb: 2 }}>
              {t("pages.login.toLogin")}
            </Typography>
            <Stack direction="column" spacing={1} sx={{ minWidth: "30vw" }}>
              <Typography variant="body2">{t("common.email")}</Typography>
              <Controller
                control={control}
                name="email"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => <CustomTextField value={value} onChange={onChange} />}
              />
            </Stack>

            <Stack direction="column" spacing={1}>
              <Typography variant="body2">{t("common.password")}</Typography>
              <Controller
                control={control}
                name="password"
                render={({
                  field: { onChange, onBlur, value, name, ref },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <CustomTextField
                    type="password"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Stack>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                borderRadius: "100px",
                height: "50px",
                flexGrow: 1,
              }}
            >
              {t("pages.login.toLogin")}
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}
