import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../components/CustomTextField";
import IOSSwitch from "../../components/IOSSwitch";
import { Campaign } from "../../types/campaign.types";
import { Controller } from "react-hook-form";
import { ButtonSaveCampaignSettings } from ".";
import { useTranslation } from "react-i18next";
import { CompactPicker } from "react-color";

interface PopUpProps {
  campaign: Campaign;
  formMethods: any;
}

export default function PopUp({ campaign, formMethods }: PopUpProps) {
  const { t } = useTranslation();

  const [displayButton, setDisplayButton] = React.useState<boolean>(
    !!campaign?.popupButtonText?.length
  );
  const [displayMessage, setDisplayMessage] = React.useState<boolean>(
    !!campaign?.popupMessage?.length
  );
  const [displayPreviewMessage, setDisplayPreviewMessage] =
    React.useState<boolean>(!!campaign?.popupPreviewMessage?.length);

  React.useEffect(() => {
    if (!displayButton) {
      formMethods.setValue("popupButtonLink", "");
      formMethods.setValue("popupButtonText", "");
    }
    if (!displayMessage) {
      formMethods.setValue("popupMessage", "");
    }
    if (!displayPreviewMessage) {
      formMethods.setValue("popupPreviewMessage", "");
    }
  }, [displayButton, displayMessage, displayPreviewMessage]);

  return (
    <>
      <Stack spacing={1} direction="row">
        <Stack spacing={3} mt={1}>
          <Stack spacing={1}>
            <Typography variant="body2">
              {t("pages.campaignSettings.popupColor")}
            </Typography>
            <ControlledColorSelector
              control={formMethods.control}
              defaultValue={campaign.popupColor}
              name="popupColor"
            />
          </Stack>
          <Stack spacing={1}>
            <Typography variant="body2">
              {t("pages.campaignSettings.popupRefreshBeforeReDisplay")}
            </Typography>
            <Stack>
              <ControlledCustomTextField
                type="number"
                control={formMethods.control}
                defaultValue={campaign.popupRefreshBeforeReDisplay}
                name="popupRefreshBeforeReDisplay"
                step={1}
                pattern="\d+"
              />
              <Typography variant="body1" color="#9296A1">
                {t(
                  "pages.campaignSettings.popupRefreshBeforeReDisplayExplicationText"
                )}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Stack spacing={1} direction="row">
              {/* @ts-ignore */}
              <IOSSwitch
                checked={displayButton}
                onChange={(event, checked) => {
                  setDisplayButton(checked);
                }}
              />
              <Typography
                variant="body2"
                sx={{ cursor: "pointer" }}
                onClick={() => setDisplayButton(!displayButton)}
              >
                {t("pages.campaignSettings.popupDisplayButton")}
              </Typography>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={1} direction="row">
              {/* @ts-ignore */}
              <IOSSwitch
                checked={displayMessage}
                onChange={(event, checked) => {
                  setDisplayMessage(checked);
                }}
              />
              <Typography
                variant="body2"
                sx={{ cursor: "pointer" }}
                onClick={() => setDisplayMessage(!displayMessage)}
              >
                {t("pages.campaignSettings.popupDisplayMessage")}
              </Typography>
            </Stack>
            <Divider orientation="vertical" flexItem />
            <Stack spacing={1} direction="row">
              {/* @ts-ignore */}
              <IOSSwitch
                checked={displayPreviewMessage}
                onChange={(event, checked) => {
                  setDisplayPreviewMessage(checked);
                }}
              />
              <Typography
                variant="body2"
                sx={{ cursor: "pointer" }}
                onClick={() => setDisplayPreviewMessage(!displayPreviewMessage)}
              >
                {t("pages.campaignSettings.popupDisplayPreviewMessage")}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            sx={{
              flexWrap: "wrap",
              ">*": {
                width: "49%",
                marginRight: "1%",
                mb: 3,
              },
            }}
          >
            {displayButton && (
              <>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    {t("pages.campaignSettings.popupButtonText")}
                  </Typography>
                  <ControlledCustomTextField
                    control={formMethods.control}
                    defaultValue={campaign.popupButtonText}
                    name="popupButtonText"
                  />
                </Stack>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    {t("pages.campaignSettings.popupButtonLink")}
                  </Typography>
                  <ControlledCustomTextField
                    control={formMethods.control}
                    defaultValue={campaign.popupButtonLink}
                    name="popupButtonLink"
                  />
                </Stack>
              </>
            )}

            {displayMessage && (
              <Stack spacing={1}>
                <Typography variant="body2">
                  {t("pages.campaignSettings.popupMessage")}
                </Typography>
                <Stack>
                  <ControlledCustomTextField
                    control={formMethods.control}
                    defaultValue={campaign.popupMessage}
                    name="popupMessage"
                  />
                  <Typography variant="body1" color="#9296A1">
                    {t("pages.campaignSettings.replaceExplicationText")}
                  </Typography>
                </Stack>
              </Stack>
            )}

            {displayPreviewMessage && (
              <Stack spacing={1}>
                <Typography variant="body2">
                  {t("pages.campaignSettings.popupPreviewMessage")}
                </Typography>
                <Stack>
                  <ControlledCustomTextField
                    control={formMethods.control}
                    defaultValue={campaign.popupPreviewMessage}
                    name="popupPreviewMessage"
                  />
                  <Typography variant="body1" color="#9296A1">
                    {t("pages.campaignSettings.replaceExplicationText")}
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack></Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <ButtonSaveCampaignSettings />
      </Stack>
    </>
  );
}

function ControlledCustomTextField({
  pattern,
  step,
  sx,
  multiline,
  name,
  control,
  defaultValue,
  type,
}: {
  pattern?: string;
  step?: string | number;
  sx?: any;
  name: string;
  control: any;
  defaultValue?: string | number;
  multiline?: boolean;
  type?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <CustomTextField
          multiline={multiline || false}
          fullWidth={false}
          value={value}
          onChange={onChange}
          sx={sx}
          type={type}
          inputProps={{
            step,
            pattern,
          }}
        />
      )}
    />
  );
}

function ControlledColorSelector({
  name,
  control,
  defaultValue,
}: {
  name: string;
  control: any;
  defaultValue?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Box>
          <CompactPicker
            color={value || ""}
            onChangeComplete={(color) => onChange(color?.hex)}
          />
        </Box>
      )}
    />
  );
}
