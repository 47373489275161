import ApiService from "./api.service";
import AuthService from "./auth.service";
import CampaignsService from "./campaigns.service";
import NotificationService from "./notifications.service";
import QuestionService from "./questions.service";
import ResponseService from "./response.service";
import SitesService from "./sites.services";
import LoggerService from "./logger.service";

const authService = new AuthService();
const apiService = new ApiService();
const campaignsService = new CampaignsService( apiService );
const sitesService = new SitesService( apiService );
const questionService = new QuestionService( apiService );
const notificationService = new NotificationService( apiService );
const responseService = new ResponseService( apiService );
const loggerService = new LoggerService( {
    appName: "GrowProof",
    logLevel: "error",
    version: "1.0.0",
    logging: true,
    callBack: ( data ) => {
        apiService.post( { path: '/debug', body: data } );

    }
} );

interface Services {
    authService: AuthService;
    campaignsService: CampaignsService;
    sitesService: SitesService;
    questionService: QuestionService;
    notificationService: NotificationService;
    responseService: ResponseService,
    loggerService: LoggerService,

}

export default {
    authService,
    campaignsService,
    sitesService,
    questionService,
    notificationService,
    responseService,
    loggerService

} as Services;