const translation = {
  common: {
    sorryYouCantTestify:
      "Désolé, vous ne pouvez actuellement pas nous envoyer votre témoignage",
    videoAreLoading:
      "🚀 Vos réponses sont en cours d'envois sur nos serveurs ! Veuillez patienter quelques secondes ! Merci ! 🙂",
    toReplace: "Déplacer",
    sureReplaceFolder: "Voulez-vous vraiment déplacer cette campagne ?",
    sureReplaceCampaign: "Voulez-vous vraiment déplacer ce dossier ?",
    replaceSucced: "Déplacement effectué",
    replaceFailed: "Une erreur est survenue",
    root: "-- Racine --",

    download: "Télécharger",
    respondToCampaing: "Répondre à la campagne",
    validate: "Valider",
    sureDisconnect: "Attention, vous allez être déconnecté",
    confirmation: "Confirmation",
    sureDeleteFolder: "Voulez-vous vraiment supprimer ce dossier ?",
    sureDeleteCampaign: "Voulez-vous vraiment supprimer cette campagne ?",
    sureDeleteConditionDiffusion:
      "Voulez-vous vraiment supprimer cette condition de diffusion ?",
    surePauseCampaign: "Voulez-vous vraiment mettre en pause cette campagne ?",
    surePlayCampaign: "Voulez-vous vraiment démarrer cette campagne ?",
    toStop: "Arrêter",
    toStartUp: "Démarrer",
    viewRender: "Visionner le rendu",
    yes: "Oui",
    no: "Non",
    status: {
      draft: "Brouillon",
      ended: "En pause",
      inProgress: "En cours",
    },
    return: "Retour",
    settings: "Paramètres",
    toAnswer: "Répondre",
    hello: "Bonjour",
    toCancel: "Annuler",
    toDelete: "Supprimer",
    toConfirm: "Confirmer",
    toAdd: "Ajouter",
    toSave: "Enregistrer",
    toStart: "Commencer",
    toRestart: "Recommencer",
    email: "e-mail",
    password: "Mot de passe",
    title: "Titre",
    deleteSucced: "Suppression réussie !",
    deleteFailed: "Erreur lors de la suppression",
    saveSucced: "Enregistrement réussi !",
    saveFailed: "Erreur lors de l'enregistrement",
    ok: "Ok",
  },
  pages: {
    account: {
      account: "Compte",
      company: "Entreprise",
      email: "Email",
      phone: "Telephone",
      address: "Adresse",
      entity: "Entité",
      siret: "Siret",

      product: "Découvrir l'offre ",

      manageSubscription: "Gérer l'abonnement",
      goToHomePage: "Retourner à l'écran d'accueil",
      accountInformations: "Informations du compte",
      firstname: "Prénom",
      lastname: "Nom",
      avatar: "Avatar",
    },
    campaignSettings: {
      popupRefreshBeforeReDisplay:
        "Nombre de visites avant réapparition de la pop up",
      popupRefreshBeforeReDisplayExplicationText:
        "Indiquez combien de fois une personne doit visiter votre page avant que la pop up ne réapparaisse quand celle-ci a précédemment été fermée",
      popupDisplayButton: "Afficher un bouton dans la pop up",
      popupDisplayMessage: "Afficher un message dans la pop up",
      popupDisplayPreviewMessage:
        "Afficher un texte sur la prévisualisation de la pop up",
      popupColor: "Couleur de la pop up",
      replaceExplicationText:
        "Les textes {firstname} et {lastname} seront remplacés par le prénom et le nom du client",
      popupButtonText: "Texte du bouton",
      popupButtonLink: "Lien du bouton",
      popupMessage: "Texte de la pop up",
      popupPreviewMessage: "Texte de prévisualisation de la pop up",
      emailSettings: "Paramètres e-mail",
      emailReplyTo: "Adresse e-mail pour répondre",
      emailFrom: "Adresse e-mail de l'expéditeur",
      smtp_pass: "Mot de passe SMTP (email)",
      smtp_host: "Host SMTP",
      smtp_port: "Port SMTP",
      url_contain: "L'URL contient",
      url_do_not_contain: "L'URL ne contient pas",
      url_equal: "l'URL est égale à",
      url_not_equal: "l'URL n'est pas égale à",
      url_begin_with: "L'URL commence par",
      display: "Afficher",
      do_not_display: "Ne pas afficher",
      withChildren: "Inclure les sous-campagnes",
      notWithChildren: "Exclure les sous-campagnes",
      randomOrder: "Ordre aléatoire",
      noRandomOrder: "Ordre configuré",
      campaignName: "Nom de la campagne",
      giftLink: "Lien du cadeau",
      campaignStatus: "Statut de la campagne",
      customerNotation: "Notation par le client",
      sendAutoEmail: "Envoi d’un e-mail automatique",
      emailText: "Texte de l’e-mail",
      videoQuestions: "Questions en vidéos",
      textQuestions: "Questions écrites",
      yourQuestion: "Vos questions",
      viewRender: "Visionner le rendu",
      editMode: "Mode édition",
      conditions: "Conditions",
      condition: "Condition",
      addCondition: "Ajouter une condition",
      if: "Si",
      then: "Si toutes les conditions sont remplies, appliquer cette règle",
      rule: "Règle",
      draftCampaign: "Campagne en brouillon",
      testimonials: "Témoignages",
      returnToCampaign: "Retour à la campagne",
      generalSettings: "Paramètres généraux",
      popup: "Pop up",
      tastyWall: "Mur de témoignages",
      broadcasting: "Diffusion",
      publishUpdates: "Publier les modifications",
    },
    campaign: {
      draftCampaign: "Campagne en brouillon",
      testimonials: "Témoignages",
      returnToCampaign: "Retour aux campagnes",
      viewRender: "Visionner le rendu",
      youHaveXTestimonials:
        "Vous avez {{numberResponsesNotManaged}} témoignages en attente",
      yourCampaignIsUpToDate: "Votre campagne est à jour",
      yourCampaignCannotBePublishedYet:
        "Votre campagne ne peut pas encore être publiée",
      manageCampaignSettings: "Gérer les paramètres de votre campagne",
      itWillOnlyTakeFewMinutes: "Cela ne vous prendra que quelques minutes",
      campaignKeyFigures: "Statistiques de votre campagne",
      testimonialsTreated: "Témoignages traités",
      campaignSettings: "Paramétrer la campagne",
      superYourCampaignIsCreatedAsADraft:
        "Super, votre campagne vient d’être enregistrée en brouillon",
      clickRate: "Taux de clic",
      convertionRate: "Taux de conversion",
      numberOfPrint: "Nombre d’impressions",
      increaseSinceLastMonth: "{{value}} d’augmentation depuis le mois dernier",
      decreaseSinceLastMonth: "{{value}} de diminution depuis le mois dernier",
      archived: "Archivé",
      inappropriate: "Inapproprié",
      broadcast: "Diffusé",
      responseArchived: "Réponse archivée",
      responseInappropriated: "Réponse inappropriée",
      responseBroadcasted: "Réponse partagée",
      anErrorHasOccurred: "Une erreur est survenue",
      testimonyDate: "Date du témoignage",
      email: "e-mail",
      showEmbed: "Afficher le code",
      editStatus: "Modifier le statut",
      download: "Télécharger",
      toArchive: "Archiver",
      toBroadcast: "Diffuser",
      inappropriateTestimony: "Témoignage inapproprié",
      testimonyBroadcasted: "Témoignage validé et diffusé",
      testimonyArchived: "Témoignage archivé",
    },
    displayScriptInfoButtonModal: {
      integrateTheScriptOnYourMerchantSite:
        "Intégrer le script sur votre site internet",
      toUseOurService:
        "Pour utiliser GrowProof, veuillez suivre les instructions suivantes",
      addScriptOnProductPage: "Ajouter ce script sur votre page produit",
      addScriptOnSellDonePage:
        "Ajouter ce script sur votre page de confirmation de vente/commande",
      textCopied: "Texte copié !",
      toCopy: "Copier",
    },
    notificationDrawer: {
      notifications: "Notifications",
      upToDate: "À jour",
      newTestimonials: "Nouveaux témoignages",
      createNewCampaign: "Créer une nouvelle campagne",
    },
    customQuestionThankYouPage: {
      endPageIsHTML: "Configurer le code de la page manuellement",
      html: "HTML",
      thankYouScreen: "Écran de remerciement",
      yourMessage: "Votre message",
      layout: "Mise en page",
      title: "Titre",
      subtitle: "Sous-titre",
      viewRender: "Visionner le rendu",
      buttonText: "Texte du bouton",
      addMedia: "Ajouter un média",
      mainColor: "Couleur principale",
      buttonLink: "Lien du bouton",
    },
    customQuestionHomePage: {
      homeScreen: "Écran d’accueil",
      yourMessage: "Votre message",
      title: "Titre",
      subtitle: "Sous-titre",
      addMedia: "Ajouter un média",
      mainColor: "Couleur principale",
      userInformations: "Informations de l'utilisateur",
      firstname: "Prénom",
      lastname: "Nom",
      email: "Adresse e-mail",
      purchaseDate: "Date d’achat",
      viewRender: "Visionner le rendu",
    },
    updateVideoQuestion: {
      sureWantToDeleteThisQuestion:
        "Voulez-vous vraiment supprimer cette question ?",
    },
    createFolder: {
      createANewFolder: "Créer un nouveau dossier",
      folderName: "Nom du dossier",
      site: "Site",
      folder: "Dossier parent",
      createMyFolder: "Créer mon dossier",
    },
    createCampaign: {
      createANewCampaign: "Créer une nouvelle campagne",
      campaignName: "Nom de la campagne",
      site: "Site",
      folder: "Dossier",
      videoQuestions: "Questions en vidéos",
      textQuestions: "Questions écrites",
      createMyCampaign: "Créer ma campagne",
    },
    response: {
      warning: "Attention !",
      incompatibility:
        "Votre navigateur ne semble pas être compatible avec les technologies d'enregistrement vidéo utilisées par ce site !",
      incompatibilityTip:
        " Nous vous conseillons de mettre à jour votre navigateur ou d'en installer un autre pour profiter pleinement des fonctionnalités vidéo.",
      possibleErrors:
        "Des erreurs sont susceptibles de survenir si vous continuez.",
      starsProduct: "Note du produit",
      acceptTermsOfUse: "Accepter les conditions d’utilisation",
      yourFirstname: "Votre prénom",
      yourLastname: "Votre nom",
      yourEmail: "Votre adresse e-mail",
      purchaseDate: "Date d’achat",
      isThisResponseOkForYou: "Cette réponse vous convient-elle ?",
      acceptContract:
        "J'accepte les conditions tel que décrites dans ce contrat",
    },
    questionBuilder: {
      yourQuestions: "Vos questions",
      customizeHomeScreen: "Personnaliser l’écran d’accueil",
      customizeEndScreen: "Personnaliser l’écran de remerciement",
      addQuestion: "Ajouter une question",
      howToCreateQuestion: "Comment vous-voulez poser votre question ?",
      filmMyselfWithMyWebcam: "Me filmer avec ma webcam",
      integrateAReadyVideo: "Utiliser une vidéo existante",
      question: "Question",
      validate: "Valider",
      textQuestion: "Question écrite",
    },
    home: {
      sureWantToDeleteSite: "Voulez-vous vraiment supprimer ce site ?",
      youHaveXNotification:
        "Vous avez {{notificationsCount}} notifications à traiter",
      youAreUpdated: "Vous êtes à jour",
      createFolder: "Créer un dossier",
      createCampaign: "Créer une campagne",
      addSite: "Ajout d'un site",
      toAddSite: "Ajouter un site",
    },
    login: {
      toLogin: "Se connecter",
    },
  },
};

export default translation;
