import { Box } from "@mui/system";
import React from "react";
import ReturnPageButton from "../ReturnPageButton";
import {
  Button,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import RecordRTC, {
  // @ts-ignore
  RecordRTCPromisesHandler,
} from "recordrtc";
import AloneBadge from "../AloneBadge";
import { useTranslation } from "react-i18next";
import Services from "../../services/context";
interface VideoRecordProps {
  onGoPrevious: () => void;
  onVideoBlobComplete: (blob: Blob, posterSRC: string) => void;
}

type RecordStates = "recording" | "on-wait-to-start" | "no-record";

async function startStream(stream, videoRef, setStream): Promise<MediaStream> {
  if (stream) {
    return stream;
  }
  if (videoRef?.current) {
    const newStream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    });
    /*
            videoRef.current.loop = false;
            videoRef.current.src = null;
            videoRef.current.muted = true;
            */
    if (videoRef?.current) {
      videoRef.current.srcObject = newStream;
      setStream(newStream);
    }
    return newStream;
  }
}

function stopStream(stream, setStream, setRecorder): void {
  if (stream) {
    const tracks = stream.getTracks();
    tracks.forEach(function (track) {
      track.stop();
    });
    setStream(null);
    setRecorder(null);
  }
}

export default function VideoRecord({
  onGoPrevious,
  onVideoBlobComplete,
}: VideoRecordProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const videoRef = React.useRef(null);
  const [recorder, setRecorder] = React.useState<RecordRTC | null>();
  const [stream, setStream] = React.useState<MediaStream | null>();
  const [videoBlob, setVideoUrlBlob] = React.useState<Blob | null>();
  const [poster, setPoster] = React.useState<string | null>();

  const [recordStartedAt, setRecordStartedAt] = React.useState<number | null>(
    null
  );
  const [recordDuration, setRecordDuration] = React.useState<string>("");
  const [recordState, setRecordState] =
    React.useState<RecordStates>("no-record");

  function handleGoPrevious() {
    stopStream(stream, setStream, setRecorder);
    onGoPrevious();
  }

  const handleStartTimerToStartRecording = () => {
    setRecordState("on-wait-to-start");
  };

  const handleStartRecording = async () => {
    try {
      const currentStream = await startStream(stream, videoRef, setStream);
      setRecordState("recording");
      setRecordDuration("00:00");
      setRecordStartedAt(new Date().getTime());
      setVideoUrlBlob(null);
      const recorder: RecordRTC = new RecordRTCPromisesHandler(currentStream, {
        type: "video",
        mimeType: "video/webm;codecs=vp8",
      });
      await recorder.startRecording();
      let canvas = document.createElement("canvas");
      const video = window.document.getElementById(
        "live_video"
      ) as HTMLVideoElement;
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const src = canvas.toDataURL();
      setPoster(src);
      setRecorder(recorder);
      setVideoUrlBlob(null);
    } catch (err) {
      Services.loggerService.listen(err);
      console.error(err);
    }
  };

  const handleStopRecording = async () => {
    if (recorder) {
      await recorder.stopRecording();
      const blob: Blob = await recorder.getBlob();
      (stream as any).stop();
      setVideoUrlBlob(blob);
      stopStream(stream, setStream, setRecorder);
      setRecordState("no-record");
      Services.loggerService.listen({
        size: blob.size,
        type: blob.type,
      });

      onVideoBlobComplete(blob, poster);

      /*
            if (videoRef?.current) {
                videoRef.current.srcObject = null;
                const url = URL.createObjectURL(blob);
                videoRef.current.loop = true;
                videoRef.current.muted = false;
                videoRef.current.src = url;
                videoRef.current.load();
            }
            */
    }
  };

  function calculateTimeDuration(secs) {
    var hr = Math.floor(secs / 3600);
    var min: number | string = Math.floor((secs - hr * 3600) / 60);
    var sec: number | string = Math.floor(secs - hr * 3600 - min * 60);

    if (min < 10) {
      min = "0" + min;
    }

    if (sec < 10) {
      sec = "0" + sec;
    }

    if (hr <= 0) {
      return min + ":" + sec;
    }

    return hr + ":" + min + ":" + sec;
  }

  React.useEffect(() => {
    startStream(stream, videoRef, setStream);

    return () => stopStream(stream, setStream, setRecorder);
  }, [stream]);

  React.useEffect(() => {
    let recorderInterval = setInterval(() => {
      setRecordDuration(
        calculateTimeDuration((new Date().getTime() - recordStartedAt) / 1000)
      );
    }, 500);

    return () => {
      clearInterval(recorderInterval);
    };
  }, [recorder, recordStartedAt]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100vw",
        height: "100vh",
        maxHeight: "-webkit-fill-available",
        minHeight: "-webkit-fill-available",
      }}
    >
      <ReturnPageButton onClick={handleGoPrevious} />

      <Box
        pr={2}
        pl={2}
        sx={{
          borderRadius: matchesSmScreen ? 0 : "56px",
          backgroundColor: "black",
          position: "relative",
        }}
        p={matchesSmScreen ? 0 : 5}
        width={matchesSmScreen ? "100vw" : "80%"}
        height={matchesSmScreen ? "100vh" : "75vh"}
      >
        <Box
          sx={{
            display: matchesSmScreen ? "none" : "block",
            backgroundColor: "black",
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            borderRadius: matchesSmScreen ? 0 : "0 56px 56px 0",
            height: "100%",
            zIndex: 2,
            width: "20%",
            transition: "width 3s",
          }}
        ></Box>
        <Box
          sx={{
            display: matchesSmScreen ? "none" : "block",
            backgroundColor: "black",
            position: "absolute",
            top: 0,
            left: 0,
            bottom: 0,
            borderRadius: matchesSmScreen ? 0 : "56px 0 0 56px",
            height: "100%",
            zIndex: 2,
            width: "20%",
            transition: "width 3s",
          }}
        ></Box>

        {recordState === "on-wait-to-start" && (
          <BeforeStartRecord
            matchesSmScreen={matchesSmScreen}
            onCanStart={handleStartRecording}
          />
        )}
        <video
          id="live_video"
          muted
          autoPlay
          playsInline
          ref={videoRef}
          style={{
            borderRadius: matchesSmScreen ? 0 : "56px",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            objectFit: "contain",
          }}
        ></video>

        <Box
          mb={2}
          sx={{
            position: "absolute",
            bottom: 0,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {videoBlob ? (
            <Button
              variant="contained"
              sx={{
                borderRadius: "50%",
                width: "160px",
                height: "160px",
              }}
              onClick={handleStartTimerToStartRecording}
            >
              {t("common.toRestart")}
            </Button>
          ) : recordState === "recording" ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Stack
                direction="row"
                spacing={1}
                display="flex"
                alignItems="center"
              >
                <AloneBadge size="md" color={theme.palette.primary.main} />
                <Typography
                  variant="body2"
                  fontWeight={500}
                  fontSize="24px"
                  color={theme.palette.primary.main}
                >
                  {recordDuration}
                </Typography>
              </Stack>
              <Button
                variant="contained"
                sx={{
                  borderRadius: "50%",
                  width: "100px",
                  height: "100px",
                  backgroundColor: "rgba(80, 74, 183, 0.3)",
                }}
                onClick={handleStopRecording}
              >
                <Box
                  width="50px"
                  height="50px"
                  sx={{ backgroundColor: theme.palette.primary.main }}
                ></Box>
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              sx={{
                borderRadius: "50%",
                width: "160px",
                height: "160px",
              }}
              onClick={handleStartTimerToStartRecording}
            >
              {t("common.toStart")}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
interface BeforeStartRecordProps {
  onCanStart: () => void;
  matchesSmScreen?: boolean;
}
function BeforeStartRecord({
  onCanStart,
  matchesSmScreen,
}: BeforeStartRecordProps) {
  const [timer, setTimer] = React.useState<number>(3);

  // console.log( timer );

  React.useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((previousTimer) => previousTimer - 1);
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  React.useEffect(() => {
    if (timer === 0) {
      onCanStart();
    }
  }, [timer, onCanStart]);

  return (
    <>
      <Typography
        variant="h1"
        color="#ED2E7E"
        fontWeight={600}
        fontSize="200px"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
      >
        {timer}
      </Typography>
    </>
  );
}
