import {
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Chip,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { Box, useTheme } from "@mui/system";
import { Campaign } from "../../types/campaign.types";
import { useHistory } from "react-router-dom";
import useService from "../../hooks/useServices.hook";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { confirm } from "../../plugins/confirm.plugin";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";

interface FolderListItemProps {
  folder: Campaign;
  nested?: boolean;
  campaigns?: Campaign[];
  mutateCampaigns: any;
}
export function FolderListItem({
  folder,
  nested,
  campaigns,
  mutateCampaigns,
}: FolderListItemProps) {
  const theme = useTheme();

  const LOCALSTORAGE_ISOPEN_KEY = `is_open_folder_${folder?.id}`;

  const [isOpen, setIsOpen] = React.useState(
    JSON.parse(localStorage.getItem(LOCALSTORAGE_ISOPEN_KEY) || "false")
  );

  const handleClick = () => {
    localStorage.setItem(LOCALSTORAGE_ISOPEN_KEY, JSON.stringify(!isOpen));
    setIsOpen(!isOpen);
  };

  const campaignChildren = campaigns.filter((c) => c.parentId === folder.id);
  const hasFolderChildren: boolean = !!campaignChildren.find((c) => c.isFolder);

  return (
    <Grid item md={nested ? 12 : 6} xs={12} pl={nested ? 3 : 0}>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <FolderIcon
            style={{
              color: hasFolderChildren
                ? theme.palette.info.light
                : theme.palette.success.main,
            }}
          />
        </ListItemIcon>
        <ListItemText primary={`${folder.name} ${folder?.emoji || ""}`} />
        <ButtonMenu
          campaign={folder}
          mutateCampaigns={mutateCampaigns}
          campaigns={campaigns}
        />
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List disablePadding>
          {campaignChildren.map((campaign: Campaign) =>
            campaign.isFolder ? (
              <FolderListItem
                key={campaign.id}
                folder={campaign}
                campaigns={campaigns}
                nested
                mutateCampaigns={mutateCampaigns}
              />
            ) : (
              <CampaignListItem
                nested
                key={campaign.id}
                campaigns={campaigns}
                campaign={campaign}
                mutateCampaigns={mutateCampaigns}
              />
            )
          )}
        </List>
      </Collapse>
    </Grid>
  );
}

interface CampaignListItemProps {
  campaign: Campaign;
  nested?: boolean;
  mutateCampaigns: any;
  campaigns: Campaign[];
}
export function CampaignListItem({
  campaign,
  nested,
  mutateCampaigns,
  campaigns,
}: CampaignListItemProps) {
  const { t } = useTranslation();
  let history = useHistory();
  const theme = useTheme();
  const matchesXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  function goToCampaign() {
    history.push(`/campaign/${campaign.id}`);
  }

  return (
    <Grid item md={nested ? 12 : 6} xs={12} pl={nested ? 3 : 0}>
      <ListItemButton onClick={goToCampaign}>
        <ListItemIcon>
          <PlayCircleFilledIcon style={{ color: theme.palette.warning.main }} />
        </ListItemIcon>
        <ListItemText primary={`${campaign.name} ${campaign?.emoji || ""}`} />
        <Box display="flex" alignItems="center">
          {/*
                    
                    {!matchesXsScreen && (
                        <>
                            {campaign.status === 'draft' && (
                                <Chip icon={<LoyaltyIcon style={{ color: "#629DD3", fontSize: '16px' }} />} label={t('common.status.draft')} sx={{ background: '#E8F7FE', color: '#629DD3' }} />
                            )}
                            {campaign.status === 'inProgress' && (
                                <Chip icon={<PlayCircleOutlineIcon style={{ color: theme.palette.success.dark, fontSize: '16px' }} />}
                                    label={t('common.status.inProgress')}
                                    sx={{ background: theme.palette.success.light, color: theme.palette.success.dark }}
                                />
                            )}
                            {campaign.status === 'ended' && (
                                <Chip
                                    icon={<PauseCircleOutlineIcon style={{ color: theme.palette.error.dark, fontSize: '16px' }} />}
                                    label={t('common.status.ended')}
                                    sx={{ background: '#FFF0F6', color: theme.palette.error.dark }}
                                />
                            )}
                        </>
                    )}

                    <Box ml={matchesXsScreen ? 0 : 2}>
                        <ButtonMenu campaign={campaign} mutateCampaigns={mutateCampaigns} />
                    </Box>
                    */}

          {campaign.status === "draft" && (
            <Chip
              icon={
                <LoyaltyIcon style={{ color: "#629DD3", fontSize: "16px" }} />
              }
              label={t("common.status.draft")}
              sx={{ background: "#E8F7FE", color: "#629DD3" }}
            />
          )}
          {campaign.status === "inProgress" && (
            <Chip
              icon={
                <PlayCircleOutlineIcon
                  style={{
                    color: theme.palette.success.dark,
                    fontSize: "16px",
                  }}
                />
              }
              label={t("common.status.inProgress")}
              sx={{
                background: theme.palette.success.light,
                color: theme.palette.success.dark,
              }}
            />
          )}
          {campaign.status === "ended" && (
            <Chip
              icon={
                <PauseCircleOutlineIcon
                  style={{ color: theme.palette.error.dark, fontSize: "16px" }}
                />
              }
              label={t("common.status.ended")}
              sx={{ background: "#FFF0F6", color: theme.palette.error.dark }}
            />
          )}

          <Box ml={matchesXsScreen ? 0.5 : 2}>
            <ButtonMenu
              campaign={campaign}
              mutateCampaigns={mutateCampaigns}
              campaigns={campaigns}
            />
          </Box>
        </Box>
      </ListItemButton>
    </Grid>
  );
}

interface ButtonMenuProps {
  campaign: Campaign;
  mutateCampaigns: any;
  campaigns: Campaign[];
  campaignChildren?: Campaign[];
}

function ButtonMenu({ campaigns, campaign, mutateCampaigns }: ButtonMenuProps) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { campaignsService } = useService();
  let history = useHistory();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (event && event?.stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  async function handleDelete(event: React.MouseEvent) {
    event.stopPropagation();
    try {
      if (
        await confirm(
          campaign?.isFolder
            ? t("common.sureDeleteFolder")
            : t("common.sureDeleteCampaign")
        )
      ) {
        await campaignsService.delete(campaign.id);
        //mutateCampaigns(cs => cs.filter(c => c.id != campaign.id))
        mutateCampaigns();
        enqueueSnackbar(t("common.deleteSucced"), { variant: "success" });
        handleClose(event);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.deleteFailed"), { variant: "error" });
    }
  }

  function handleGoToCampaignSettings(event: React.MouseEvent) {
    event.stopPropagation();
    handleClose(event);
    history.push(`/campaign/${campaign.id}/settings`);
  }

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleGoToCampaignSettings}>
          <ListItemText>{t("common.settings")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          {/*
                <ListItemIcon>
                    <DeleteOutlineIcon fontSize="small" sx={{ color: theme.palette.error.main }} />
                </ListItemIcon>
                */}
          <ListItemText>{t("common.toDelete")}</ListItemText>
        </MenuItem>

        <ReplaceMenu
          label={t("common.toReplace")}
          folders={campaigns.filter((c) => c.isFolder && !c.parentId)}
          campaigns={campaigns}
          campaign={campaign}
          mutateCampaigns={mutateCampaigns}
        />
      </Menu>
    </>
  );
}

function ReplaceMenu({ campaign, label, folders, campaigns, mutateCampaigns }) {
  const rootId = folders?.length ? folders[0]?.parentId : null;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { campaignsService } = useService();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    if (event && event?.stopPropagation) {
      event.stopPropagation();
    }
    setAnchorEl(null);
  };

  async function handleReplaceCampaign(event, folder: Campaign) {
    if (event && event?.stopPropagation) {
      event.stopPropagation();
    }
    try {
      if (
        await confirm(
          campaign?.isFolder
            ? t("common.sureReplaceFolder")
            : t("common.sureReplaceCampaign")
        )
      ) {
        await campaignsService.update(campaign.id, {
          parentId: folder.id,
        });
        //mutateCampaigns(cs => cs.filter(c => c.id != campaign.id))
        mutateCampaigns();
        enqueueSnackbar(t("common.replaceSucced"), { variant: "success" });
        handleClose(event);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.replaceFailed"), { variant: "error" });
    }
  }

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemText>{label}</ListItemText>
      </MenuItem>
      <Menu
        id="basic-menu-replace"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={(event) => handleReplaceCampaign(event, { id: rootId })}
        >
          <ListItemText>{t("common.root")}</ListItemText>
        </MenuItem>
        {(folders || [])
          .filter((f) => f.id !== campaign?.id)
          .map((folder) => {
            const childrenFolder = campaigns.filter(
              (c) => c.parentId === folder.id && c.isFolder
            );
            if (childrenFolder?.length) {
              return (
                <ReplaceMenu
                  key={folder.id}
                  label={folder.name}
                  campaigns={campaigns}
                  folders={childrenFolder}
                  campaign={campaign}
                  mutateCampaigns={mutateCampaigns}
                />
              );
            } else {
              return (
                <MenuItem
                  key={folder.id}
                  onClick={(event) => handleReplaceCampaign(event, folder)}
                >
                  <ListItemText>{folder.name}</ListItemText>
                </MenuItem>
              );
            }
          })}
      </Menu>
    </>
  );
}
