import { Box, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { pageState } from "../stores/page.store";
import Header, { HeaderProps } from "./Header";
import Menu from "./Menu";

export interface PageProps {
  headerProps?: HeaderProps;
  isMenuActive;
}

export default function Page({
  children,
  headerProps: h,
}: {
  children: any;
  headerProps?: any;
}) {
  const theme = useTheme();
  const matchesXsScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [{ isMenuActive, headerProps }]: [PageProps, (p: PageProps) => void] =
    useRecoilState(pageState);

  return isMenuActive ? (
    <>
      <Menu />
      <Box sx={{ marginLeft: "54px", width: "calc(100% - 54px)" }}>
        <Box ml={matchesXsScreen ? 1 : 3} mr={matchesXsScreen ? 1 : 3} mb={4}>
          <Header {...headerProps} />
          {children}
        </Box>
      </Box>
    </>
  ) : (
    children
  );
}
