import {
  Button,
  Modal,
  Box,
  Typography,
  IconButton,
  Tooltip,
  Stack,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useForm, Controller } from "react-hook-form";
import CustomTextField from "../../components/CustomTextField";
import CloseIcon from "@mui/icons-material/Close";
import { Site } from "../../types/user.types";
import useServices from "../../hooks/useServices.hook";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

interface ButtonModalAddSiteProps {
  mutateSites: (sites: Array<Site>) => void;
  sites: Array<Site>;
  setSelectedSite: (site: Site) => void;
}

export default function ButtonModalAddSite({
  mutateSites,
  sites,
  setSelectedSite,
}: ButtonModalAddSiteProps) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { sitesService } = useServices();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, reset } = useForm();

  async function handleSave(data) {
    try {
      const site = await sitesService.create(data);
      mutateSites([...sites, site]);
      enqueueSnackbar(t("common.saveSucced"), { variant: "success" });

      handleClose();
      setSelectedSite(site);
      reset();
    } catch (err) {
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
  }

  return (
    <>
      <Tooltip title={t("pages.home.toAddSite")}>
        <IconButton onClick={handleOpen}>
          <AddIcon color="success" />
        </IconButton>
      </Tooltip>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "none",
            boxShadow: 24,
            p: 4,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: "8px",
              right: "8px",
            }}
            onClick={handleClose}
          >
            <CloseIcon fontSize="medium" />
          </IconButton>
          <form onSubmit={handleSubmit(handleSave)}>
            <Stack spacing={2}>
              <Typography variant="h5" fontWeight={500} fontSize="24px" mb={4}>
                {t("pages.home.addSite")}
              </Typography>

              {/*<Stack spacing={1}>
                                    <Typography variant="h4">
                                        URL
                                    </Typography>
                                    <ControlledCustomTextField control={control} defaultValue={""} name="url" />
                                </Stack>*/}
              <Stack spacing={1}>
                <Typography variant="body2">{t("common.title")}</Typography>
                <ControlledCustomTextField
                  control={control}
                  defaultValue={""}
                  name="title"
                />
              </Stack>
              <Button
                type="submit"
                variant="contained"
                sx={{ borderRadius: "100px", height: "50px" }}
              >
                {t("common.toSave")}
              </Button>
            </Stack>
          </form>
        </Box>
      </Modal>
    </>
  );
}

function ControlledCustomTextField({
  name,
  control,
  defaultValue,
}: {
  name: string;
  control: any;
  defaultValue?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({ field: { onChange, value } }) => (
        <CustomTextField value={value} onChange={onChange} />
      )}
    />
  );
}
