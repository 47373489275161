import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import CustomTextField from "../../components/CustomTextField";
import { Campaign } from "../../types/campaign.types";
import { Controller } from "react-hook-form";
import { ButtonSaveCampaignSettings } from ".";
import { useTranslation } from "react-i18next";
import ControlledRichTextEditor from "../../components/form-components/ControlledRichTextEditor";

interface EmailSettingsProps {
  campaign: Campaign;
  formMethods: any;
}

export default function EmailSettings({
  campaign,
  formMethods,
}: EmailSettingsProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack spacing={3} mt={1}>
      <Stack
        direction={matchesSmScreen ? "column" : "row"}
        spacing={matchesSmScreen ? 1 : 2}
      >
        <Stack spacing={1} flex=".25">
          <Typography variant="body2">
            {t("pages.campaignSettings.smtp_host")}
          </Typography>
          <ControlledCustomTextField
            control={formMethods.control}
            defaultValue={campaign.smtp_host}
            name="smtp_host"
          />
        </Stack>
        <Stack spacing={1} flex=".25">
          <Typography variant="body2">
            {t("pages.campaignSettings.smtp_port")}
          </Typography>
          <ControlledCustomTextField
            control={formMethods.control}
            type="number"
            defaultValue={campaign.smtp_port}
            name="smtp_port"
          />
        </Stack>
      </Stack>
      <Stack
        direction={matchesSmScreen ? "column" : "row"}
        spacing={matchesSmScreen ? 1 : 2}
      >
        <Stack spacing={1} flex=".25">
          <Typography variant="body2">
            {t("pages.campaignSettings.emailFrom")}
          </Typography>
          <ControlledCustomTextField
            control={formMethods.control}
            defaultValue={campaign.emailFrom}
            name="emailFrom"
          />
        </Stack>
        <Stack spacing={1} flex=".25">
          <Typography variant="body2">
            {t("pages.campaignSettings.smtp_pass")}
          </Typography>
          <ControlledCustomTextField
            control={formMethods.control}
            defaultValue={campaign.smtp_pass}
            name="smtp_pass"
          />
        </Stack>
      </Stack>
      <Stack
        direction={matchesSmScreen ? "column" : "row"}
        spacing={matchesSmScreen ? 1 : 2}
      >
        <Stack spacing={1}>
          <Typography variant="body2">
            {t("pages.campaignSettings.emailReplyTo")}
          </Typography>
          <ControlledCustomTextField
            control={formMethods.control}
            defaultValue={campaign.emailReplyTo}
            name="emailReplyTo"
          />
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body2">
          {t("pages.campaignSettings.emailText")}
        </Typography>
        {/*
                <ControlledCustomTextField
                    control={formMethods.control}
                    multiline
                    defaultValue={campaign.textEmail}
                    name="textEmail"
                    sx={{
                        width: matchesSmScreen ? '100%' : '50%',
                        maxWidth: '100%'
                    }}
                />
                */}
        <ControlledRichTextEditor
          control={formMethods.control}
          name="textEmail"
          defaultValue={campaign.textEmail}
        />
      </Stack>
      <Stack direction="row" justifyContent="flex-end">
        <ButtonSaveCampaignSettings />
      </Stack>
    </Stack>
  );
}

function ControlledCustomTextField({
  sx,
  multiline,
  name,
  control,
  defaultValue,
  type,
}: {
  sx?: any;
  name: string;
  control: any;
  defaultValue?: string;
  multiline?: boolean;
  type?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({ field: { onChange, value } }) => (
        <CustomTextField
          multiline={multiline || false}
          fullWidth={false}
          value={value}
          onChange={onChange}
          sx={sx}
          type={type}
        />
      )}
    />
  );
}
