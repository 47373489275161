import { Button, InputAdornment, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import useGreyBackground from "../hooks/useGreyBackground";
import { Campaign } from "../types/campaign.types";
import Divider from "@mui/material/Divider";
import ArrowDownwardOutlinedIcon from "@mui/icons-material/ArrowDownwardOutlined";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ClosePageButton from "../components/ClosePageButton";
import CustomTextField from "../components/CustomTextField";
import CustomSwitchField from "../components/CustomSwitchField";
import useSWR from "swr";
import useServices from "../hooks/useServices.hook";
import { useForm, Controller } from "react-hook-form";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import ViewRenderingButtonModal from "../components/ViewRenderingButtonModal";
import ResponseHomePage from "../components/Response/ResponseHomePage";
import { CompactPicker } from "react-color";
import usePage from "../hooks/usePage";

// todo : add media to campaign home page
export default function CustomHomePage(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const matchesSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [, setMedia] = useState<File>(null);

  usePage({ isMenuActive: false });

  useGreyBackground();
  const { idCampaign } = useParams();
  let history = useHistory();
  const { campaignsService } = useServices();
  const { data: campaign, mutate: mutateCampaign } = useSWR(
    ["campaigns", idCampaign],
    (key, id) => campaignsService.getById(id)
  );

  const { handleSubmit, control, getValues, reset } = useForm();

  React.useEffect(() => {
    reset(campaign);
  }, [campaign, reset]);

  const [previewData, setPreviewData] = React.useState<Campaign>(campaign);

  async function handleSave(data) {
    try {
      await campaignsService.update(idCampaign, data);
      enqueueSnackbar(t("common.saveSucced"), { variant: "success" });
      mutateCampaign({
        ...campaign,
        ...data,
      });
      handleClose();
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("common.saveFailed"), { variant: "error" });
    }
  }

  function handleViewRender() {
    setPreviewData({
      ...getValues(),

      notations: campaign?.notations,
    });
  }

  function handleClose() {
    history.goBack();
  }

  return (
    <form onSubmit={handleSubmit(handleSave)}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pb={2}
        mt={matchesSmScreen ? 0 : 6}
      >
        <ClosePageButton onClick={handleClose} />

        <Typography variant="h4" color="primary" mt={5}>
          {t("pages.customQuestionHomePage.homeScreen")}
        </Typography>
        <Box
          display="flex"
          flexDirection={matchesSmScreen ? "column" : "row"}
          justifyContent="space-between"
          width="80%"
          mt={5}
        >
          <Stack spacing={2} minWidth="45%">
            <Typography variant="h5" fontSize="24px" mb={4}>
              {t("pages.customQuestionHomePage.yourMessage")}
            </Typography>

            <Stack spacing={1}>
              <Typography variant="body2">
                {t("pages.customQuestionHomePage.title")}
              </Typography>
              <ControlledCustomTextField
                control={control}
                defaultValue={campaign?.homePageTitle}
                name="homePageTitle"
              />
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2">
                {t("pages.customQuestionHomePage.subtitle")}
              </Typography>
              <ControlledCustomTextField
                control={control}
                defaultValue={campaign?.homePageSubTitle}
                name="homePageSubTitle"
              />
            </Stack>
            <Stack spacing={1}>
              <input
                style={{ display: "none" }}
                id="button-file"
                type="file"
                onChange={(event) => {
                  setMedia(event.target.files[0]);
                }}
              />
              <label htmlFor="button-file">
                <CustomTextField
                  sx={{ cursor: "pointer" }}
                  InputProps={{
                    style: { cursor: "pointer" },
                    startAdornment: (
                      <InputAdornment position="start">
                        <ArrowDownwardOutlinedIcon
                          sx={{ color: theme.palette.primary.main }}
                        />
                        <Typography variant="body2" color="black" ml={1.5}>
                          {t("pages.customQuestionHomePage.addMedia")}
                        </Typography>
                      </InputAdornment>
                    ),
                    disabled: true,
                  }}
                />
              </label>
            </Stack>
            <Stack spacing={1}>
              <Typography variant="body2">
                {t("pages.customQuestionHomePage.mainColor")}
              </Typography>
              <ControlledColorSelector
                control={control}
                defaultValue={campaign?.homePagePrimaryColor}
                name="homePagePrimaryColor"
              />
            </Stack>
          </Stack>
          {matchesSmScreen ? (
            <Box mt={4}></Box>
          ) : (
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "white" }}
            />
          )}

          <Stack minWidth="45%" spacing={2} justifyContent="space-between">
            <Typography variant="h5" fontWeight={500} fontSize="24px" mb={4}>
              {t("pages.customQuestionHomePage.userInformations")}
            </Typography>

            {/*
                        <ControlledCustomSwitchField
                            control={control}
                            defaultValue={campaign?.firstnameNeeded}
                            label={t('pages.customQuestionHomePage.firstname')}
                            name="firstnameNeeded"
                            disabled
                        />
                        <ControlledCustomSwitchField
                            control={control}
                            defaultValue={campaign?.nameNeeded}
                            label={t('pages.customQuestionHomePage.lastname')}
                            name="nameNeeded"
                            disabled
                        />
                        <ControlledCustomSwitchField
                            control={control}
                            defaultValue={campaign?.emailNeeded}
                            label={t('pages.customQuestionHomePage.email')}
                            name="emailNeeded"
                        />
                        */}
            <CustomSwitchField
              disabled
              checked={true}
              label={t("pages.customQuestionHomePage.firstname")}
            />
            <CustomSwitchField
              disabled
              checked={true}
              label={t("pages.customQuestionHomePage.lastname")}
            />
            <CustomSwitchField
              disabled
              checked={true}
              label={t("pages.customQuestionHomePage.email")}
            />

            <ControlledCustomSwitchField
              control={control}
              defaultValue={campaign?.purchaseDateNeeded}
              label={t("pages.customQuestionHomePage.purchaseDate")}
              name="purchaseDateNeeded"
            />
          </Stack>
        </Box>

        <ViewRenderingButtonModal
          sx={{ marginTop: 8 }}
          onOpen={handleViewRender}
        >
          <ResponseHomePage defaultProps={previewData} />
        </ViewRenderingButtonModal>

        <Button
          type="submit"
          variant="contained"
          sx={{
            borderRadius: "100px",
            height: "50px",
            marginTop: 1,
            minWidth: "336px",
          }}
        >
          {t("common.toSave")}
        </Button>
      </Box>
    </form>
  );
}

function ControlledCustomSwitchField({
  name,
  control,
  label,
  defaultValue,
}: {
  name: string;
  control: any;
  label: string;
  defaultValue?: boolean;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || false}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <CustomSwitchField
          checked={!!value}
          onSwitchChange={onChange}
          label={label}
        />
      )}
    />
  );
}

function ControlledCustomTextField({
  name,
  control,
  defaultValue,
}: {
  name: string;
  control: any;
  defaultValue?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => <CustomTextField value={value} onChange={onChange} />}
    />
  );
}

function ControlledColorSelector({
  name,
  control,
  defaultValue,
}: {
  name: string;
  control: any;
  defaultValue?: string;
}) {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue || ""}
      name={name}
      render={({
        field: { onChange, onBlur, value, name, ref },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <Box
          sx={{
            maxWidth: "500px",
            ">*": {
              width: "100%",
            },
            ">*>*": {
              boxShadow: "none!important",
            },
            ">*>*>*": {
              width: "auto!important",
              padding: "8px!important",
            },
          }}
        >
          <CompactPicker
            color={value || ""}
            onChangeComplete={(color) => onChange(color?.hex)}
          />
        </Box>
      )}
    />
  );
}
