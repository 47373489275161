import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import ColorBlock from "../../components/ColorBlock";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import MouseOutlinedIcon from "@mui/icons-material/MouseOutlined";
import EmojiObjectsOutlinedIcon from "@mui/icons-material/EmojiObjectsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import { useTranslation } from "react-i18next";
import { CampaignStats } from "../../types/campaign.types";

interface KeySettingsProps {
  stats: CampaignStats;
}

export default function KeySettings({ stats }: KeySettingsProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchesXsScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      display="flex"
      direction={matchesXsScreen ? "column" : "row"}
      spacing={matchesXsScreen ? 1 : 2}
    >
      <ColorBlock
        text1={t("pages.campaign.clickRate")}
        text2={(stats?.clickRate || "-") + "%"}
        text3={t(
          `pages.campaign.${
            (stats?.percentEvolutionClickRate || 0) > 0
              ? "increaseSinceLastMonth"
              : "decreaseSinceLastMonth"
          }`,
          { value: `${stats?.percentEvolutionClickRate || "-"}%` }
        )}
        icon1={MouseOutlinedIcon}
        icon2={ChangeHistoryIcon}
        clickable
        minSize
        centerElements={false}
        color="primary"
        revertIcon2={(stats?.percentEvolutionClickRate || 0) < 0}
        width={matchesXsScreen ? "100%" : "auto"}
      />

      <ColorBlock
        text1={t("pages.campaign.convertionRate")}
        text2={(stats?.conversionRate || "-") + "%"}
        text3={t(
          `pages.campaign.${
            (stats?.percentEvolutionConversionRate || 0) > 0
              ? "increaseSinceLastMonth"
              : "decreaseSinceLastMonth"
          }`,
          { value: `${stats?.percentEvolutionConversionRate || "-"}%` }
        )}
        icon1={EmojiObjectsOutlinedIcon}
        icon2={ChangeHistoryIcon}
        clickable
        minSize
        centerElements={false}
        color="success"
        revertIcon2={(stats?.percentEvolutionClickRate || 0) < 0}
        width={matchesXsScreen ? "100%" : "auto"}
      />

      <ColorBlock
        text1={t("pages.campaign.numberOfPrint")}
        text2={(stats?.numberOfPrint || "-") as unknown as string}
        text3={t("pages.campaign.increaseSinceLastMonth", {
          value: `${stats?.percentEvolutionPrint || "-"}%`,
        })}
        icon1={ReceiptOutlinedIcon}
        icon2={ChangeHistoryIcon}
        clickable
        minSize
        centerElements={false}
        color="warning"
        width={matchesXsScreen ? "100%" : "auto"}
      />
    </Stack>
  );
}
