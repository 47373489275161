import { Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Question } from "../../types/campaign.types";
import QuestionResponseBuilder from "./QuestionResponseBuilder";

export type QuestionResponse = {
  question: Question;
  videoBlob?: Blob | boolean;
};

interface QuestionsResponsesBuilderProps {
  onEnd: (qrs: Array<QuestionResponse>) => void;
  initialValue: Array<QuestionResponse>;
  userData?: any;
  setData?: any;
}

export default function QuestionsResponsesBuilder({
  onEnd,
  initialValue,
  userData,
  setData,
}: QuestionsResponsesBuilderProps) {
  const [questionResponse, setQuestionResponse] =
    useState<QuestionResponse[]>(initialValue);
  const [numberOfLoading, setNumberOfLoading] = useState<number>(0);
  const { t } = useTranslation();

  const currentQuestionResponseStep: QuestionResponse = questionResponse.find(
    (q) => !q.videoBlob
  );

  const isEnded =
    questionResponse?.length && !questionResponse.find((q) => !q.videoBlob);

  useEffect(() => {
    if (isEnded && numberOfLoading === 0) {
      onEnd(questionResponse);
    }
  }, [isEnded, questionResponse, onEnd, numberOfLoading]);

  if (isEnded && numberOfLoading > 0) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        width="100vw"
      >
        <Typography
          fontWeight={400}
          fontSize="24px"
          color="#18181A"
          textAlign="center"
        >
          {t("common.videoAreLoading")}
        </Typography>
      </Stack>
    );
  }
  return (
    <>
      {currentQuestionResponseStep ? (
        <QuestionResponseBuilder
          question={currentQuestionResponseStep.question}
          setData={setData}
          userData={userData}
          onBlobSaved={() => setNumberOfLoading((number) => number - 1)}
          onBlobSaveStart={() => setNumberOfLoading((number) => number + 1)}
          onFailSaveBlob={(id: number) => {
            setNumberOfLoading((number) => number - 1);
            return setQuestionResponse(
              questionResponse.map((q) =>
                q.question.id === id
                  ? { ...currentQuestionResponseStep, videoBlob: false }
                  : q
              )
            );
          }}
          onResponseBlob={() => {
            setQuestionResponse(
              questionResponse.map((q) =>
                q.question.id === currentQuestionResponseStep.question.id
                  ? { ...currentQuestionResponseStep, videoBlob: true }
                  : q
              )
            );
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
}
