import { Stack, Theme } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import { Controller } from "react-hook-form";
import RichTextEditor from "react-rte";

interface ControlledRichTextEditorProps {
  control: any;
  name: string;
  defaultValue?: string;
  richTextEditorProps?: any;
  label?: string;
  disabled?: boolean;
}

export default function ControlledRichTextEditor({
  control,
  defaultValue,
  name,
  richTextEditorProps,
  label,
  disabled,
}: ControlledRichTextEditorProps) {
  const baseValue = defaultValue
    ? RichTextEditor.createValueFromString(defaultValue, "html")
    : RichTextEditor.createEmptyValue();

  const [rteValue, setRteValue] = React.useState(baseValue);

  const theme: Theme = useTheme();

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue || ""}
      render={({ field: { onChange } }) => (
        <>
          <Stack direction="column" spacing={0.25}>
            <Stack
              sx={{
                background: "inherit",
                "& *:not(span):not(button)": {
                  background: "inherit",
                  color:
                    theme.palette.mode === "dark"
                      ? theme.palette.common.white
                      : theme.palette.common.black,
                },
              }}
            >
              {!disabled ? (
                <RichTextEditor
                  value={rteValue}
                  onChange={(val) => {
                    setRteValue(val);
                    onChange(val.toString("html"));
                  }}
                  {...richTextEditorProps}
                />
              ) : (
                <div dangerouslySetInnerHTML={{ __html: defaultValue }}></div>
              )}
            </Stack>
          </Stack>
        </>
      )}
    />
  );
}
